import React from "react";


class Footer extends React.Component {


    render() {
        return   <footer class="footer mt-auto py-3">
        <div class="container">
            <div class="row">
                <div class="col-md-10">
                    <small>Para un mejor funcionamiento del sitio, le recomendamos utilizar navegador Chrome o Firefox en sus últimas versiones.</small>
                    <br></br><small><b>800200111 – (+)56979247372</b> Lun a Jue de 9 a 18 hrs. Vier de 9 a 16 hrs.</small>
                </div>
                <div class="col-md-2">
                <a href="https://www.afpcapital.cl/Administracion/Paginas/sucursales.aspx" target="_blank"  rel="noreferrer"> <b>Sucursales</b></a>
                </div>
            </div>

        </div>
    </footer>

    }



}

export default Footer;