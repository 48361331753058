import React from "react";
import AFPLogo from '../bg_et.png';
//import '../index.css';
class Header extends React.Component {


    render() {

        return   <div>
        <img width= "100%" src={AFPLogo} alt="logo AFP Capital"></img>
      
    </div>

    }



}

export default Header;