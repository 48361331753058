
import React from "react";


class ComentariosFinales extends React.Component {


    render() {
        return <div class="container" style={{ textAlign: "left" }}>
            <div class="row">
                <div class="col-md-10">
                    <small>
                        Inicio de retiro
                    <br></br>
                        <ul>
                            <li>Este proceso de retiro sin costo para los afiliados</li>
                            <li>El realizar este retiro no influye para percibir o solicitar el Aporte Previsional Solidario.</li>
                        </ul>


                    {/* Inembargabilidad de los fondos
                    <br></br>
                        <ul>
                            <li>Los fondos retirados se considerarán extraordinariamente intangibles para todo efecto legal, y no serán objeto de retención, descuento, compensación legal o contractual, embargo o cualquier forma de afectación judicial o administrativa, ni podrá rebajarse del monto ya decretado de la compensación  económica en el juicio de divorcio.</li>
                            <li>Los pagos serán realizados en 12 meses como renta temporal sin opción de excedente de libre disposición, segun normativa vigente.</li>
                        </ul> */}

                    </small>

                </div>
            </div>

        </div>

    }



}

export default ComentariosFinales;