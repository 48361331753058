import React, { Component } from 'react';
import { Button } from 'react-bootstrap'

export default class UploadMultipleFiles extends Component {
  state = {

    // Initially, no file is selected
    selectedFile: null,
    displayButton: 'none',
    message: []
  };
  // state = {

  //   // Initially, no file is selected
  //   selectedFiles: [],
  //   progressInfos: [],
  //   message: ["hola", 'cho'],
  //   fileInfos: [],
  //   color: "green",
  //   display: "block"

  // };
  // onFileChange = event => {
  //   console.log(event.target.files)
  //   // Update the state
  //   this.setState({
  //     selectedFiles: event.target.files, fileInfos: Array.from(event.target.files)
  //     , progressInfos: Array.from(event.target.files)
  //   });


  render() {
    const { message, color, display } = this.state;
    // console.log(selectedFiles, progressInfos, message, fileInfos)
    return (
      <div>


        <div className="row">
          <div className="col-md-12">
            
            <input style={{ color: color }} variant="info" type="file" multiple accept=".pdf,.jpg,.jpeg" onChange={this.props.parentCallBackFileOnChange} />
            <Button variant="info" style={{ display: display }} onClick={this.props.parentCallBackFileOnClick}>
              Cargar Archivos
				</Button>
          </div>
         
        </div>

        {/* {progressInfos &&
          progressInfos.map((progressInfo, index) => (
            <div className="mb-2" key={index}>
              <span>{progressInfo.name}</span>
              <div className="progress">
                <div
                  className="progress-bar progress-bar-info"
                  role="progressbar"
                  aria-valuenow={progressInfo.percentage}
                  aria-valuemin="0"
                  aria-valuemax="100"
                  style={{ width: progressInfo.percentage + "%" }}
                >
                  {progressInfo.percentage}%
                  </div>
              </div>
            </div>
          ))} */}

        {message.length > 0 && (
          <div className="alert alert-secondary" role="alert">
            <ul>
              {message.map((item, i) => {
                return <li key={i}>{item}</li>;
              })}
            </ul>
          </div>
        )}

        {/* <div className="card">
          <div className="card-header">List of Files</div>
          <ul className="list-group list-group-flush">
            {fileInfos &&
              fileInfos.map((file, index) => (

                <li className="list-group-item" key={index} >
                  <a href={file.url}>{file.name}</a>
                </li>
              ))}
          </ul>
        </div> */}
      </div>
    );
  }
}