import React from 'react';
import Footer from '../../componentes/footer'
import Header from '../../componentes/headerEjecutivo'
import Home from '../../sitios/home/index'

const { format } = require('rut.js')

export default class LandingEjecutivo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      redirect: false,
      disableSubmit: false,
      rutEjecutivo: "",
      URL_LOGIN_EJECUTIVO: process.env.REACT_APP_URL_LOGIN_EJECUTIVO,
      URL_REDIRECT: process.env.REACT_APP_URL_URL_REDIRECT,
      claveEjecutivo: "",
      rutColorWarning: "green",
      claveColorWarning: "green",
      colorSubmit: "green"
    }
  }

  LimpiarRut = (rutvalor) => {

    // eslint-disable-next-line
    var rutv = rutvalor.replace(/\./g, "");
    // eslint-disable-next-line
    rutv = rutv.replace(/\-/g, "");
    // rutv = rutv.padStart(10, "0");
    return rutv;
  }

  HandlerRUTEjecutivo = (e) => {
    var rut = e.target.value
    this.setState({ rutEjecutivo: format(rut), infoRutEjecutivo: "", clave: "", infoSubmit: "" })
  }

  HandlerRutEjecutivoBlur = (e) => {
    var { rutEjecutivo } = this.state

    //validacion de rut existente en base proporcionada
    this.setState({ disableSubmit: true, rutColorWarning: 'green', infoRut: "validando rut en sistema...." })
    document.getElementById('submitButton').className += " disabled"
    const letras = Array.from('ABCDEFGHIJKLMNOPQRSTUVWXYZ')
    var rutarray = Array.from(rutEjecutivo)
    var contador = 0
    letras.forEach((letra) => {
      var count = rutarray.filter(x => x.toUpperCase() === letra.toUpperCase()).length
      if (count >= 1) {
        contador += count
      }
    })

    if (contador > 1) { //mas de una letra
      this.setState({ rutColorWarning: 'red', rutEjecutivo: "", infoRutEjecutivo: "El rut ingresado no puede contener mas de una letra K" })
      return;
    } else if (isNaN(rutEjecutivo.charAt(0))) {
      this.setState({ rutColorWarning: 'red', rutEjecutivo: "", infoRutEjecutivo: "El rut no puede comenzar por una letra" })
      return
    } else {
      document.getElementById('submitButton').classList.remove("disabled")
      this.setState({ disableSubmit: false, infoRutEjecutivo: "" })
    }
  }


  HandlerClaveEjecutivo = (e) => {
    var clave = e.target.value
    this.setState({ claveEjecutivo: clave, infoClaveEjecutivo: "", infoSubmit: "" })
  }



  HandlerSubmit = async () => {
    var { rutEjecutivo, claveEjecutivo } = this.state


    this.setState({ colorSubmit: 'green', infoSubmit: "validando datos en el  sistema...." })
    rutEjecutivo = this.LimpiarRut(rutEjecutivo)

    await fetch(this.state.URL_LOGIN_EJECUTIVO, {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ "rutEjecutivo": rutEjecutivo, "claveEjecutivo": claveEjecutivo }),
    }).then(res => res.json())
      .then(response => {
        if (response.rechazo === true) {
          this.setState({ colorSubmit: "red", infoSubmit: "favor verifique que su rut y/o clave son correctos" })
          return;
        } else {
          this.setState({ colorSubmit: 'green', infoSubmit: "redirigiendo al sitio principal..." })          
          sessionStorage.setItem('token', response.token);
          setTimeout(() => {
            this.setState({ redirect: true, infoSubmit: "" })
          }, 5000);
        }
      })
      .catch((error) => {
        this.setState({ colorSubmit: "red", infoSubmit: "En estos momentos tenemos problemas para procesar su solicitud, favor intente nuevamente en unos minutos" })
        return;
      });
  }

  NewHOC = (PassedComponent) => {
    return class extends React.Component {
      render() {
        return (
          <div>
            <PassedComponent {...this.props} />
          </div>
        )
      }
    }
  }

  render() {
    return (
      <div>
        {this.state.redirect ?
          <Home></Home>
          :
          <div>

            <Header></Header>
            <div className="container " >
              <div class="row">

                <div class="col-md-12">
                  <br></br>
                  {/* <div class="row "><div class="col-md-12"> <img src={AFPLogo} href="/" onClick={() => window.location = "https://beneficiarios-sobrevivencia-qa.afpcapital.cl/"}></img></div></div> */}
                  <br></br>
                  <div class="row">
                    <div class="col-md-12 text-center">
                      <h1 style={{ fontFamily: "Trebuchet MS,Arial,Helvetica,sans-serif", fontSize: "30px", lineHeight: "100%", color: "#0033A0" }}>Ejecutivos</h1>
                      Ingreso de Ejecutivos
                      <br></br><br></br><br></br>
                    </div></div>
                  <div class="row">
                    <div class="col-md-2"></div>
                    <div class="col-md-4">
                      <label className="" >RUT/DNI Ejecutivo</label>
                    </div>
                    <div class="col-md-4">
                      <input type="email" className="form-control" placeholder="" maxLength="12" value={this.state.rutEjecutivo} onChange={(e) => this.HandlerRUTEjecutivo(e)} onBlur={() => this.HandlerRutEjecutivoBlur()}></input>
                      <p className="inputdisclaimertext" style={{ color: this.state.rutColorWarning }}>{this.state.infoRutEjecutivo}</p>
                    </div>
                    <div class="col-md-2"></div>
                  </div>
                  <div class="row">
                    <div class="col-md-2"></div>
                    <div class="col-md-4">
                      <label className="" >Clave</label>
                    </div>
                    <div class="col-md-4">
                      <input type="password" className="form-control" placeholder="" value={this.state.claveEjecutivo} onChange={(e) => this.HandlerClaveEjecutivo(e)}></input>
                      <p className="inputdisclaimertext" style={{ color: this.state.claveColorWarning }}>{this.state.infoClave}</p>
                    </div>
                    <div class="col-md-2"></div>
                  </div>
                  <div class="row">
                    <div class="col-md-4">
                    </div>
                    <div class="col-md-4">
                      <button className="btnRegister montoRetiroNumero" id="submitButton" disabled={this.state.disableSubmit} onClick={() => this.HandlerSubmit()}>Ingresar</button>

                    </div>
                    <div class="col-md-4">
                    </div>
                  </div>
                  <div class="text-center">
                    <p id="infoSubmit" style={{ color: this.state.colorSubmit, fontSize: "16px", fontWeight: "bold" }}>{this.state.infoSubmit}</p>
                  </div>
                </div>

              </div>
            </div>
            <br></br>
            <br></br>
            <br></br>
            <br></br>
            <Footer></Footer>

          </div>

        }



      </div>
    )
  }
}

