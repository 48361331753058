import './App.css';
import './bootstrap.min.css'
import Home from './sitios/home/index'
import Login from './sitios/ejecutivo/index'
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

function App() {
  var ruta = ""
  if (window.location.host === "beneficiarios-sobrevivencia-con-et-ejecutivos-qa.afpcapital.cl" 
  || window.location.host === "beneficiarios-sobrevivencia-con-et-ejecutivos-qa2.afpcapital.cl" || window.location.host === "beneficiarios-sobrevivencia-con-et-ejecutivos.afpcapital.cl") {
    ruta = <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Login} />
        <Route exact path="/ejecutivo" component={Login} />
        <Redirect from="*" to="/" />
      </Switch>
    </BrowserRouter>
  } else {
    ruta = <BrowserRouter>
      <Switch>
        <Route exact path="/" component={Home} />
        <Route exact path="/ejecutivo" component={Home} />
        <Redirect from="*" to="/" />
      </Switch>
    </BrowserRouter>
  }

  return (
    ruta
  )
}

export default App;
