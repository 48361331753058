import React from "react";

import Recaptcha from 'react-google-invisible-recaptcha';
import ModalInformativo from '../../componentes/modal'
import Footer from "../../componentes/footer";
import Header from "../../componentes/header";
import IngresoSolicitud from '../solicitud/index'
import Comentarios from '../../componentes/comentariosIniciales'
import BlockUi from 'react-block-ui'
import '../../blockStyle.css'

const { format } = require('rut.js')

class Home extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            mostrarIngresoSolicitud: false,
            rutBeneficiario: "",
            rutCausante: "",
            tipoIngresoSolicitud: "",
            manejadorMensajes: "",
            manejadorMensajesColor: "green",
            dataBeneficiario: "",
            ejecutivoLogueado: false,
            showLoginEjecutivo: false,
            showModalInformativo: false,
            textModalInformativo: "",
            blocking: false,
            sitekey: process.env.REACT_APP_SITEKEY,
            idEjecutivo: "",
            URL_VALIDAR_BENEFICIARIO: process.env.REACT_APP_URL_VALIDAR_BENEFICIARIO,
            URL_VALIDAR_VIGENCIA_TOKEN: process.env.REACT_APP_URL_VALIDAR_VIGENCIA_TOKEN
        }
    }

    async componentDidMount() {

        document.getElementById('cbTipoIngreso').selectedIndex = 0;
        var token = sessionStorage.getItem('token');
        if (!token || token === null) {
            return
        }

        await fetch(this.state.URL_VALIDAR_VIGENCIA_TOKEN, {
            method: 'POST',
            body: JSON.stringify({ "token": token }),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => res.json())
            .then((response) => {
                
                if (response.rechazo) {
                    this.setState({ showLoginEjecutivo: true, ejecutivoLogueado: false, showModalInformativo: true, textModalInformativo: "Estimado ejecutivo, su sesion a expirado por lo que debe volver a ingresar." })
                } else {
                    this.setState({ idEjecutivo: response.ID_EJECUTIVO, showLoginEjecutivo: false, ejecutivoLogueado: true })
                }
            })
            .catch((error) => {
                
                this.setState({ showLoginEjecutivo: true, ejecutivoLogueado: false, showModalInformativo: true, textModalInformativo: "En estos momentos tenemos problemas para validar su sesion , favor inténtelo nuevamente en unos minutos o vuelva a ingresar a sus credenciales de acceso en el login" })
            })
    }

    componentWillMount() {


    }

    HandlerRUTBeneficiario = (e) => {
        let rut = e.target.value
        this.setState({ rutBeneficiario: format(rut) })
    }

    HandlerRUTCausante = (e) => {
        let rutCausante = e.target.value
        this.setState({ rutCausante: format(rutCausante) })
    }


    HandlerTipoIngresoSolicitud = (e) => {
        let value = e.target.value
        if (value === 0) {
            return;
        }
        this.setState({ tipoIngresoSolicitud: value })
    }

    LimpiarRut = (rutvalor) => {
        // eslint-disable-next-line
        var rutv = rutvalor.replace(/\./g, "");
        // eslint-disable-next-line
        rutv = rutv.replace(/\-/g, "");
        // eslint-disable-next-line
        rutv = rutv.padStart(10, "0");
        return rutv;
    }

    ModalInformativoparentCallBackClose = () => {
        this.setState({ showModalInformativo: false })
    }

    LoginEjecutivo_ = () => {
        this.setState({ showLoginEjecutivo: true })
    }


    HandlerSubmit = async () => {
        this.recaptcha.execute();
    }


    HandlerRecaptcha = async () => {



        var token = await this.recaptcha.getResponse();
        let { rutBeneficiario, rutCausante, tipoIngresoSolicitud } = this.state

        if (rutBeneficiario === "" || rutCausante === "" || tipoIngresoSolicitud === "" || tipoIngresoSolicitud === 0) {
            this.setState({ showModalInformativo: true, textModalInformativo: "Favor verifica que la información solicitada este ingresada correctamente" })
            return false
        }
        this.setState({ blocking: true })
        await fetch(this.state.URL_VALIDAR_BENEFICIARIO, {
            method: 'POST',
            body: JSON.stringify({ "rutBeneficiario": this.LimpiarRut(rutBeneficiario), "rutCausante": this.LimpiarRut(rutCausante), tokenCaptcha: token }),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => res.json())
            .then((response) => {
                if (response.status === 200)
                    this.setState({ blocking: false, mostrarIngresoSolicitud: true, dataBeneficiario: response.data[0] })
                else if (response.status === 400)
                    this.setState({ blocking: false, showModalInformativo: true, textModalInformativo: "No es posible continuar con el proceso , ya que , el beneficiario actualmente cuenta con una solicitud activa en el sistema." })
                else if (response.status === 404)
                    this.setState({ blocking: false, showModalInformativo: true, textModalInformativo: "El o los RUT ingresados , no se encuentran dentro de la base de beneficiarios por lo que no podra realizar su solicitud de retiro" })
                else
                    this.setState({ blocking: false, showModalInformativo: true, textModalInformativo: "En estos momentos tenemos problemas para validar sus datos, favor inténtelo mas tarde" })
            })
            .catch((error) => {                
                this.setState({ blocking: false, showModalInformativo: true, textModalInformativo: "En estos momentos tenemos problemas para validar sus datos, favor inténtelo mas tarde" })
                return;
            });
    }

    NewHOC = (PassedComponent) => {
        return class extends React.Component {
            render() {
                return (
                    <div>
                        <PassedComponent {...this.props} />
                    </div>
                )
            }
        }
    }

    render() {
        // const LoginEjecutivoView = this.NewHOC(LoginEjecutivo);

        var { idEjecutivo, mostrarIngresoSolicitud, rutBeneficiario, rutCausante, tipoIngresoSolicitud, dataBeneficiario } = this.state
        return <div>
            {mostrarIngresoSolicitud ?
                <IngresoSolicitud
                    rutBeneficiario={rutBeneficiario}
                    rutCausante={rutCausante}
                    tipoIngresoSolicitud={tipoIngresoSolicitud}
                    dataBeneficiario={dataBeneficiario}
                    idEjecutivo={idEjecutivo}
                >
                </IngresoSolicitud>
                :

                <div>
                    <BlockUi tag="div" blocking={this.state.blocking} message="Espere unos minutos mientras verificamos la información" keepInView>

                        <div>
                            <ModalInformativo mostrarModal={this.state.showModalInformativo} texto={this.state.textModalInformativo} parentCallBackInformativo={this.ModalInformativoparentCallBackClose}></ModalInformativo>

                            <Header></Header>
                            <div className="container " >

                                <div className="row">




                                    <div className="col-md-12">




                                        <div className="row">
                                            <div className="col-md-12 text-center">
                                                {/* {this.state.ejecutivoLogueado === false && <img src={IconEjecutivo} alt="login ejecutivo icon" style={{ maxWidth: "5%", right: "0px", position: "absolute" }} onClick={() => this.LoginEjecutivo_()} />} */}
                                                <br></br>
                                                <h1 style={{ fontFamily: "Trebuchet MS,Arial,Helvetica,sans-serif", fontSize: "30px", lineHeight: "100%", color: "#0033A0" }}>Solicitud de Certificación de Enfermo Terminal</h1>
                                                Te invitamos a ingresar los datos que aparecen a continuación para poder gestionar tu solicitud
                                                <br></br><br></br>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-3"></div>
                                            <div className="col-md-6">
                                            </div>
                                            <div className="col-md-3"></div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-4"></div>
                                            <div className="col-md-4">
                                                <label htmlFor="basic-url">RUT del beneficiario</label>
                                                <div className="input-group mb-3">
                                                    <input type="email" className="form-control" maxLength="12" value={this.state.rutBeneficiario} onChange={(e) => this.HandlerRUTBeneficiario(e)}></input>
                                                </div>
                                            </div>
                                            <div className="col-md-4"></div>
                                        </div>

                                        <div className="row">
                                            <div className="col-md-4"></div>
                                            <div className="col-md-4">
                                            </div>
                                            <div className="col-md-4"></div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-4"></div>
                                            <div className="col-md-4">
                                                <label htmlFor="basic-url">RUT del causante</label>
                                                <div className="input-group mb-3">
                                                    <input type="email" className="form-control" maxLength="12" value={this.state.rutCausante} onChange={(e) => this.HandlerRUTCausante(e)} ></input>
                                                    <p className="inputdisclaimertext" style={{ color: 'rgb(0, 51, 160)', fontSize: '12px' }}>El causante corresponde a la persona que originó los ahorros para financiar la pensión de sobrevivencia
                                                    </p>

                                                </div>
                                            </div>
                                            <div className="col-md-4"></div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-4"></div>
                                            <div className="col-md-4">
                                                <label className="">Tipo de Ingreso</label>
                                                <select className="custom-select" id="cbTipoIngreso" onChange={(e) => this.HandlerTipoIngresoSolicitud(e)}>
                                                    <option value="0" selected>Seleccionar</option>
                                                    <option value="TU" selected>Tutor</option>
                                                    <option value="MA" selected>Mandatario</option>
                                                    <option value="BE" selected>Beneficiario</option>
                                                </select>
                                            </div>
                                            <div className="col-md-4"></div>
                                        </div>
                                        <br></br>
                                        <br></br>

                                        <div className="text-center">

                                            <div className="row">
                                                <div className="col-md-2">
                                                </div>
                                                <div className="col-md-8">

                                                    <div className="row">
                                                        <div class="col-md-3">
                                                        </div>
                                                        <div className="col-md-6">
                                                            <button className="btnRegister montoRetiroNumero" id="submitButton" onClick={() => this.HandlerSubmit()}>Ingresar</button>
                                                        </div>
                                                        <div class="col-md-3">
                                                        </div>
                                                    </div>
                                                    <Recaptcha
                                                        ref={ref => this.recaptcha = ref}
                                                        sitekey={this.state.sitekey}
                                                        onResolved={() => this.HandlerRecaptcha()} />
                                                </div>
                                                <p className="small-text" id="disclaimerInfo" style={{ 'fontSize': '1rem', color: this.state.manejadorMensajesColor, fontWeight: "bold" }}>{this.state.manejadorMensajes}</p>
                                                <div className="col-md-2">
                                                </div>

                                            </div>
                                            <div class="row">
                                                <div class="col-md-3"></div>
                                                <div class="col-md-6 text-center">
                                                    {this.state.ejecutivoLogueado &&
                                                        <p>Ingreso Solicitud Ejecutiva/o : {this.state.idEjecutivo}</p>

                                                    }
                                                </div>
                                                <div class="col-md-3"></div>
                                            </div>
                                            <div className="text-center">
                                            </div>

                                        </div>

                                    </div>




                                </div>







                            </div>
                        </div>
                        <Comentarios></Comentarios>
                        <Footer></Footer>

                    </BlockUi>
                </div>

            }

        </div>

    }
}

export default Home;
