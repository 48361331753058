
import React from "react";


class ComentariosIniciales extends React.Component {


    render() {
        return <div className="row">
            <div class="col-md-2"></div>
                <div class="col-md-8 text-justify">
                    <small>
Antes de ingresar su solicitud, usted o su representante, debe obtener de parte de su médico tratante y del establecimiento de salud público o privado que corresponda, un certificado médico que acredite su condición de salud, el que deberá adjuntarse a la solicitud. Su médico tratante deberá llenar directamente el certificado en el siguiente <a target="_blank" rel="noreferrer" href="https://certificadosET.portalafp.cl">link</a> Su médico tratante, deberá enviarlo al establecimiento de salud público o privado que corresponda, donde haya recibido atenciones respecto de su enfermedad, para completar los datos correspondientes, firmar y timbrar por el director médico o su equivalente, el que deberá entregárselo por los medios que a usted le resulten más convenientes.El certificado, debidamente llenado, firmado y timbrado, deberá adjuntarse a la solicitud que presente usted o su representante, por esta vía o por cualquiera de los canales de servicio de la AFP. Además, deberá adjuntar la autorización para que el Consejo Médico pueda acceder a la revisión de la ficha clínica (la que se encuentra disponible para su llenado y envío en este  <a  target="_blank" rel="noreferrer" href="https://solicitud-pension-enfermedad-terminal.afpcapital.cl/early-withdrawal/prelogin">link</a> . Adicionalmente, se le recomienda adjuntar la mayor cantidad de antecedentes médicos sobre su condición de salud principal.
                    </small>
                </div>
                <div class="col-md-2"></div>
            </div>

    }



}

export default ComentariosIniciales;