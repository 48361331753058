import React, { Component } from 'react';
import { Button } from 'react-bootstrap'

class UploadFile extends Component {

    state = {

        // Initially, no file is selected
        selectedFile: null,
        displayButton: 'none'

    };


    fileData = () => {

        if (this.state.selectedFile) {

            return (
                <div>
                    <h2>File Details:</h2>

                    <p>File Name: {this.state.selectedFile.name}</p>


                    <p>File Type: {this.state.selectedFile.type}</p>


                    <p>
                        Last Modified:{" "}
                        {this.state.selectedFile.lastModifiedDate.toDateString()}
                    </p>

                </div>
            );
        }
    };


    render() {
        const { display,color } = this.props;
        return (
//             <div>
//                 <label for="file-upload" style={{
//                     border: "1px solid #ccc",
//                     display: "inline-block",
//                     padding: "6px 12px",
//                     cursor: "pointer"
//                 }}>
//                     <i class="fa fa-cloud-upload"></i> Seleccionar Archivo
// </label>

//                 <input id="file-upload" style={{ display: 'none' }} type="file" accept=".pdf" onChange={this.props.parentCallBackFileOnChange} />

//                 <Button variant="info" onClick={this.props.parentCallBackFileOnClick}>
//                         Cargar Archivo
// 				</Button>
                 <div>
                    <input style={{color: color}} variant="info" type="file" accept=".pdf" onChange={this.props.parentCallBackFileOnChange} />
                    <Button variant="info" style={{ display: display }} onClick={this.props.parentCallBackFileOnClick}>
                        Cargar Archivo
				</Button>
                </div> 
            // </div>

        );
    }
}

export default UploadFile;
