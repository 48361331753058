module.exports.region_comuna = {
    "name": "Chile",
    "regions": [
        {
            "name": "Arica y Parinacota",
            "romanNumber": "XV",
            "number": "15",
            "DropDownIndex": "1",
            "communes": [
                { 'name': 'ARICA', codigo: '15101', "DropDownIndex": "1" },
                { 'name': 'CAMARONES', codigo: '15102', "DropDownIndex": "2" },
                { 'name': 'PUTRE', codigo: '15201', "DropDownIndex": "3" },
                { 'name': 'GENERAL LAGOS', codigo: '15202', "DropDownIndex": "4" }
            ]
        },
        {
            "name": "Tarapacá",
            "romanNumber": "I",
            "number": "1",
            "DropDownIndex": "2",
            "communes": [
                { 'name': 'ALTO HOSPICIO', codigo: '1107', "DropDownIndex": "1" },
                { 'name': 'IQUIQUE', codigo: '1101', "DropDownIndex": "2" },
                { 'name': 'CAMINA', codigo: '1402', "DropDownIndex": "3" },
                { 'name': 'COLCHANE', codigo: '1403', "DropDownIndex": "4" },
                { 'name': 'HUARA', codigo: '1404', "DropDownIndex": "5" },
                { 'name': 'PICA', codigo: '1405', "DropDownIndex": "6" },
                { 'name': 'POZO ALMONTE', codigo: '1401', "DropDownIndex": "7" }
            ]
        },
        {
            "name": "Antofagasta",
            "romanNumber": "II",
            "number": "2",
            "DropDownIndex": "3",
            "communes": [
                { 'name': 'SIERRA GORDA', codigo: '2103', "DropDownIndex": "1" },
                { 'name': 'TALTAL', codigo: '2104', "DropDownIndex": "2" },
                { 'name': 'CALAMA', codigo: '2201', "DropDownIndex": "3" },
                { 'name': 'OLLAGUE', codigo: '2202', "DropDownIndex": "4" },
                { 'name': 'SAN PEDRO DE ATACAMA', codigo: '2203', "DropDownIndex": "5" },
                { 'name': 'TOCOPILLA', codigo: '2301', "DropDownIndex": "6" },
                { 'name': 'MARIA ELENA', codigo: '2302', "DropDownIndex": "7" },
                { 'name': 'ANTOFAGASTA', codigo: '2101', "DropDownIndex": "8" },
                { 'name': 'MEJILLONES', codigo: '2102', "DropDownIndex": "9" }
            ]
        },
        {
            "name": "Atacama",
            "romanNumber": "III",
            "number": "3",
            "DropDownIndex": "4",
            "communes": [
                { 'name': 'COPIAPO', codigo: '3101', "DropDownIndex": "1" },
                { 'name': 'CALDERA', codigo: '3102', "DropDownIndex": "2" },
                { 'name': 'TIERRA AMARILLA', codigo: '3103', "DropDownIndex": "3" },
                { 'name': 'CHANARAL', codigo: '3201', "DropDownIndex": "4" },
                { 'name': 'DIEGO DE ALMAGRO', codigo: '3202', "DropDownIndex": "5" },
                { 'name': 'VALLENAR', codigo: '3301', "DropDownIndex": "6" },
                { 'name': 'ALTO DEL CARMEN', codigo: '3302', "DropDownIndex": "7" },
                { 'name': 'FREIRINA', codigo: '3303', "DropDownIndex": "8" },
                { 'name': 'HUASCO', codigo: '3304', "DropDownIndex": "9" }

            ]
        },
        {
            "name": "Coquimbo",
            "romanNumber": "IV",
            "number": "4",
            "DropDownIndex": "5",
            "communes": [
                { 'name': 'LOS VILOS', codigo: '4203', "DropDownIndex": "1" },
                { 'name': 'SALAMANCA', codigo: '4204', "DropDownIndex": "2" },
                { 'name': 'OVALLE', codigo: '4301', "DropDownIndex": "3" },
                { 'name': 'COMBARBALA', codigo: '4302', "DropDownIndex": "4" },
                { 'name': 'MONTE PATRIA', codigo: '4303', "DropDownIndex": "5" },
                { 'name': 'PUNITAQUI', codigo: '4304', "DropDownIndex": "6" },
                { 'name': 'RIO HURTADO', codigo: '4305', "DropDownIndex": "7" },
                { 'name': 'LA SERENA', codigo: '4101', "DropDownIndex": "8" },
                { 'name': 'COQUIMBO', codigo: '4102', "DropDownIndex": "9" },
                { 'name': 'ANDACOLLO', codigo: '4103', "DropDownIndex": "10" },
                { 'name': 'LA HIGUERA', codigo: '4104', "DropDownIndex": "11" },
                { 'name': 'PAIHUANO', codigo: '4105', "DropDownIndex": "12" },
                { 'name': 'VICUNA', codigo: '4106', "DropDownIndex": "13" },
                { 'name': 'ILLAPEL', codigo: '4201', "DropDownIndex": "14" },
                { 'name': 'CANELA', codigo: '4202', "DropDownIndex": "15" }
            ]
        },
        {
            "name": "Valparaíso",
            "romanNumber": "V",
            "number": "5",
            "DropDownIndex": "6",
            "communes": [
                { 'name': 'VALPARAISO', codigo: '5101', "DropDownIndex": "1" },
                { 'name': 'CASABLANCA', codigo: '5102', "DropDownIndex": "2" },
                { 'name': 'CONCON', codigo: '5103', "DropDownIndex": "3" },
                { 'name': 'JUAN FERNANDEZ', codigo: '5104', "DropDownIndex": "4" },
                { 'name': 'PUCHUNCAVI', codigo: '5105', "DropDownIndex": "5" },
                { 'name': 'QUILPUE', codigo: '5801', "DropDownIndex": "6" },
                { 'name': 'QUINTERO', codigo: '5107', "DropDownIndex": "7" },
                { 'name': 'VILLA ALEMANA', codigo: '5804', "DropDownIndex": "8" },
                { 'name': 'VINA DEL MAR', codigo: '5109', "DropDownIndex": "9" },
                { 'name': 'ISLA DE PASCUA', codigo: '5201', "DropDownIndex": "10" },
                { 'name': 'LOS ANDES', codigo: '5301', "DropDownIndex": "11" },
                { 'name': 'CALLE LARGA', codigo: '5302', "DropDownIndex": "12" },
                { 'name': 'RINCONADA', codigo: '5303', "DropDownIndex": "13" },
                { 'name': 'SAN ESTEBAN', codigo: '5304', "DropDownIndex": "14" },
                { 'name': 'LA LIGUA', codigo: '5401', "DropDownIndex": "15" },
                { 'name': 'CABILDO', codigo: '5402', "DropDownIndex": "16" },
                { 'name': 'PAPUDO', codigo: '5403', "DropDownIndex": "17" },
                { 'name': 'PETORCA', codigo: '5404', "DropDownIndex": "18" },
                { 'name': 'ZAPALLAR', codigo: '5405', "DropDownIndex": "19" },
                { 'name': 'QUILLOTA', codigo: '5501', "DropDownIndex": "20" },
                { 'name': 'CALERA', codigo: '5502', "DropDownIndex": "21" },
                { 'name': 'HIJUELAS', codigo: '5503', "DropDownIndex": "22" },
                { 'name': 'LA CRUZ', codigo: '5504', "DropDownIndex": "23" },
                { 'name': 'LIMACHE', codigo: '5802', "DropDownIndex": "24" },
                { 'name': 'NOGALES', codigo: '5506', "DropDownIndex": "25" },
                { 'name': 'OLMUE', codigo: '5803', "DropDownIndex": "26" },
                { 'name': 'SAN ANTONIO', codigo: '5601', "DropDownIndex": "27" },
                { 'name': 'ALGARROBO', codigo: '5602', "DropDownIndex": "28" },
                { 'name': 'CARTAGENA', codigo: '5603', "DropDownIndex": "29" },
                { 'name': 'EL QUISCO', codigo: '5604', "DropDownIndex": "30" },
                { 'name': 'EL TABO', codigo: '5605', "DropDownIndex": "31" },
                { 'name': 'SANTO DOMINGO', codigo: '5606', "DropDownIndex": "32" },
                { 'name': 'SAN FELIPE', codigo: '5701', "DropDownIndex": "33" },
                { 'name': 'CATEMU', codigo: '5702', "DropDownIndex": "34" },
                { 'name': 'LLAILLAY', codigo: '5703', "DropDownIndex": "35" },
                { 'name': 'PANQUEHUE', codigo: '5704', "DropDownIndex": "36" },
                { 'name': 'PUTAENDO', codigo: '5705', "DropDownIndex": "37" },
                { 'name': 'SANTA MARIA', codigo: '5706', "DropDownIndex": "38" }
            ]
        },
        {
            "name": "Metropolitana de Santiago",
            "romanNumber": "XIII",
            "number": "13",
            "DropDownIndex": "7",
            "communes": [
                { 'name': 'MANTENCION', codigo: '13606', "DropDownIndex": "1" },
                { 'name': 'SANTIAGO', codigo: '13101', "DropDownIndex": "2" },
                { 'name': 'CERRILLOS', codigo: '13102', "DropDownIndex": "3" },
                { 'name': 'CERRO NAVIA', codigo: '13103', "DropDownIndex": "4" },
                { 'name': 'CONCHALI', codigo: '13104', "DropDownIndex": "5" },
                { 'name': 'EL BOSQUE', codigo: '13105', "DropDownIndex": "6" },
                { 'name': 'ESTACION CENTRAL', codigo: '13106', "DropDownIndex": "7" },
                { 'name': 'HUECHURABA', codigo: '13107', "DropDownIndex": "8" },
                { 'name': 'INDEPENDENCIA', codigo: '13108', "DropDownIndex": "9" },
                { 'name': 'LA CISTERNA', codigo: '13109', "DropDownIndex": "10" },
                { 'name': 'LA FLORIDA', codigo: '13110', "DropDownIndex": "11" },
                { 'name': 'LA GRANJA', codigo: '13111', "DropDownIndex": "12" },
                { 'name': 'LA PINTANA', codigo: '13112', "DropDownIndex": "13" },
                { 'name': 'LA REINA', codigo: '13113', "DropDownIndex": "14" },
                { 'name': 'LAS CONDES', codigo: '13114', "DropDownIndex": "15" },
                { 'name': 'LO BARNECHEA', codigo: '13115', "DropDownIndex": "16" },
                { 'name': 'LO ESPEJO', codigo: '13116', "DropDownIndex": "17" },
                { 'name': 'LO PRADO', codigo: '13117', "DropDownIndex": "18" },
                { 'name': 'MACUL', codigo: '13118', "DropDownIndex": "19" },
                { 'name': 'MAIPU', codigo: '13119', "DropDownIndex": "20" },
                { 'name': 'NUNOA', codigo: '13120', "DropDownIndex": "21" },
                { 'name': 'PEDRO AGUIRRE CERDA', codigo: '13121', "DropDownIndex": "22" },
                { 'name': 'PENALOLEN', codigo: '13122', "DropDownIndex": "23" },
                { 'name': 'PROVIDENCIA', codigo: '13123', "DropDownIndex": "24" },
                { 'name': 'PUDAHUEL', codigo: '13124', "DropDownIndex": "25" },
                { 'name': 'QUILICURA', codigo: '13125', "DropDownIndex": "26" },
                { 'name': 'QUINTA NORMAL', codigo: '13126', "DropDownIndex": "27" },
                { 'name': 'RENCA', codigo: '13128', "DropDownIndex": "28" },
                { 'name': 'SAN JOAQUIN', codigo: '13129', "DropDownIndex": "29" },
                { 'name': 'SAN MIGUEL', codigo: '13130', "DropDownIndex": "30" },
                { 'name': 'SAN RAMON', codigo: '13131', "DropDownIndex": "31" },
                { 'name': 'VITACURA', codigo: '13132', "DropDownIndex": "32" },
                { 'name': 'PUENTE ALTO', codigo: '13201', "DropDownIndex": "33" },
                { 'name': 'PIRQUE', codigo: '13202', "DropDownIndex": "34" },
                { 'name': 'SAN JOSE DE MAIPO', codigo: '13203', "DropDownIndex": "35" },
                { 'name': 'COLINA', codigo: '13301', "DropDownIndex": "36" },
                { 'name': 'LAMPA', codigo: '13302', "DropDownIndex": "37" },
                { 'name': 'TILTIL', codigo: '13303', "DropDownIndex": "38" },
                { 'name': 'SAN BERNARDO', codigo: '13401', "DropDownIndex": "39" },
                { 'name': 'BUIN', codigo: '13402', "DropDownIndex": "40" },
                { 'name': 'CALERA DE TANGO', codigo: '13403', "DropDownIndex": "41" },
                { 'name': 'PAINE', codigo: '13404', "DropDownIndex": "42" },
                { 'name': 'MELIPILLA', codigo: '13501', "DropDownIndex": "43" },
                { 'name': 'ALHUE', codigo: '13502', "DropDownIndex": "44" },
                { 'name': 'CURACAVI', codigo: '13503', "DropDownIndex": "45" },
                { 'name': 'MARIA PINTO', codigo: '13504', "DropDownIndex": "46" },
                { 'name': 'SAN PEDRO', codigo: '13505', "DropDownIndex": "47" },
                { 'name': 'TALAGANTE', codigo: '13601', "DropDownIndex": "48" },
                { 'name': 'EL MONTE', codigo: '13602', "DropDownIndex": "49" },
                { 'name': 'ISLA DE MAIPO', codigo: '13603', "DropDownIndex": "50" },
                { 'name': 'PADRE HURTADO', codigo: '13604', "DropDownIndex": "51" },
                { 'name': 'PENAFLOR', codigo: '13605', "DropDownIndex": "52" },
                { 'name': 'RECOLETA', codigo: '13127', "DropDownIndex": "53" },
                { 'name': 'SIN COMUNA', codigo: '0', "DropDownIndex": "54" }
            ]
        },
        {
            "name": "Libertador Gral. Bernardo O’Higgins",
            "romanNumber": "VI",
            "number": "6",
            "DropDownIndex": "8",
            "communes": [
                { 'name': 'RANCAGUA', codigo: '6101' , "DropDownIndex": "1"},
                { 'name': 'COINCO', codigo: '6103' , "DropDownIndex": "2"},
                { 'name': 'COLTAUCO', codigo: '6104', "DropDownIndex": "3" },
                { 'name': 'DONIHUE', codigo: '6105' , "DropDownIndex": "4"},
                { 'name': 'GRANEROS', codigo: '6106', "DropDownIndex": "5" },
                { 'name': 'LAS CABRAS', codigo: '6107', "DropDownIndex": "6" },
                { 'name': 'MACHALI', codigo: '6108', "DropDownIndex": "7" },
                { 'name': 'MALLOA', codigo: '6109' , "DropDownIndex": "8"},
                { 'name': 'MOSTAZAL', codigo: '6110' , "DropDownIndex": "9"},
                { 'name': 'OLIVAR', codigo: '6111', "DropDownIndex": "10" },
                { 'name': 'PEUMO', codigo: '6112' , "DropDownIndex": "11"},
                { 'name': 'PICHIDEGUA', codigo: '6113', "DropDownIndex": "12" },
                { 'name': 'QUINTA DE TILCOCO', codigo: '6114' , "DropDownIndex": "13"},
                { 'name': 'RENGO', codigo: '6115' , "DropDownIndex": "14"},
                { 'name': 'REQUINOA', codigo: '6116' , "DropDownIndex": "15"},
                { 'name': 'SAN VICENTE', codigo: '6117' , "DropDownIndex": "16"},
                { 'name': 'PICHILEMU', codigo: '6201', "DropDownIndex": "17" },
                { 'name': 'LA ESTRELLA', codigo: '6202', "DropDownIndex": "18" },
                { 'name': 'LITUECHE', codigo: '6203', "DropDownIndex": "19" },
                { 'name': 'MARCHIHUE', codigo: '6204', "DropDownIndex": "20" },
                { 'name': 'NAVIDAD', codigo: '6205', "DropDownIndex": "21" },
                { 'name': 'PAREDONES', codigo: '6206' , "DropDownIndex": "22"},
                { 'name': 'SAN FERNANDO', codigo: '6301' , "DropDownIndex": "23"},
                { 'name': 'CHEPICA', codigo: '6302' , "DropDownIndex": "24"},
                { 'name': 'CHIMBARONGO', codigo: '6303' , "DropDownIndex": "25"},
                { 'name': 'LOLOL', codigo: '6304' , "DropDownIndex": "26"},
                { 'name': 'NANCAGUA', codigo: '6305' , "DropDownIndex": "27"},
                { 'name': 'PALMILLA', codigo: '6306' , "DropDownIndex": "28"},
                { 'name': 'PERALILLO', codigo: '6307', "DropDownIndex": "29" },
                { 'name': 'PLACILLA', codigo: '6308', "DropDownIndex": "30" },
                { 'name': 'PUMANQUE', codigo: '6309', "DropDownIndex": "31" },
                { 'name': 'SANTA CRUZ', codigo: '6310', "DropDownIndex": "32" },
                { 'name': 'CODEGUA', codigo: '6102' , "DropDownIndex": "33"}
            ]
        },
        {
            "name": "Maule",
            "romanNumber": "VII",
            "number": "7",
            "DropDownIndex": "9",
            "communes": [
                { 'name': 'SAGRADA FAMILIA', codigo: '7307', "DropDownIndex": "1" },
                { 'name': 'TENO', codigo: '7308', "DropDownIndex": "2" },
                { 'name': 'VICHUQUEN', codigo: '7309', "DropDownIndex": "3" },
                { 'name': 'LINARES', codigo: '7401', "DropDownIndex": "4" },
                { 'name': 'COLBUN', codigo: '7402' , "DropDownIndex": "5"},
                { 'name': 'LONGAVI', codigo: '7403' , "DropDownIndex": "6"},
                { 'name': 'PARRAL', codigo: '7404' , "DropDownIndex": "7"},
                { 'name': 'RETIRO', codigo: '7405' , "DropDownIndex": "8"},
                { 'name': 'SAN JAVIER', codigo: '7406' , "DropDownIndex": "9"},
                { 'name': 'VILLA ALEGRE', codigo: '7407' , "DropDownIndex": "10"},
                { 'name': 'YERBAS BUENAS', codigo: '7408' , "DropDownIndex": "11"},
                { 'name': 'TALCA', codigo: '7101' , "DropDownIndex": "12"},
                { 'name': 'CONSTITUCION', codigo: '7102', "DropDownIndex": "13" },
                { 'name': 'CUREPTO', codigo: '7103' , "DropDownIndex": "14"},
                { 'name': 'EMPEDRADO', codigo: '7104' , "DropDownIndex": "15"},
                { 'name': 'MAULE', codigo: '7105', "DropDownIndex": "16" },
                { 'name': 'PELARCO', codigo: '7106' , "DropDownIndex": "17"},
                { 'name': 'PENCAHUE', codigo: '7107' , "DropDownIndex": "18"},
                { 'name': 'RIO CLARO', codigo: '7108' , "DropDownIndex": "19"},
                { 'name': 'SAN CLEMENTE', codigo: '7109' , "DropDownIndex": "20"},
                { 'name': 'SAN RAFAEL', codigo: '7110', "DropDownIndex": "21" },
                { 'name': 'CAUQUENES', codigo: '7201' , "DropDownIndex": "22"},
                { 'name': 'CHANCO', codigo: '7202' , "DropDownIndex": "23"},
                { 'name': 'PELLUHUE', codigo: '7203' , "DropDownIndex": "24"},
                { 'name': 'CURICO', codigo: '7301' , "DropDownIndex": "25"},
                { 'name': 'HUALANE', codigo: '7302', "DropDownIndex": "26" },
                { 'name': 'LICANTEN', codigo: '7303', "DropDownIndex": "27" },
                { 'name': 'MOLINA', codigo: '7304' , "DropDownIndex": "28"},
                { 'name': 'RAUCO', codigo: '7305' , "DropDownIndex": "29"},
                { 'name': 'ROMERAL', codigo: '7306' , "DropDownIndex": "30"}
            ]
        },
        {
            "name": "Ñuble",
            "romanNumber": "XVI",
            "number": "16",
            "DropDownIndex": "10",
            "communes": [
                { 'name': 'CHILLAN', codigo: '16101', "DropDownIndex": "1" },
                { 'name': 'BULNES', codigo: '16102', "DropDownIndex": "2" },
                { 'name': 'CHILLAN VIEJO', codigo: '16103', "DropDownIndex": "3" },
                { 'name': 'EL CARMEN', codigo: '16104' , "DropDownIndex": "4"},
                { 'name': 'PEMUCO', codigo: '16105' , "DropDownIndex": "5"},
                { 'name': 'PINTO', codigo: '16106', "DropDownIndex": "6" },
                { 'name': 'QUILLON', codigo: '16107' , "DropDownIndex": "7"},
                { 'name': 'SAN IGNACIO', codigo: '16108', "DropDownIndex": "8" },
                { 'name': 'YUNGAY', codigo: '16109', "DropDownIndex": "9" },
                { 'name': 'QUIRIHUE', codigo: '16201' , "DropDownIndex": "10"},
                { 'name': 'COBQUECURA', codigo: '16202', "DropDownIndex": "11" },
                { 'name': 'COELEMU', codigo: '16203' , "DropDownIndex": "12"},
                { 'name': 'NINHUE', codigo: '16204' , "DropDownIndex": "13"},
                { 'name': 'PORTEZUELO', codigo: '16205', "DropDownIndex": "14" },
                { 'name': 'RANQUIL', codigo: '16206', "DropDownIndex": "15" },
                { 'name': 'TREGUACO', codigo: '16207', "DropDownIndex": "16" },
                { 'name': 'SAN CARLOS', codigo: '16301', "DropDownIndex": "17" },
                { 'name': 'COIHUECO', codigo: '16302', "DropDownIndex": "18" },
                { 'name': 'NIQUEN', codigo: '16303' , "DropDownIndex": "19"},
                { 'name': 'SAN FABIAN', codigo: '16304' , "DropDownIndex": "20"},
                { 'name': 'SAN NICOLAS', codigo: '16305' , "DropDownIndex": "21"}
            ]
        },
        {
            "name": "Biobío",
            "romanNumber": "VIII",
            "number": "8",
            "DropDownIndex": "11",
            "communes": [
                { 'name': 'CONCEPCION', codigo: '8101', "DropDownIndex": "1" },
                { 'name': 'CORONEL', codigo: '8102' , "DropDownIndex": "2"},
                { 'name': 'HUALPEN', codigo: '8112' , "DropDownIndex": "3"},
                { 'name': 'ALTO BIO BIO', codigo: '8314', "DropDownIndex": "4" },
                { 'name': 'CHIGUAYANTE', codigo: '8103', "DropDownIndex": "5" },
                { 'name': 'FLORIDA', codigo: '8104', "DropDownIndex": "6" },
                { 'name': 'HUALQUI', codigo: '8105' , "DropDownIndex": "7"},
                { 'name': 'LOTA', codigo: '8106' , "DropDownIndex": "8"},
                { 'name': 'PENCO', codigo: '8107' , "DropDownIndex": "9"},
                { 'name': 'SAN PEDRO DE LA PAZ', codigo: '8108' , "DropDownIndex": "10"},
                { 'name': 'SANTA JUANA', codigo: '8109', "DropDownIndex": "11" },
                { 'name': 'TALCAHUANO', codigo: '8110' , "DropDownIndex": "12"},
                { 'name': 'TOME', codigo: '8111' , "DropDownIndex": "13"},
                { 'name': 'LEBU', codigo: '8201' , "DropDownIndex": "14"},
                { 'name': 'ARAUCO', codigo: '8202', "DropDownIndex": "15" },
                { 'name': 'CANETE', codigo: '8203' , "DropDownIndex": "16"},
                { 'name': 'CONTULMO', codigo: '8204' , "DropDownIndex": "17"},
                { 'name': 'CURANILAHUE', codigo: '8205' , "DropDownIndex": "18"},
                { 'name': 'LOS ALAMOS', codigo: '8206', "DropDownIndex": "19" },
                { 'name': 'TIRUA', codigo: '8207' , "DropDownIndex": "20"},
                { 'name': 'LOS ANGELES', codigo: '8301', "DropDownIndex": "21" },
                { 'name': 'ANTUCO', codigo: '8302' , "DropDownIndex": "22"},
                { 'name': 'CABRERO', codigo: '8303' , "DropDownIndex": "23"},
                { 'name': 'LAJA', codigo: '8304' , "DropDownIndex": "24"},
                { 'name': 'MULCHEN', codigo: '8305' , "DropDownIndex": "25"},
                { 'name': 'NACIMIENTO', codigo: '8306' , "DropDownIndex": "26"},
                { 'name': 'NEGRETE', codigo: '8307', "DropDownIndex": "27" },
                { 'name': 'QUILACO', codigo: '8308' , "DropDownIndex": "28"},
                { 'name': 'QUILLECO', codigo: '8309' , "DropDownIndex": "29"},
                { 'name': 'SAN ROSENDO', codigo: '8310' , "DropDownIndex": "30"},
                { 'name': 'SANTA BARBARA', codigo: '8311' , "DropDownIndex": "31"},
                { 'name': 'TUCAPEL', codigo: '8312' , "DropDownIndex": "32"},
                { 'name': 'YUMBEL', codigo: '8313' , "DropDownIndex": "33"}
            ]
        },
        {
            "name": "Araucanía",
            "romanNumber": "IX",
            "number": "9",
            "DropDownIndex": "12",
            "communes": [
                { 'name': 'CHOL CHOL', codigo: '9121' , "DropDownIndex": "1"},
                { 'name': 'TEMUCO', codigo: '9101' , "DropDownIndex": "2"},
                { 'name': 'CARAHUE', codigo: '9102' , "DropDownIndex": "3"},
                { 'name': 'CUNCO', codigo: '9103', "DropDownIndex": "4" },
                { 'name': 'CURARREHUE', codigo: '9104', "DropDownIndex": "5" },
                { 'name': 'FREIRE', codigo: '9105', "DropDownIndex": "6"},
                { 'name': 'GALVARINO', codigo: '9106', "DropDownIndex": "7" },
                { 'name': 'GORBEA', codigo: '9107' , "DropDownIndex": "8"},
                { 'name': 'LAUTARO', codigo: '9108' , "DropDownIndex": "9"},
                { 'name': 'LONCOCHE', codigo: '9109' , "DropDownIndex": "10"},
                { 'name': 'MELIPEUCO', codigo: '9110' , "DropDownIndex": "11"},
                { 'name': 'NUEVA IMPERIAL', codigo: '9111' , "DropDownIndex": "12"},
                { 'name': 'PADRE LAS CASAS', codigo: '9112' , "DropDownIndex": "13"},
                { 'name': 'PERQUENCO', codigo: '9113' , "DropDownIndex": "14"},
                { 'name': 'PITRUFQUEN', codigo: '9114' , "DropDownIndex": "15"},
                { 'name': 'PUCON', codigo: '9115' , "DropDownIndex": "16"},
                { 'name': 'SAAVEDRA', codigo: '9116' , "DropDownIndex": "17"},
                { 'name': 'TEODORO SCHMIDT', codigo: '9117', "DropDownIndex": "18" },
                { 'name': 'TOLTEN', codigo: '9118' , "DropDownIndex": "19"},
                { 'name': 'VILCUN', codigo: '9119' , "DropDownIndex": "20"},
                { 'name': 'VILLARRICA', codigo: '9120', "DropDownIndex": "21" },
                { 'name': 'ANGOL', codigo: '9201' , "DropDownIndex": "22"},
                { 'name': 'COLLIPULLI', codigo: '9202', "DropDownIndex": "23" },
                { 'name': 'CURACAUTIN', codigo: '9203', "DropDownIndex": "24" },
                { 'name': 'ERCILLA', codigo: '9204', "DropDownIndex": "25" },
                { 'name': 'LONQUIMAY', codigo: '9205', "DropDownIndex": "26" },
                { 'name': 'LOS SAUCES', codigo: '9206' , "DropDownIndex": "27"},
                { 'name': 'LUMACO', codigo: '9207' , "DropDownIndex": "28"},
                { 'name': 'PUREN', codigo: '9208' , "DropDownIndex": "29"},
                { 'name': 'RENAICO', codigo: '9209' , "DropDownIndex": "30"},
                { 'name': 'TRAIGUEN', codigo: '9210' , "DropDownIndex": "31"},
                { 'name': 'VICTORIA', codigo: '9211' , "DropDownIndex": "32"}
            ]
        },
        {
            "name": "Los Ríos",
            "romanNumber": "XIV",
            "number": "14",
            "DropDownIndex": "13",
            "communes": [
                { 'name': 'VALDIVIA', codigo: '14101' , "DropDownIndex": "1"},
                { 'name': 'CORRAL', codigo: '14102' , "DropDownIndex": "2"},
                { 'name': 'FUTRONO', codigo: '14202' , "DropDownIndex": "3"},
                { 'name': 'LA UNION', codigo: '14201' , "DropDownIndex": "4"},
                { 'name': 'LAGO RANCO', codigo: '14203', "DropDownIndex": "5" },
                { 'name': 'LANCO', codigo: '14103', "DropDownIndex": "6" },
                { 'name': 'LOS LAGOS', codigo: '14104' , "DropDownIndex": "7"},
                { 'name': 'MAFIL', codigo: '14105' , "DropDownIndex": "8"},
                { 'name': 'MARIQUINA', codigo: '14106', "DropDownIndex": "9" },
                { 'name': 'PAILLACO', codigo: '14107', "DropDownIndex": "10"},
                { 'name': 'PANGUIPULLI', codigo: '14108' , "DropDownIndex": "11"},
                { 'name': 'RIO BUENO', codigo: '14204', "DropDownIndex": "12" }
            ]
        },
        {
            "name": "Los Lagos",
            "romanNumber": "X",
            "number": "10",
            "DropDownIndex": "14",
            "communes": [
                { 'name': 'COCHAMO', codigo: '10103' , "DropDownIndex": "1"},
                { 'name': 'FRESIA', codigo: '10104' , "DropDownIndex": "2"},
                { 'name': 'FRUTILLAR', codigo: '10105' , "DropDownIndex": "3"},
                { 'name': 'LOS MUERMOS', codigo: '10106', "DropDownIndex": "4" },
                { 'name': 'LLANQUIHUE', codigo: '10107', "DropDownIndex": "5" },
                { 'name': 'MAULLIN', codigo: '10108', "DropDownIndex": "6" },
                { 'name': 'PUERTO VARAS', codigo: '10109' , "DropDownIndex": "7"},
                { 'name': 'CASTRO', codigo: '10201' , "DropDownIndex": "8"},
                { 'name': 'ANCUD', codigo: '10202' , "DropDownIndex": "9"},
                { 'name': 'CHONCHI', codigo: '10203' , "DropDownIndex": "10"},
                { 'name': 'CURACO DE VELEZ', codigo: '10204' , "DropDownIndex": "11"},
                { 'name': 'DALCAHUE', codigo: '10205' , "DropDownIndex": "12"},
                { 'name': 'PUQUELDON', codigo: '10206' , "DropDownIndex": "13"},
                { 'name': 'QUEILEN', codigo: '10207' , "DropDownIndex": "14"},
                { 'name': 'QUELLON', codigo: '10208' , "DropDownIndex": "15"},
                { 'name': 'PUERTO MONTT', codigo: '10101', "DropDownIndex": "16" },
                { 'name': 'CALBUCO', codigo: '10102' , "DropDownIndex": "17"},
                { 'name': 'QUEMCHI', codigo: '10209' , "DropDownIndex": "18"},
                { 'name': 'QUINCHAO', codigo: '10210' , "DropDownIndex": "19"},
                { 'name': 'OSORNO', codigo: '10301' , "DropDownIndex": "20"},
                { 'name': 'PUERTO OCTAY', codigo: '10302' , "DropDownIndex": "21"},
                { 'name': 'PURRANQUE', codigo: '10303', "DropDownIndex": "22" },
                { 'name': 'PUYEHUE', codigo: '10304', "DropDownIndex": "23" },
                { 'name': 'RIO NEGRO', codigo: '10305' , "DropDownIndex": "24"},
                { 'name': 'SAN JUAN DE LA COSTA', codigo: '10306', "DropDownIndex": "25" },
                { 'name': 'SAN PABLO', codigo: '10307' , "DropDownIndex": "26"},
                { 'name': 'CHAITEN', codigo: '10401' , "DropDownIndex": "27"},
                { 'name': 'FUTALEUFU', codigo: '10402' , "DropDownIndex": "28"},
                { 'name': 'HUALAIHUE', codigo: '10403' , "DropDownIndex": "29"},
                { 'name': 'PALENA', codigo: '10404' , "DropDownIndex": "30"}
            ]
        },
        {
            "name": "Aisén del Gral. Carlos Ibáñez del Campo",
            "romanNumber": "XI",
            "number": "11",
            "DropDownIndex": "15",
            "communes": [
                { 'name': 'COIHAIQUE', codigo: '11101', "DropDownIndex": "1" },
                { 'name': 'LAGO VERDE', codigo: '11102', "DropDownIndex": "2" },
                { 'name': 'AISEN', codigo: '11201' , "DropDownIndex": "3"},
                { 'name': 'CISNES', codigo: '11202' , "DropDownIndex": "4"},
                { 'name': 'GUAITECAS', codigo: '11203' , "DropDownIndex": "5"},
                { 'name': 'COCHRANE', codigo: '11301' , "DropDownIndex": "6"},
                { 'name': 'O´HIGGINS', codigo: '11302' , "DropDownIndex": "7"},
                { 'name': 'TORTEL', codigo: '11303' , "DropDownIndex": "8"},
                { 'name': 'CHILE CHICO', codigo: '11401' , "DropDownIndex": "9"},
                { 'name': 'RIO IBANEZ', codigo: '11402' , "DropDownIndex": "10"}
            ]
        },
        {
            "name": "Magallanes y de la Antártica Chilena",
            "romanNumber": "XII",
            "number": "12",
            "DropDownIndex": "16",
            "communes": [
                { 'name': 'CABO DE HORNOS', codigo: '12201' , "DropDownIndex": "1"},
                { 'name': 'ANTARTICA', codigo: '12202' , "DropDownIndex": "2"},
                { 'name': 'PORVENIR', codigo: '12301' , "DropDownIndex": "3"},
                { 'name': 'PRIMAVERA', codigo: '12302' , "DropDownIndex": "4"},
                { 'name': 'TIMAUKEL', codigo: '12303' , "DropDownIndex": "5"},
                { 'name': 'PUERTO NATALES', codigo: '12401', "DropDownIndex": "6" },
                { 'name': 'TORRES DEL PAINE', codigo: '12402', "DropDownIndex": "7" },
                { 'name': 'PUNTA ARENAS', codigo: '12101', "DropDownIndex": "8"},
                { 'name': 'LAGUNA BLANCA', codigo: '12102' , "DropDownIndex": "9"},
                { 'name': 'RIO VERDE', codigo: '12103' , "DropDownIndex": "10"},
                { 'name': 'SAN GREGORIO', codigo: '12104' , "DropDownIndex": "11"}
            ]
        },
        {
            "name": "SIN REGION ASOCIADA",
            "romanNumber": "0",
            "number": "0",
            "DropDownIndex": "17",
            "communes": [
                { 'name': 'SIN COMUNA', codigo: '0' , "DropDownIndex": "1"}
            ]
        }
    ]
}


