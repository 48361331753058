import React from 'react';
// import './App.css';
// import './bootstrap.min.css';
import { BrowserRouter as Router } from "react-router-dom";
import PreSolicitud from './landing_presolicitud';
// const { format } = require('rut.js')


export default class IngresoSolicitud extends React.Component {


  // constructor(props) {

  //   super(props);
  // }

 

  NewHOC = (PassedComponent) => {
    return class extends React.Component {
      render() {
        return (
          <div>
            <PassedComponent {...this.props} />
          </div>
        )
      }
    }
  }

  render() {
    const PreSolicitudHoc= this.NewHOC(PreSolicitud);
    return <Router>
      <PreSolicitudHoc propiedades={this.props}></PreSolicitudHoc>
    </Router>

  }
}

