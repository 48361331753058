
import React from "react";
import BlockUi from 'react-block-ui'
import Recaptcha from 'react-google-invisible-recaptcha';
import ModalInformativo from '../../componentes/modal'
import ReactHtmlParser from 'react-html-parser';
import Footer from "../../componentes/footer";
import Comentarios from '../../componentes/comentariosFinales'
import Header from "../../componentes/header";
import UploadFile from "../../componentes/uploadFileCustom";
import UploadMultipleFiles from "../../componentes/uploadMultipleFiles";
import { Button } from 'react-bootstrap'
const moment = require('moment-timezone');
const region_comuna = require('../../util/comunas-regiones');
const { format } = require('rut.js')

class Solicitud extends React.Component {


    constructor(props) {
        super(props);

        this.state = {
            comunaRegionReceptor: [],
            medioPago: "D",
            bancoSelected: "",
            rutCausante: "",
            nombreBeneficiario: "",
            correoBeneficiario: "",
            apellidoPaternoBeneficiario: "",
            nroSerieBeneficiario: "",
            certificadoETCargado: false,
            MandatoCargado: false,
            DictamenCargado: false,
            direccionBeneficiario: "",
            comunaBeneficiario: "",
            tipoVigenciaBeneficiario: "",
            ciudadBeneficiario: "",
            regionBeneficiario: "0",
            telefonoBeneficiario: "",
            celularBeneficiario: "",
            celularBeneficiarioConfirmacion: "",
            celularBeneficiarioOpcional: "",
            rutBeneficiario: "",
            tipoReceptor: "",
            rutReceptor: "",
            nombreReceptor: "",
            displayCargaMandato: 'none',
            displayCargaDocumentoAdicional: 'none',
            DctoAdicionalCargado: false,
            displayCertificadoET: 'none',
            displayCargaDictamen: 'none',
            apellidoPaternoReceptor: "",
            apellidoMaternoReceptor: "",
            nroSerieReceptor: "",
            nroSerieReceptorConfirmacion: "",
            regionReceptor: "0",
            comunaReceptor: "",
            ciudadReceptor: "",
            fechaNacimientoBeneficiario: "",
            direccionReceptor: "",
            celularReceptor: "",
            generoBeneficiario: "",
            celularReceptorConfirmacion: "",
            celularReceptorConfirmacionOpcional: "",
            correoReceptor: "",
            correoReceptorConfirmacion: "",
            infoTelefonoBeneficiario: "",
            infoCelularBeneficiario: "",
            infoCelularBeneficiarioConfirmacion: "",
            infoCelularBeneficiarioOpcion: "",
            displayUploadCertificadoET: 'block',
            displayUploadDictamen: 'none',
            displayUploadMandato: 'none',
            mandato: "",
            autorizaSubscripcionCertificados: false,
            selectedFileCertificadoET: null,
            selectedFileDictamen: null,
            selectedFileMandato: null,
            selectedFilesDocumentoAdicionl: null,
            selectedFileAutorizacionFirmada: null,
            selectFilesDocumentoAdicionalInfo: [],
            dictamen: "",
            mensajeBloqueo: "",
            blocking: false,
            institucionSalud: "",
            certificadoET: "S",
            colorCertificadoET: 'red',
            colorDictamen: 'red',
            colorMandato: 'red',
            nroCuenta: "",
            tipoCuenta: "",
            maxlengthCuenta: 12,
            parentescoBeneficiario: "",
            apellidoMaternoBeneficiario: "",
            idEjecutivo: "",
            displayNroCuenta: 'none',
            ipIngresoSolicitud: "",
            displayDctoAdicional: "none",
            documentacionAdicionalSeleccionado: false,
            displayUploadAutorizacionFirmada: 'none',
            AutorizacionFirmadaCargada: false,
            displayLugarReposoOtro: 'none',
            colorAutorizacionFirmada: 'red',
            regionLugarReposo: [],
            comunaLugarReposo: [],
            comunasLugarReposo: [],
            ciudadLugarReposo: '',
            calleLugarReposo: '',
            numeroLugarReposo: '',
            sitekey: process.env.REACT_APP_SITEKEY,
            URL_REDIRECCION: process.env.REACT_APP_URL_URL_REDIRECT,
            URL_INGRESO_SOLICITUD: process.env.REACT_APP_URL_INGRESO_SOLICITUD,
            URL_CARGA_DOC_CM: process.env.REACT_APP_URL_CARGA_DOC_CM,
            URL_CARGA_DOC_GCP: process.env.REACT_APP_URL_CARGA_DOC_GCP,
            URL_NRO_DOCUMENTO_VALIDAR: process.env.REACT_APP_URL_NRO_DOCUMENTO_VALIDAR,
            bancos: [
                'BANCO DE CHILE',
                'BANCO INTERNACIONAL',
                'SCOTIABANK CHILE',
                'BANCO DE CRÉDITO E INVERSIONES',
                'BANCO BICE',
                'HSBC BANK CHILE',
                'BANCO SANTANDER CHILE',
                'BANCO ITAU',
                'BANCO SECURITY',
                'BANCO FALABELLA',
                // 'BANCO RIPLEY',
                'BANCO CONSORCIO',
                // 'SCOTIABANK AZUL',
                // 'BANCO BTG PACTUAL CHILE',
                'BANCO ESTADO',
                // 'COOPEUCH',
                // 'SOCIEDAD EMISORA DE TARJETAS LOS HÉROES'
                'CORP BANCA',
                'BANCO DE A. EDWARDS (CITI)',
                'CITIBANK N.A.',
                'BANCO DE SANTIA',
                'BANCO BANEFE',
                'BANCO BBVA',
                'BANCO DEL DESARROLLO',
                'BANCO DE LA NACION ARGENTINA'
            ],
            bancoDisplay: 'none',
            codigosBanco: [
                { banco: 'BANCO DE CHILE', codigo: 1, DropDownIndex: 1 },
                { banco: 'BANCO INTERNACIONAL', codigo: 9, DropDownIndex: 2 },
                { banco: 'SCOTIABANK CHILE', codigo: 14, DropDownIndex: 3 },
                { banco: 'BANCO DE CRÉDITO E INVERSIONES', codigo: 16, DropDownIndex: 4 },
                { banco: 'BANCO BICE', codigo: 28, DropDownIndex: 5 },
                { banco: 'HSBC BANK CHILE', codigo: 31, DropDownIndex: 6 },
                { banco: 'BANCO SANTANDER CHILE', codigo: 37, DropDownIndex: 7 },
                { banco: 'BANCO ITAU', codigo: 39, DropDownIndex: 8 },
                { banco: 'BANCO SECURITY', codigo: 49, DropDownIndex: 9 },
                { banco: 'BANCO FALABELLA', codigo: 51, DropDownIndex: 10 },
                // { banco: 'BANCO RIPLEY', codigo: 53, DropDownIndex: 11 },
                { banco: 'BANCO CONSORCIO', codigo: 55, DropDownIndex: 11 },
                // { banco: 'SCOTIABANK AZUL', codigo: 504, DropDownIndex: 13 },
                // { banco: 'BANCO BTG PACTUAL CHILE', codigo: 59, DropDownIndex: 14 },
                { banco: 'BANCO ESTADO', codigo: 12, DropDownIndex: 12 },
                // { banco: 'COOPEUCH', codigo: 672, DropDownIndex: 16 },
                // { banco: 'SOCIEDAD EMISORA DE TARJETAS LOS HÉROES', codigo: 729, DropDownIndex: 17 },
                { banco: 'CORP BANCA', codigo: 27, DropDownIndex: 13 },
                { banco: 'BANCO DE A. EDWARDS (CITI)', codigo: 29, DropDownIndex: 14 },
                { banco: 'CITIBANK N.A.', codigo: 33, DropDownIndex: 15 },
                { banco: 'BANCO DE SANTIA', codigo: 35, DropDownIndex: 16 },
                { banco: 'BANCO BANEFE', codigo: 256, DropDownIndex: 17 },
                { banco: 'BANCO BBVA', codigo: 504, DropDownIndex: 18 },
                { banco: 'BANCO DEL DESARROLLO', codigo: 507, DropDownIndex: 19 },
                { banco: 'BANCO DE LA NACION ARGENTINA', codigo: 43, DropDownIndex: 20 }

            ],
            codigosCuenta: [
                { cuenta: 'CUENTA RUT', tipo: "R", combo: 'Cta. Rut', DropDownIndex: 1 },
                { cuenta: 'CUENTA CORRIENTE', tipo: "B", combo: 'Cta. Corriente', DropDownIndex: 1 },
                { cuenta: 'CUENTA AHORRO', tipo: "A", combo: 'Cta. de Ahorro', DropDownIndex: 2 },
                { cuenta: 'CUENTA VISTA', tipo: "V", combo: 'Cta. vista', DropDownIndex: 3 },
                { cuenta: 'VACIA???', tipo: "E", DropDownIndex: 4 },
                { cuenta: 'VACIA', tipo: "P", DropDownIndex: 5 },
            ],
            cuentas: [
                'Cta. Corriente',
                'Cta. de Ahorro',
                'Cta. vista',
                'Cta. de Ahorro a plazo', //NO ESTA
                'Chequera Electrónica' // NO ESTA
            ],
        }
    }

    componentDidMount() {

        //obtencion IP
        fetch('https://geoip-db.com/json/')
            .then(response => response.json())
            .then(response => this.setState({ ipIngresoSolicitud: response.IPv4 }))
            .catch(error => this.setState({ ipIngresoSolicitud: "127.0.0.1" }))


        console.log("mounted")
        // document.getElementById('comboMedioPago').selectedIndex = 0;
        document.getElementById('comboTipodeVigencia').selectedIndex = 0;
        document.getElementById('comboTipoReceptor').selectedIndex = 0;
        // document.getElementById('comboCertificadoET').selectedIndex = 0;
        document.getElementById('cbDictamen').selectedIndex = 0;
        document.getElementById('comboMandato').selectedIndex = 0;
        // document.getElementById('comboComunaBeneficiario').selectedIndex = 0;
        document.getElementById('comboComunaReceptor').selectedIndex = 0;
        // document.getElementById('comboRegionBeneficiario').selectedIndex = 0;
        document.getElementById('comboRegionReceptor').selectedIndex = 0;
        console.log(this.props)
        if (this.props) {
            var { rutBeneficiario,
                fechaNacimientoBeneficiario,
                nroSerieBeneficiario,
                rutCausante,
                apellidoMaternoBeneficiario,
                apellidoPaternoBeneficiario,
                generoBeneficiario,
                parentescoBeneficiario,
                tipoIngresoSolicitud,
                nombreBeneficiario,
                idEjecutivo,
                dataBeneficiario } = this.props

            //SET COMBO DIRECCION BD
            let regionBD = region_comuna.region_comuna.regions.filter(region => region.number === dataBeneficiario.COD_REGION)[0]
            let comunaRegionBD = regionBD ? region_comuna.region_comuna.regions
                .filter(region => region.number.toString() === regionBD.number)[0].communes.find(comuna => comuna.name === dataBeneficiario.DESCRIPCION_COMUNA) : region_comuna.region_comuna.regions
                    .filter(region => region.number.toString() === "0")[0].communes.find(comuna => comuna.name === dataBeneficiario.DESCRIPCION_COMUNA)

            //let medioPagoBD = dataBeneficiario.NOMBRE_TIPO_VIA_PAGO.length > 3 ? dataBeneficiario.TIPO_VIA_PAGO : ""

            let bancoBD = this.state.codigosBanco.filter(banco => banco.codigo.toString() === dataBeneficiario.COD_INST_FINANC_DEPOSITO.toString())[0]
            console.log("BANDO BD x", bancoBD)
            if (bancoBD && bancoBD.banco === "BANCO ESTADO") {
                this.setState({ cuentas: ['Cta. Rut', 'Cta. Corriente', 'Cta. de Ahorro', 'Cta. vista'] })
            } else if (bancoBD && bancoBD.banco === "COOPEUCH") {
                this.setState({ cuentas: ['Cta. vista'] })
            }


            let tipoCuentaBancoBD = this.state.codigosCuenta.find(cuenta => cuenta.tipo === dataBeneficiario.TIPO_CUENTA_DEPOSITO)

            console.log("tipoCuentaBancoBD BD ", tipoCuentaBancoBD, dataBeneficiario)
            document.getElementById('comboRegionBeneficiario').selectedIndex = regionBD ? parseInt(regionBD.DropDownIndex) : 0
            document.getElementById('comboComunaBeneficiario').selectedIndex = comunaRegionBD ? parseInt(comunaRegionBD.DropDownIndex) : 0;
            // document.getElementById('comboMedioPago').selectedIndex = tipoCuentaBancoBD ? 1 : 0
            document.getElementById('comboBanco').selectedIndex = bancoBD ? bancoBD.DropDownIndex : 0;
            document.getElementById('comboTipoCuenta').selectedIndex = tipoCuentaBancoBD ? tipoCuentaBancoBD.DropDownIndex : 0;

            this.setState({
                rutBeneficiario,
                idEjecutivo,
                fechaNacimientoBeneficiario,
                nroSerieBeneficiario,
                rutCausante,
                nombreBeneficiario,
                apellidoMaternoBeneficiario,
                apellidoPaternoBeneficiario,
                generoBeneficiario,
                parentescoBeneficiario,
                tipoIngresoSolicitud,
                direccionBeneficiario: dataBeneficiario.DIRECCION,
                comunaBeneficiario: comunaRegionBD ? comunaRegionBD.codigo : "",
                ciudadBeneficiario: dataBeneficiario.CIUDAD,
                telefonoBeneficiario: dataBeneficiario.TELEFONO,
                celularBeneficiario: dataBeneficiario.CELULAR_PRINCIPAL,
                correoBeneficiario: dataBeneficiario.EMAIL,
                correoBeneficiarioConfirmacion: dataBeneficiario.EMAIL,
                tipoVigenciaBeneficiario: dataBeneficiario.TIPO_VIGENCIA,
                rutReceptor: format(dataBeneficiario.ID_RECEPTOR),
                regionBeneficiario: regionBD ? regionBD.number : "",
                medioPago: "D", //tipoCuentaBancoBD ? medioPagoBD : '',
                bancoDisplay: 'block',
                bancoSelected: bancoBD ? bancoBD.banco : '',
                nroCuenta: dataBeneficiario.NUM_CUENTA_DEPOSITO,
                nroCuentaConfirm: dataBeneficiario.NUM_CUENTA_DEPOSITO,
                tipoCuenta: tipoCuentaBancoBD ? tipoCuentaBancoBD.combo : '',
                displayNroCuenta: tipoCuentaBancoBD ? tipoCuentaBancoBD.combo !== "" ? 'block' : 'none' : 'none'
            })

            // document.getElementById('comboComunaBeneficiario').selectedIndex = 0;
        }


    }


    HandlerDireccionBeneficiario = (e) => {
        var direccion = e.target.value
        this.setState({ direccionBeneficiario: direccion, disableSubmit: true })
    }

    HandlerCalleLugarReposo = (e) => {
        var direccion = e.target.value
        this.setState({ calleLugarReposo: direccion, disableSubmit: true })
    }

    HandlerNumeroLugarReposo = (e) => {
        var numero = e.target.value
        if (isNaN(numero)) {
            this.setState({ numeroLugarReposo: "", showModalInformativo: true, textModalInformativo: "El valor ingresado debe contener solo números" })
            return
        }
        this.setState({ numeroLugarReposo: numero, disableSubmit: true })
    }


    HandlerTelefonoBeneficiario = (e) => {
        var telefonoBeneficiario = e.target.value
        if (isNaN(telefonoBeneficiario)) {
            this.setState({ telefonoBeneficiario: "", showModalInformativo: true, textModalInformativo: "El valor ingresado debe contener solo números" })
            return
        } else if (telefonoBeneficiario.charAt(0) !== "2") {
            this.setState({ telefonoBeneficiario: "", showModalInformativo: true, textModalInformativo: "El número de telefono debe comenzar con 2" })
            return
        }
        this.setState({ telefonoBeneficiario, disableSubmit: true })
    }


    HandlerCiudadBeneficiario = (e) => {
        var ciudad = e.target.value
        if (!isNaN(e.target.value)) {
            this.setState({ showModalInformativo: true, textModalInformativo: "La ciudad no puede contener valores numéricos" })
            return
        }
        this.setState({ ciudadBeneficiario: ciudad, disableSubmit: true })
    }


    HandlerCiudadLugarReposo = (e) => {
        var ciudad = e.target.value
        if (!isNaN(e.target.value)) {
            this.setState({ showModalInformativo: true, textModalInformativo: "La ciudad no puede contener valores numéricos", disableSubmit: true })
            return
        }
        this.setState({ ciudadLugarReposo: ciudad, disableSubmit: true })
    }

    // HandlerMedioPago = (e) => {
    //     var medioPago = "D"

    //     // if (medioPago === 0) {
    //     //     document.getElementById('comboBanco').selectedIndex = 0;
    //     //     document.getElementById('comboTipoCuenta').selectedIndex = 0;
    //     //     this.setState({ medioPago: "", displayNroCuenta: 'none', displayNroCuentaHeroes: 'none', bancoDisplay: 'block', bancoSelected: "", tipoCuenta: "", nroCuentaHeroes: "", nroCuenta: "", roCuentaHeroesConfirm: "", nroCuentaConfirm: "" })
    //     //     return
    //     // }
    //     // document.getElementById('comboBanco').selectedIndex = 0;
    //     // document.getElementById('comboTipoCuenta').selectedIndex = 0;



    //     this.setState({ medioPago, displayNroCuentaHeroes: 'none', bancoDisplay: 'block', nroCuentaHeroes: "", nroCuentaHeroesConfirm: "" })
    // }


    HandlerBanco = (e) => {
        var banco = e.target.value
        document.getElementById('comboTipoCuenta').selectedIndex = 0;
        if (banco === 0) {
            this.setState({ bancoSelected: "", tipoCuenta: "", nroCuentaHeroes: "", nroCuenta: "", roCuentaHeroesConfirm: "", nroCuentaConfirm: "" })
            return;
        }

        if (banco === 'BANCO FALABELLA') {
            this.setState({ maxlengthCuenta: 13 })
        } else {
            this.setState({ maxlengthCuenta: 12 })
        }

        if (banco === "BANCO ESTADO") {
            this.setState({ bancoSelected: banco, tipoCuenta: "", nroCuentaHeroes: "", nroCuenta: "", roCuentaHeroesConfirm: "", nroCuentaConfirm: "", cuentas: ['Cta. Rut', 'Cta. Corriente', 'Cta. de Ahorro', 'Cta. vista'] })
            return;
        } else if (banco === "COOPEUCH") {
            this.setState({ bancoSelected: banco, tipoCuenta: "", nroCuentaHeroes: "", nroCuenta: "", roCuentaHeroesConfirm: "", nroCuentaConfirm: "", cuentas: ['Cta. vista'] })
            return;
        }
        this.setState({ bancoSelected: banco, tipoCuenta: "", nroCuentaHeroes: "", nroCuenta: "", roCuentaHeroesConfirm: "", nroCuentaConfirm: "", cuentas: ['Cta. Corriente', 'Cta. de Ahorro', 'Cta. vista'] })

    }



    HandlerTipoCuenta = (e) => {
        var tipoCuenta = e.target.value
        var { rutBeneficiario, rutReceptor } = this.state


        if (tipoCuenta === 0) {
            this.setState({ tipoCuenta: "", nroCuentaHeroes: "", nroCuenta: "", nroCuentaHeroesConfirm: "", nroCuentaConfirm: "", displayNroCuenta: 'block' })
            return
        } else if (tipoCuenta === 'Cta. Rut') {
            //CUENTA RUT
            if (!rutReceptor === "") {
                this.setState({ tipoCuenta, nroCuentaHeroes: "", nroCuentaHeroesConfirm: "", nroCuenta: this.LimpiarFormatoRut(rutReceptor.slice(0, -1)), displayNroCuenta: 'block' })
            } else {
                this.setState({ tipoCuenta, nroCuentaHeroes: "", nroCuentaHeroesConfirm: "", nroCuenta: this.LimpiarFormatoRut(rutBeneficiario.slice(0, -1)), displayNroCuenta: 'block' })
            }
            return
        }
        this.setState({ tipoCuenta, nroCuentaHeroes: "", nroCuenta: "", roCuentaHeroesConfirm: "", nroCuentaConfirm: "", displayNroCuenta: 'block' })
    }

    HandlerNroCuenta = (e) => {
        var nroCuenta = e.target.value
        if (isNaN(nroCuenta)) {
            this.setState({ nroCuenta: "", nroCuentaConfirm: "", infoNroCuenta: "El numero de cuenta solo debe incluir valores numericos" })
            return
        }
        this.setState({ nroCuenta, nroCuentaConfirm: "", infoNroCuenta: "" })
    }

    HandlerNroCuentaConfirm = (e) => {
        var cuentaConfirm = e.target.value
        this.setState({ nroCuentaConfirm: cuentaConfirm, infoNroCuentaConfirm: "" })
    }

    HandlerNroCuentaConfirmBlur = (e) => {
        var { nroCuenta, nroCuentaConfirm } = this.state
        if (nroCuenta !== nroCuentaConfirm) {
            this.setState({ nroCuentaConfirm: "", infoNroCuentaConfirm: "El número de cuenta ingresado en la confirmación debe ser igual al original" })
            return
        }
        this.setState({ nroCuentaConfirm: nroCuenta, diclaimerEmpty: "", infoNroCuentaConfirm: "" })
    }

    HandlerNroCuentaHeroes = (e) => {
        var nroCuentaHeroes = e.target.value
        if (isNaN(nroCuentaHeroes)) {
            this.setState({ nroCuentaHeroesConfirm: "", nroCuentaHeroes: "", infoNroCuentaHeroe: "El número de cuenta solo debe incluir valores numericos y tener un largo de 9 digitos" })
            return
        }
        this.setState({ nroCuentaHeroes, nroCuentaHeroesConfirm: "", infoNroCuentaHeroe: "" })
    }


    HandlerNroCuentaHeroesConfirm = (e) => {
        var nroCuentaHeroesConfirm = e.target.value
        this.setState({ nroCuentaHeroesConfirm, infoNroCuentaHeroeconfirm: "" })
    }

    HandlerNroCuentaHeroesConfirmBlur = () => {
        var { nroCuentaHeroesConfirm, nroCuentaHeroes } = this.state;

        if (nroCuentaHeroesConfirm !== nroCuentaHeroes) {
            this.setState({ nroCuentaHeroesConfirm: "", infoNroCuentaHeroeconfirm: "La cuenta de confirmacion no coincide con la original" })
            return
        }
    }

    HandlerEmail = (e) => {
        var email = e.target.value
        this.setState({ correoBeneficiario: email, correoBeneficiarioConfirmacion: "", disableSubmit: true })

    }


    HandlerEmailBlur = (e) => {
        var email = this.state.correoBeneficiario
        if (!this.ValidarMail(email)) {
            this.setState({ correoBeneficiario: "", showModalInformativo: true, textModalInformativo: "El correoBeneficiario ingresado no tiene el formato correcto xxx@xxx.xx" })
            return
        } else {
            this.setState({ disableSubmit: false })
        }
    }



    HandlerEmailValidacion = (e) => {
        var emailValidacion = e.target.value
        this.setState({ correoBeneficiarioConfirmacion: emailValidacion, disableSubmit: true })

    }


    HandlerEmailValidationBlur = (e) => {
        var { correoBeneficiario, correoBeneficiarioConfirmacion } = this.state
        if (!this.ValidarMail(correoBeneficiarioConfirmacion)) {
            this.setState({ correoBeneficiarioConfirmacion: "", showModalInformativo: true, textModalInformativo: "El correoBeneficiario ingresado no tiene el formato correcto xxx@xxx.xx" })
            return
        } else if (correoBeneficiario !== correoBeneficiarioConfirmacion) {
            this.setState({ correoBeneficiarioConfirmacion: "", showModalInformativo: true, textModalInformativo: "Ambos correso ingresados deben ser iguales" })
            return
        } else {
            this.setState({ disableSubmit: false })
        }
    }

    HandlerRegionBeneficiario = (e) => {
        console.log("num region ", e.target.value)
        let region = e.target.value
        if (region === "0") {
            return;
        }

        let comunaRegion = region_comuna.region_comuna.regions
            .filter(region => region.number.toString() === e.target.value.toString())[0].communes


        this.setState({ regionBeneficiario: region, comunaBeneficiario: comunaRegion })
        document.getElementById('comboComunaBeneficiario').selectedIndex = 0;
    }

    HandlerRegionLugarReposo = (e) => {

        let region = e.target.value
        if (region === "0") {
            return;
        }

        let comunaRegion = region_comuna.region_comuna.regions
            .filter(region => region.number.toString() === e.target.value.toString())[0].communes
        this.setState({ regionLugarReposo: region, comunasLugarReposo: comunaRegion })
        document.getElementById('comboComunaLugarReposo').selectedIndex = 0;
    }

    HandlerComunaBeneficiario = (e) => {
        let comuna = e.target.value
        if (comuna === "0") {
            return;
        }
        this.setState({ comunaBeneficiario: comuna })

    }


    HandlerComunaLugarReposo = (e) => {
        let comuna = e.target.value
        if (comuna === "0") {
            return;
        }
        this.setState({ comunaLugarReposo: comuna })

    }

    HandlerTipoVigencia = (e) => {
        let vigencia = e.target.value
        if (vigencia === "0") {
            return;
        }
        this.setState({ tipoVigenciaBeneficiario: vigencia })
    }

    HandlerComboLugarReposo = (e) => {
        let lugarReposo = e.target.value
        if (lugarReposo === "OTR" || lugarReposo === "HOSP") {
            this.setState({ lugarReposo: lugarReposo, displayLugarReposoOtro: 'block' })
            return;
        } else {
            this.setState({ lugarReposo: lugarReposo, displayLugarReposoOtro: 'none' })
        }

    }


    LimpiarRut = (rutvalor) => {
        // eslint-disable-next-line
        var rutv = rutvalor.replace(/\./g, "");
        // eslint-disable-next-line
        rutv = rutv.replace(/\-/g, "");
        rutv = rutv.padStart(10, "0");
        return rutv;
    }

    LimpiarFormatoRut = (rutvalor) => {
        // eslint-disable-next-line
        var rutv = rutvalor.replace(/\./g, "");
        // eslint-disable-next-line
        rutv = rutv.replace(/\-/g, "");
        //rutv = rutv.padStart(10, "0");
        return rutv;
    }

    LimpiarFormatoRutValidSerie = (rutvalor) => {
        var rutv = rutvalor.replace(/\./g, "");
        return rutv;
    }
    HandleRutReceptor = (e) => {
        var rutReceptor = e.target.value
        this.setState({ disableSubmit: true, rutReceptor: format(rutReceptor) })
    }


    HandleRutReceptorBlur = async (e) => {
        console.log("blur")
        // var { rutbeneficiario, rutReceptor } = this.state
        // document.getElementById('submitButton').className += " disabled"
        // await fetch(this.state.backURLValidCausante, {
        //     method: 'POST',
        //     body: JSON.stringify({ "rutBeneficiario": rutbeneficiario, "rutCausante": rutReceptor }),
        //     headers: {
        //         'Content-Type': 'application/json'
        //     }
        // }).then(res => res)
        //     .then((response) => {
        //         if (response.status === 400) {
        //             this.setState({ rutReceptor: "", showModalInformativo: true, textModalInformativo: "El rut ingresado no se encuentra en nuestros registros , por lo que deberá acercarse de manera presencial a una sucursal de nuestra AFP para actualizar y/o regularizar su información" })
        //             return;
        //         } else {
        //             const letras = Array.from('ABCDEFGHIJKLMNOPQRSTUVWXYZ')
        //             var rutarray = Array.from(rutReceptor)
        //             var contador = 0
        //             letras.map((letra) => {
        //                 var count = rutarray.filter(x => x.toUpperCase() == letra.toUpperCase()).length
        //                 if (count >= 1) {
        //                     contador += count
        //                 }
        //             })
        //             if (contador > 1) { //mas de una letra
        //                 this.setState({ rutReceptor: "", showModalInformativo: true, textModalInformativo: "El rut ingresado no puede contener mas de una letra K" })
        //                 return;
        //             } else if (rutbeneficiario === rutReceptor) {
        //                 this.setState({ rutReceptor: "", showModalInformativo: true, textModalInformativo: "El rut del causante no puede ser igual a Tú rut" })
        //                 return
        //             } else if (isNaN(rutbeneficiario.charAt(0))) {
        //                 this.setState({ rutReceptor: "", showModalInformativo: true, textModalInformativo: "El rut no puede comenzar por una letra" })
        //                 return
        //             } else {
        //                 document.getElementById('submitButton').classList.remove("disabled")
        //                 this.setState({ disableSubmit: false })
        //             }
        //         }
        //     })
        //     .catch(() => {
        //         this.setState({ rutReceptor: "", showModalInformativo: true, textModalInformativo: "Estimado afiliado, en estos momentos tenemos problemas para validar su rut, favor vuelva a intentarlo." })
        //         return;
        //     });
    }

    HandlerTipoReceptor = (e) => {
        var tipoReceptor = e.target.value
        if (tipoReceptor === "0") {
            return
        }
        this.setState({ tipoReceptor: tipoReceptor })
    }

    HandlerNroSerieReceptor = (e) => {
        var serie = e.target.value
        if (/[^A-Za-z0-9]+/.test(serie)) {
            return false
        }
        this.setState({ nroSerieReceptor: serie, disableSubmit: true })
    }

    HandlerNroSerieReceptorConfirmacion = (e) => {
        var serieConfirm = e.target.value
        if (/[^A-Za-z0-9]+/.test(serieConfirm)) {
            return false
        }
        this.setState({ nroSerieReceptorConfirmacion: serieConfirm, disableSubmit: true })
    }

    HandlerNroSerieReceptorConfirmacionBlur = () => {
        var { nroSerieReceptor, rutReceptor, nroSerieReceptorConfirmacion } = this.state
        document.getElementById('submitButton').className += " disabled"

        if (!rutReceptor || rutReceptor === "") {
            this.setState({ nroSerieReceptor: "", showModalInformativo: true, textModalInformativo: "Estimado afiliado , favor ingrese rut de receptor para continuar" });
            return;
        }
        else if (nroSerieReceptor !== nroSerieReceptorConfirmacion) {
            this.setState({ nroSerieReceptorConfirmacion: "", showModalInformativo: true, textModalInformativo: "Estimado número de serie de confirmación no coincide con el original, favor verifique e intente nuevamente" });
            return;
        }
        document.getElementById('submitButton').classList.remove("disabled")
        this.setState({ disableSubmit: false })
    }


    ModalInformativoparentCallBackClose = () => {
        this.setState({ showModalInformativo: false })
    }

    HandlerNombreReceptor = (e) => {
        var nombreReceptor = e.target.value
        if (!/^[a-zA-Z()]+$/.test(nombreReceptor.replace(/\s/g, ''))) {
            this.setState({ nombreReceptor: "", showModalInformativo: true, textModalInformativo: "El nombre del receptor no puede contener números" })
            return;
        }
        this.setState({ nombreReceptor })
    }

    HandlerApellidoPaternoReceptor = (e) => {
        var apellidoPaternoReceptor = e.target.value
        if (!/^[a-zA-Z()]+$/.test(apellidoPaternoReceptor.replace(/\s/g, ''))) {
            this.setState({ apellidoPaternoReceptor: "", showModalInformativo: true, textModalInformativo: "El apellido del receptor no puede contener números" })
            return;
        }
        this.setState({ apellidoPaternoReceptor })
    }

    HandlerApellidoMaternoReceptor = (e) => {
        var apellidoMaternoReceptor = e.target.value
        if (!/^[a-zA-Z()]+$/.test(apellidoMaternoReceptor.replace(/\s/g, ''))) {
            this.setState({ apellidoMaternoReceptor: "", showModalInformativo: true, textModalInformativo: "El apellido del receptor no puede contener números" })
            return;
        }
        this.setState({ apellidoMaternoReceptor })
    }



    HandlerNroSerieReceptorBlur = async () => {
        var { nroSerieReceptor, rutReceptor } = this.state
        document.getElementById('submitButton').className += " disabled"
        // this.setState({ colorInfoNumeroSerieTutor: 'green', infoNroSerietutor: "validando informacón número de serie" })

        if (!rutReceptor || rutReceptor === "") {
            this.setState({ nroSerieReceptor: "", showModalInformativo: true, textModalInformativo: "favor ingrese el rut del Tutor legal para continuar" })
            return;
        }

        const letras = Array.from('ABCDEFGHIJKLMNOPQRSTUVWXYZ')
        var nroSeriearray = Array.from(nroSerieReceptor)
        var contador = 0
        letras.forEach((letra) => {
            var count = nroSeriearray.filter(x => x.toUpperCase() === letra.toUpperCase()).length
            if (count >= 1) {
                contador += 1
            }
        })

        if (contador > 1) { //mas de una letra
            this.setState({ nroSerieReceptor: "", showModalInformativo: true, textModalInformativo: "El número de serie no puede contener mas de una letra" })
        } else if (contador === 1) { //una letra
            if (!/^[a-zA-Z()]+$/.test(nroSerieReceptor.charAt(0))) { //validacion de letra al principio de la cadena
                this.setState({ nroSerieReceptor: "", showModalInformativo: true, textModalInformativo: "La letra debe ir al principio de su número de serie" })
                return
            } else if (nroSerieReceptor.length < 7 || nroSerieReceptor.length > 10) {
                this.setState({ nroSerieReceptor: "", showModalInformativo: true, textModalInformativo: "El largo del número de serie no puede ser menor a 7 ni mayor a 10 digitos" })
                return
            }
        } else if (nroSerieReceptor.length < 7 || nroSerieReceptor.length > 10) {
            this.setState({ nroSerieReceptor: "", showModalInformativo: true, textModalInformativo: "El largo del número de serie no puede ser menor a 7 ni mayor a 10 digitos" })
            return
        }

        await fetch(this.state.URL_NRO_DOCUMENTO_VALIDAR, {
            method: 'POST',
            body: JSON.stringify({ "rut": this.LimpiarFormatoRutValidSerie(rutReceptor), "serie": nroSerieReceptor }),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }).then(res => res.json())
            .then((response) => {
                if (response.status !== "OK") {
                    this.setState({ nroSerieReceptor: "", nroSerieReceptorConfirmacion: "", showModalInformativo: true, textModalInformativo: "En estos momentos tenemos problemas para validar la información ingresada, favor intentar nuevamente" })
                    return;
                } else if (response.verificacion !== "V") {
                    this.setState({ nroSerieReceptor: "", nroSerieReceptorConfirmacion: "", showModalInformativo: true, textModalInformativo: "Estimado beneficiario, El número de serie ingresado no es válido o no corresponde al rut de beneficiario, por favor verifique la información e intentelo nuevamente" })
                    return
                } else {
                    document.getElementById('submitButton').classList.remove("disabled")
                    this.setState({ disableSubmit: false, nroSerieReceptorConfirmacion: "" })
                }
            })
            .catch((error) => {
                this.setState({ nroSerieReceptor: "", showModalInformativo: true, textModalInformativo: "En estos momentos tenemos problemas para validar la información ingresada, favor intentar nuevamente" })
                return;
            });
    }


    HandlerRegionReceptor = (e) => {
        let regionReceptor = e.target.value

        if (regionReceptor === "0") {
            return;
        }

        let comunaRegion = region_comuna.region_comuna.regions
            .filter(region => region.number.toString() === e.target.value.toString())[0].communes

        this.setState({ regionReceptor, comunaRegionReceptor: comunaRegion })
        document.getElementById('comboComunaReceptor').selectedIndex = 0;


    }

    HandlerComunaReceptor = (e) => {
        let comunaReceptor = e.target.value

        if (comunaReceptor === "0") {
            return;
        }
        this.setState({ comunaReceptor })
    }


    HandlerCiudadReceptor = (e) => {
        let ciudadReceptor = e.target.value
        if (!isNaN(e.target.value)) {
            this.setState({ showModalInformativo: true, textModalInformativo: "La ciudad no puede contener valores numéricos" })
            return
        }
        this.setState({ ciudadReceptor })
    }


    HandlerDireccionReceptor = (e) => {
        let direccionReceptor = e.target.value
        this.setState({ direccionReceptor })
    }

    HandlercelularReceptor = (e) => {
        let celularReceptor = e.target.value
        if (isNaN(celularReceptor)) {
            this.setState({ celularReceptor: "", showModalInformativo: true, textModalInformativo: "El valor ingresado debe contener solo números" })
            return
        } else if (celularReceptor.charAt(0) !== "9") {
            this.setState({ celularReceptor: "", showModalInformativo: true, textModalInformativo: "El número de celular debe comenzar por 9 y tener 9 digitos Ej: 912345678" })
            return
        }
        this.setState({ celularReceptor, disableSubmit: true })
    }

    HandlercelularReceptorBlur = (e) => {
        console.log("hola")
    }


    HandlercelularReceptorConfirmacion = (e) => {

        var celularReceptorConfirmacion = e.target.value
        if (isNaN(celularReceptorConfirmacion)) {
            this.setState({ celularReceptorConfirmacion: "", showModalInformativo: true, textModalInformativo: "El valor ingresado debe contener solo números" })
            return
        } else if (celularReceptorConfirmacion.charAt(0) !== "9") {
            this.setState({ celularReceptorConfirmacion: "", showModalInformativo: true, textModalInformativo: "El número de celular debe comenzar con 9  y tener 9 digitos Ej: 912345678" })
            return
        }
        this.setState({ celularReceptorConfirmacion, disableSubmit: true })
    }


    HandlerCelularBeneficiario = (e) => {

        var celularBeneficiario = e.target.value
        if (isNaN(celularBeneficiario)) {
            this.setState({ celularBeneficiario: "", showModalInformativo: true, textModalInformativo: "El valor ingresado debe contener solo números" })
            return
        } else if (celularBeneficiario.charAt(0).toString() !== "9") {
            this.setState({ celularBeneficiario: "", showModalInformativo: true, textModalInformativo: "El número de celular debe comenzar con 9  y tener 9 digitos Ej: 912345678 " })
            return
        }
        this.setState({ celularBeneficiario, disableSubmit: true })
    }


    HandlerCelularBeneficiarioConfirmacion = (e) => {
        var celular = e.target.value
        this.setState({ celularBeneficiarioConfirmacion: celular, disableSubmit: false })
    }

    HandlerCelularBeneficiarioConfirmacionBlur = (e) => {
        const { celularBeneficiario, celularBeneficiarioConfirmacion } = this.state
        if (celularBeneficiario !== celularBeneficiarioConfirmacion) {
            this.setState({ celularBeneficiarioConfirmacion: "", showModalInformativo: true, textModalInformativo: "El número de celular no coincide con el que se ingreso previamente", disableSubmit: true })
            return
        }
        this.setState({ celularBeneficiarioConfirmacion: celularBeneficiarioConfirmacion, disableSubmit: false })
    }


    HandlerCelularBeneficiarioOpcional = (e) => {

        var celularBeneficiarioOpcional = e.target.value
        if (isNaN(celularBeneficiarioOpcional)) {
            this.setState({ celularBeneficiarioOpcional: "", showModalInformativo: true, textModalInformativo: "El valor ingresado debe contener solo números" })
            return
        } else if (celularBeneficiarioOpcional.charAt(0) !== "9") {
            this.setState({ celularBeneficiarioOpcional: "", showModalInformativo: true, textModalInformativo: "El número de celular debe comenzar con 9  y tener 9 digitos Ej: 912345678 " })
            return
        }
        this.setState({ celularBeneficiarioOpcional, disableSubmit: true })
    }

    HandlerCelularBeneficiarioOpcionalBlur = (e) => {
        let { celularBeneficiarioOpcional } = this.state
        if (celularBeneficiarioOpcional.length < 9) {
            this.setState({ celularBeneficiarioOpcional: "", showModalInformativo: true, textModalInformativo: "El número de celular debe comenzar con 9 y tener 9 digitos Ej: 912345678 " })
            return
        }
        this.setState({ celularBeneficiarioOpcional, disableSubmit: false })
    }

    HandlerCelularBeneficiarioBlur = (e) => {
        let { celularBeneficiario } = this.state
        if (celularBeneficiario.length < 9) {
            this.setState({ celularBeneficiario: "", showModalInformativo: true, textModalInformativo: "El número de celular debe comenzar con 9 y tener 9 digitos Ej: 912345678 " })
            return
        }
        this.setState({ celularBeneficiario, disableSubmit: false })
    }

    HandlercelularReceptorConfirmacionBlur = (e) => {

        const { celularReceptor, celularReceptorConfirmacion } = this.state
        if (celularReceptor !== celularReceptorConfirmacion) {
            this.setState({ celularReceptorConfirmacion: "", showModalInformativo: true, textModalInformativo: "El número de celular no coincide con el que se ingreso previamente", disableSubmit: true })
            return
        }
        this.setState({ celularReceptorConfirmacion: celularReceptor, disableSubmit: false })

    }




    HandlercelularReceptorConfirmacionOpcional = (e) => {

        var celularReceptorConfirmacionOpcional = e.target.value
        if (isNaN(celularReceptorConfirmacionOpcional)) {
            this.setState({ celularReceptorConfirmacionOpcional: "", showModalInformativo: true, textModalInformativo: "El valor ingresado debe contener solo números" })
            return
        } else if (celularReceptorConfirmacionOpcional.charAt(0) !== "9") {
            this.setState({ celularReceptorConfirmacionOpcional: "", showModalInformativo: true, textModalInformativo: "El número de celular debe comenzar con 9  y tener 9 digitos Ej: 912345678" })
            return
        }
        this.setState({ celularReceptorConfirmacionOpcional, disableSubmit: true })
    }


    HandlerEmailReceptor = (e) => {
        var email = e.target.value
        this.setState({ correoReceptor: email, correoReceptorConfirmacion: "", disableSubmit: true })

    }

    ValidarMail = (email) => {
        // eslint-disable-next-line
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    }

    HandlerEmailReceptorBlur = (e) => {
        var email = this.state.correoReceptor
        if (!this.ValidarMail(email)) {
            this.setState({ correoReceptor: "", correoReceptorConfirmacion: "", showModalInformativo: true, textModalInformativo: "El correo ingresado no tiene el formato correcto xxx@xxx.xx" })
            return
        } else {
            this.setState({ disableSubmit: false })
        }

    }


    HandlerEmailReceptorConfirmacion = (e) => {
        var correoReceptorConfirmacion = e.target.value
        this.setState({ correoReceptorConfirmacion: correoReceptorConfirmacion, disableSubmit: true })

    }

    HandlerEmailReceptorConfirmacionBlur = (e) => {
        var { correoReceptor, correoReceptorConfirmacion } = this.state
        if (correoReceptor !== correoReceptorConfirmacion) {
            this.setState({ correoReceptorConfirmacion: "", showModalInformativo: true, textModalInformativo: "Ambos correso ingresados deben ser iguales" })
            return
        } else {
            this.setState({ disableSubmit: false })
        }
    }

    HandlercelularReceptorConfirmacionOpcionalBlur = (e) => {
        var celularReceptorConfirmacionOpcional = e.target.value
        if (celularReceptorConfirmacionOpcional.length < 9) {
            this.setState({ celularReceptorConfirmacionOpcional: "", showModalInformativo: true, textModalInformativo: "El número de celular debe contener 9 digitos" })
            return
        }
        this.setState({ celularReceptorConfirmacionOpcional, disableSubmit: false })
    }


    // HandlerCertificadoET = (e) => {
    //     // let certificadoET = e.target.value
    //     // if (certificadoET === "0") {
    //     //     return
    //     // } else if (certificadoET === "S") {
    //     //     this.setState({ certificadoET, displayUploadCertificadoET: 'block' })
    //     //     return;
    //     // }
    //     // this.setState({ certificadoET, displayUploadCertificadoET: 'none' })

    // }


    HandlerDictamen = (e) => {
        let dictamen = e.target.value
        if (dictamen === "0") {
            return
        } else if (dictamen === "S") {
            this.setState({ dictamen, displayUploadDictamen: 'block' })
            return;
        }
        this.setState({ dictamen, displayUploadDictamen: 'none' })

    }


    HandlerMandato = (e) => {
        let mandato = e.target.value
        if (mandato === "0") {
            return
        } else if (mandato === "S") {
            this.setState({ mandato, displayUploadMandato: 'block' })
            return;
        }
        this.setState({ mandato, displayUploadMandato: 'none' })

    }

    ValidarInformacionBancarioVacia = () => {
        var { medioPago, nroCuenta, nroCuentaConfirm, tipoCuenta, bancoSelected } = this.state

        if (medioPago === "0" || medioPago === "" || bancoSelected === "" || nroCuenta === "" || nroCuentaConfirm === "" || tipoCuenta === "") {
            this.setState({ showModalInformativo: true, textModalInformativo: "Favor verifique que la información bancaria no se encuentre vacia antes de continuar con la solicitud" })
            return false
        }
        return true
    }

    ValidarInformacionLugarReposoVacia = () => {
        var { lugarReposo, regionLugarReposo, comunaLugarReposo, calleLugarReposo, numeroLugarReposo } = this.state
        console.log("lugarReposo ", lugarReposo)
        if (lugarReposo === "OTR" || lugarReposo === "HOSP") {
            if (regionLugarReposo === "" || regionLugarReposo === "0" ||
                comunaLugarReposo === "" || comunaLugarReposo === "0" ||
                calleLugarReposo === "" || lugarReposo === "0" ||
                numeroLugarReposo === "") {
                this.setState({ showModalInformativo: true, textModalInformativo: "Favor verifique que la información del lugar de reposo del beneficiario no se encuentre vacia antes de continuar con la solicitud" })
                return false
            }
        }
        return true
    }

    ValidarInformacionReceptorVacia = () => {
        var { rutReceptor, tipoReceptor, nroSerieReceptor, nroSerieReceptorConfirmacion, nombreReceptor, apellidoPaternoReceptor, apellidoMaternoReceptor,
            regionReceptor, comunaReceptor, ciudadReceptor, direccionReceptor, celularReceptor, celularReceptorConfirmacion, correoReceptor, correoReceptorConfirmacion, tipoIngresoSolicitud } = this.state

        if (tipoIngresoSolicitud === "BE") {
            return true
        }

        if (celularReceptor === "" || rutReceptor === "" || tipoReceptor === "0" ||
            tipoReceptor === "" || nroSerieReceptor === "" ||
            nroSerieReceptorConfirmacion === "" || nombreReceptor === "" || apellidoPaternoReceptor === "" ||
            apellidoMaternoReceptor === "" || regionReceptor === "" || regionReceptor === "0" || comunaReceptor === "" || comunaReceptor === "0"
            || ciudadReceptor === "" || direccionReceptor === "" || celularReceptorConfirmacion === "" || correoReceptor === "" || correoReceptorConfirmacion === "") {

            this.setState({ showModalInformativo: true, textModalInformativo: "Favor verifica que los datos de contacto y ubicación del receptor sean correctos y no se encuentren vacios para continuar." })
            return false
        }

        return true
    }

    ValidarInformacionContactoBeneficiarioVacia = () => {
        var { regionBeneficiario, comunaBeneficiario, ciudadBeneficiario, direccionBeneficiario, celularBeneficiario, celularBeneficiarioConfirmacion, correoBeneficiario, correoBeneficiarioConfirmacion, tipoVigenciaBeneficiario } = this.state

        if (regionBeneficiario === "" || regionBeneficiario === "0" ||
            comunaBeneficiario === "" || comunaBeneficiario === "0" ||
            ciudadBeneficiario === "" || direccionBeneficiario === "" ||
            celularBeneficiario === "" || celularBeneficiarioConfirmacion === "" ||
            correoBeneficiario === "" || correoBeneficiarioConfirmacion === "" || tipoVigenciaBeneficiario === "" || tipoVigenciaBeneficiario === "0") {
            this.setState({ showModalInformativo: true, textModalInformativo: "Favor verifica que los datos de contacto y ubicación del beneficiario sean correctos y no se encuentren vacios para continuar." })
            return false
        }

        return true
    }


    HandlerRecaptcha = async () => {

        let token = await this.recaptcha.getResponse();
        var { direccionBeneficiario,
            ciudadBeneficiario,
            regionBeneficiario,
            telefonoBeneficiario,
            celularBeneficiario,
            celularBeneficiarioOpcional,
            comunaBeneficiario,
            rutBeneficiario,
            tipoReceptor,
            rutReceptor,
            nombreReceptor,
            apellidoPaternoReceptor,
            apellidoMaternoReceptor,
            nroSerieReceptor,
            regionReceptor,
            comunaReceptor,
            ciudadReceptor,
            direccionReceptor,
            celularReceptor,
            celularReceptorConfirmacion,
            celularReceptorConfirmacionOpcional,
            correoReceptor,
            correoBeneficiario,
            bancoSelected,
            mandato,
            dictamen,
            certificadoET,
            autorizaSubscripcionCertificados,
            nroCuenta,
            tipoCuenta,
            rutCausante,
            nroSerieBeneficiario,
            parentescoBeneficiario,
            apellidoPaternoBeneficiario,
            apellidoMaternoBeneficiario,
            nombreBeneficiario,
            generoBeneficiario,
            fechaNacimientoBeneficiario,
            tipoVigenciaBeneficiario,
            medioPago,
            tipoIngresoSolicitud,
            idEjecutivo,
            ipIngresoSolicitud,
            lugarReposo,
            regionLugarReposo,
            comunaLugarReposo,
            calleLugarReposo,
            numeroLugarReposo,
            ciudadLugarReposo
        } = this.state

        let { dataBeneficiario } = this.props
        console.log("TIPO INGRESO SOLICITUD ", tipoIngresoSolicitud)
        var data = {
            "ID_CLIENTE": dataBeneficiario.ID_CLIENTE,
            "ID_PERSONA": this.LimpiarRut(rutCausante),
            "ID_BENEFICIARIO": this.LimpiarRut(rutBeneficiario),
            "NUM_DOCUMENTO_BENEF": nroSerieBeneficiario,
            "FOLIO_SOLICITUD": this.LimpiarRut(rutBeneficiario) + 1033 + moment().tz("America/Santiago").format('MMYY'),
            "FEC_SOLICITUD_TRAMITE": moment().tz("America/Santiago").format('DD/MM/YYYY'),
            "ID_USUARIO_ING_REG": idEjecutivo,
            "COD_AGENCIA_SOLICITUD": "",
            "SEQ_BENEFICIARIO": dataBeneficiario.SEQ_BENEFICIARIO,
            "COD_PARENTESCO": dataBeneficiario.COD_PARENTESCO,
            "DESCRIPCION_PARENTESCO": parentescoBeneficiario,
            "AP_PATERNO": apellidoPaternoBeneficiario,
            "AP_MATERNO": apellidoMaternoBeneficiario,
            "NOMBRE": nombreBeneficiario,
            "SEXO": generoBeneficiario,
            "NUM_GRUPO_FAMILIAR": dataBeneficiario.NUM_GRUPO_FAMILIAR,
            "FEC_NACIMIENTO": fechaNacimientoBeneficiario,
            "DIRECCION": direccionBeneficiario,
            "COD_COMUNA": comunaBeneficiario,
            "DESCRIPCION_COMUNA": region_comuna.region_comuna.regions.filter(region => region.number === regionBeneficiario)[0].communes.find(comuna => comuna.codigo === comunaBeneficiario).name,
            "CIUDAD": ciudadBeneficiario,
            "COD_REGION": regionBeneficiario,
            "TELEFONO": telefonoBeneficiario,
            "CELULAR_PRINCIPAL": celularBeneficiario,
            "CELULAR_OPCIONAL": celularBeneficiarioOpcional,
            "EMAIL": correoBeneficiario,
            "TIPO_VIGENCIA": tipoVigenciaBeneficiario,
            "ID_RECEPTOR": tipoIngresoSolicitud === "BE" ? this.LimpiarRut(rutBeneficiario) : this.LimpiarRut(rutReceptor),
            "NUM_DOCUMENTO_RECEP": tipoIngresoSolicitud === "BE" ? nroSerieBeneficiario : nroSerieReceptor,
            "TIPO_RECEPTOR": tipoIngresoSolicitud === "BE" ? "" : tipoReceptor,
            "AP_PATERNO_RECEP": tipoIngresoSolicitud === "BE" ? apellidoPaternoBeneficiario : apellidoPaternoReceptor,
            "AP_MATERNO_RECEP": tipoIngresoSolicitud === "BE" ? apellidoMaternoBeneficiario : apellidoMaternoReceptor,
            "NOMBRE_RECEP": tipoIngresoSolicitud === "BE" ? nombreBeneficiario : nombreReceptor,
            "DIRECCION_RECEP": tipoIngresoSolicitud === "BE" ? "" : direccionReceptor,
            "COD_COMUNA_RECEP": tipoIngresoSolicitud === "BE" ? "" : region_comuna.region_comuna.regions.filter(region => parseInt(region.number) === parseInt(regionReceptor))[0].communes.find(comuna => comuna.name === comunaReceptor).codigo,
            "DESCRIPCION_COMUNA_RECEP": tipoIngresoSolicitud === "BE" ? "" : comunaReceptor,
            "CIUDAD_RECEP": tipoIngresoSolicitud === "BE" ? "" : ciudadReceptor,
            "COD_REGION_RECEP": tipoIngresoSolicitud === "BE" ? "" : regionReceptor,
            "TELEFONO_RECEP": tipoIngresoSolicitud === "BE" ? "" : celularReceptor,
            "CELULAR_RECEP": tipoIngresoSolicitud === "BE" ? "" : celularReceptorConfirmacion,
            "CELULAR_OPCIONAL_RECEP": tipoIngresoSolicitud === "BE" ? "" : celularReceptorConfirmacionOpcional,
            "EMAIL_RECEP": tipoIngresoSolicitud === "BE" ? "" : correoReceptor,
            "TIPO_VIA_PAGO": medioPago,
            "TIPO_CUENTA_DEPOSITO": this.state.codigosCuenta.find(cuenta => cuenta.combo === tipoCuenta).tipo,
            "COD_INST_FINANC_DEPOSITO": this.state.codigosBanco.find(banco => banco.banco === bancoSelected).codigo,
            "NUM_CUENTA_DEPOSITO": nroCuenta,
            "COD_INSTITUCION_SALUD": dataBeneficiario.COD_INSTITUCION_SALUD,
            "DESCRIP_INSTITUCION_SALUD": dataBeneficiario.DESCRIP_INSTITUCION_SALUD ? dataBeneficiario.DESCRIP_INSTITUCION_SALUD : "FONASA",
            "IND_ENTREGA_CERT_ET": certificadoET,
            "IND_TIENE_DICTAMEN": dictamen,
            "IND_ADJUNTA_MANDATO": mandato,
            "IND_AUTORIZA_SUS_CALIFICACION": autorizaSubscripcionCertificados,
            "IND_AUTORIZA_SUS_APS": autorizaSubscripcionCertificados,
            "TIPO_AFILIADO": " Pensionado de sobrevivencia",
            "TOKEN_CAPTCHA": token,
            "TIPO_INGRESO_SOLICITUD": tipoIngresoSolicitud,
            "IP_INGRESO_SOLICITUD": ipIngresoSolicitud,
            "MEDIOEMISION": "ONLINE",
            "LUGAR_REPOSO_SOLICITANTE": lugarReposo,
            "REPOSO_CALLE": calleLugarReposo,
            "REPOSO_NUMERO": numeroLugarReposo,
            "REPOSO_COMUNA": comunaLugarReposo,
            "REPOSO_REGION": regionLugarReposo,
            "REPOSO_CIUDAD": ciudadLugarReposo,
            "CUBERTURA": "NO",
            "HORA_SOLICITUD": moment().tz("America/Santiago").format('HH:mm:ss')
        }

        console.log("DATA SOLICITUD ", data)
        await fetch(this.state.URL_INGRESO_SOLICITUD, {
            method: 'POST',
            body: JSON.stringify(data),
            headers: {
                'Content-Type': 'application/json'
            }
        }).then(res => res.json())
            .then((response) => {
                console.log("RESPONSE BACK ", response)
                if (response.status === 200) {
                    this.setState({
                        showModalInformativo: true,
                        textModalInformativo: ReactHtmlParser(`
                    Estimado beneficario a continuación se resumen los datos de su solicitud:  <br>
                    <br>
                    <ul>
                    <li>Número de solicitud : ${data.FOLIO_SOLICITUD}</li>
                    <li>Fecha de solicitud : ${data.FEC_SOLICITUD_TRAMITE}</li>
                    <li>Beneficiario :   ${data.ID_BENEFICIARIO}</li>
                    <li>Causante: ${data.ID_PERSONA}</li>
                    </ul>
                    <br>
                    La cual ha sido recibida exitosamente  y que luego de revisar los antecedentes serán enviados al consejo médico, quien certifica la calidad de enfermedad terminal y por tanto aprueba o rechaza continuidad del trámite. Si es aprobado, el primer pago se realizará al 4°día hábil siguiente de notificada la aprobación por parte del Consejo Médico
                    <br>
                    <br>
                    ...En breve sera redirigido al sitio principal.
                    `),
                    })

                    setTimeout(() => {
                        window.location = this.state.URL_REDIRECCION
                    }, 30000);
                    return;
                } else if (response.status === 404) {
                    this.setState({ showModalInformativo: true, textModalInformativo: "Estimado beneficiario, no es posible continuar con su proceso, ya que, actualmente cuenta con una solicitud activa en el sistema.", disableSubmit: false })
                    return;
                } else {
                    document.getElementById('submitButton').classList.remove("disabled")
                    this.setState({ showModalInformativo: true, textModalInformativo: "Estimado beneficiario, en este momento tenemos problemas para procesar su solicitud. Favor intentelo nuevamente en unos minutos", disableSubmit: false })
                    return
                }
            }
            )
            .catch(() => {
                document.getElementById('submitButton').classList.remove("disabled")
                this.setState({ showModalInformativo: true, textModalInformativo: "Estimado beneficiario, en este momento tenemos problemas para procesar su solicitud. Favor intentelo nuevamente en unos minutos" })
            })
    }


    HandlerSubmit = async () => {

        if (!this.ValidarInformacionContactoBeneficiarioVacia()) {
            return
        }

        if (!this.ValidarInformacionReceptorVacia()) {
            return;
        }

        if (!this.ValidarInformacionBancarioVacia()) {
            return;
        }

        if (!this.ValidarInformacionLugarReposoVacia()) {
            return;
        }

        //documentacion adicional
        if (this.state.documentacionAdicionalSeleccionado === true && this.state.DctoAdicionalCargado === false) {
            this.setState({ showModalInformativo: true, textModalInformativo: "Debe seleccionar los documentos que desea agregar antes de continuar con la solicitud." })
            return;
        }
        //documentacion adicional

        if (this.state.certificadoET === "S" && this.state.selectedFileCertificadoET === null) {
            this.setState({ showModalInformativo: true, textModalInformativo: "Debe subir el archivo correspondiente al Certificado de Enfermedad Terminal otorgado por la Unidad de Cuidados Paliativos" })
            return;
        } else if (this.state.certificadoET === "S" && this.state.certificadoETCargado === false) {
            this.setState({ showModalInformativo: true, textModalInformativo: "Verifique que el documento correspondiente al Certificado de enfermedad terminal se haya cargado correctamente" })
            return;
        }
        if (this.state.AutorizacionFirmadaCargada === false) {
            this.setState({ showModalInformativo: true, textModalInformativo: "Debe subir el archivo correspondiente a la autorización firmada que permita al Consejo Médico acceso a sus antecedentes " })
            return;
        }


        if ((this.state.tipoVigenciaBeneficiario !== "0" && this.state.tipoVigenciaBeneficiario !== "N" && this.state.dictamen === "0") ||
            (this.state.tipoVigenciaBeneficiario !== "0" && this.state.tipoVigenciaBeneficiario !== "N" && this.state.dictamen === "")) {
            this.setState({ showModalInformativo: true, textModalInformativo: "Debe seleciconar una opción referente al dictamen de comisión medica" })
            return;
        } else if (this.state.tipoVigenciaBeneficiario !== "0" && this.state.tipoVigenciaBeneficiario !== "N" && this.state.dictamen === "S" && this.state.selectedFileDictamen === null) {
            this.setState({ showModalInformativo: true, textModalInformativo: "Debe subir el archivo correspondiente al Dictamen de la Comisión Médica para certificar su grado de invalidez" })
            return;
        } else if (this.state.dictamen === "S" && this.state.DictamenCargado === false) {
            this.setState({ showModalInformativo: true, textModalInformativo: "Verifique que el documento correspondiente al Dictamen se haya cargado correctamente" })
            return;
        }

        if (this.state.tipoIngresoSolicitud !== "BE") {

            if (this.state.mandato === "0" || this.state.mandato === "") {
                this.setState({ showModalInformativo: true, textModalInformativo: "Debe seleciconar una opción referente al mandato" })
                return;
            } else if (this.state.mandato === "S" && this.state.selectedFileMandato === null) {
                this.setState({ showModalInformativo: true, textModalInformativo: "Debe subir el archivo correspondiente al Mandato" })
                return
            } else if (this.state.mandato === "S" && this.state.MandatoCargado === false) {
                this.setState({ showModalInformativo: true, textModalInformativo: "Verifique que el documento correspondiente al Mandato se haya cargado correctamente" })
                return;
            }
        }


        if (this.state.autorizaSubscripcionCertificados === false) {
            this.setState({ showModalInformativo: true, textModalInformativo: "Para continuar con la solicitud, debe autorizar la subscripción de sus tramites por parte de la Administradora" })
            return;
        }

        if (this.state.tipoIngresoSolicitud === "MA" && this.state.MandatoCargado === false) {
            this.setState({ showModalInformativo: true, textModalInformativo: "Para continuar con la solicitud, debe cargar el archivo correspondiente al mandato." })
            return;
        }


        document.getElementById('submitButton').className += " disabled"
        this.setState({ disableSubmit: true })
        this.recaptcha.execute();
    }


    UploadFileChangeCertificadoET = (e) => {
        this.setState({ displayCertificadoET: 'block', colorCertificadoET: 'blue', selectedFileCertificadoET: e.target.files[0] });
    }


    UploadFileClickCertificadoET = (e) => {

        if (!this.state.selectedFileCertificadoET) {
            this.setState({ showModalInformativo: true, textModalInformativo: "Debe seleccionar el archivo que desea cargar al sistema, antes de continuar con la solicitud" })
            return
        }

        if (this.state.selectedFileCertificadoET.type !== "application/pdf") {
            this.setState({ showModalInformativo: true, textModalInformativo: "El documento debe tener formato .pdf" })
            return
        }
        this.setState({ blocking: true, mensajeBloqueo: "Espere mientras se carga su documento." })
        var docData = new FormData();

        docData.append(
            "documento",
            this.state.selectedFileCertificadoET,
            'CERT. ENFERMO TERMINAL APORTADO EN LA SOLICITUD'
        );
        docData.append('rutBeneficiario', this.LimpiarRut(this.state.rutBeneficiario))
        docData.append('rutCausante', this.state.rutCausante)
        docData.append('codigoDocumento', 75)

        fetch(this.state.URL_CARGA_DOC_GCP, {
            method: 'POST',
            body: docData,
        }).then(res => {
            if (res.status === 200) {
                this.setState({ certificadoETCargado: true, blocking: false, showModalInformativo: true, textModalInformativo: "Documento cargado con exito." })
                // return
                //respaldo en la nube almacenado, ahora procedemos a cargar en CM
                // fetch(this.state.URL_CARGA_DOC_CM, {
                //     method: 'POST',
                //     body: docData
                // }).then(res => {
                //     console.log("RESPONSE CM  , res")
                //     if (res.status === 200) {
                //         this.setState({ certificadoETCargado: true, blocking: false, showModalInformativo: true, textModalInformativo: "Documento cargado con exito." })
                //         return
                //     } else {
                //         this.setState({ certificadoETCargado: false, blocking: false, showModalInformativo: true, textModalInformativo: "Ocurrio un error al carga su documento, favor intentelo nuevamente" })
                //         return
                //     }

                // })
                //     .catch(errorCM => {
                //         console.log("CATCH CM ", errorCM)
                //         this.setState({ certificadoETCargado: false, blocking: false, showModalInformativo: true, textModalInformativo: "Ocurrio un error al carga su documento, favor intentelo nuevamente" })
                //         return
                //     })
            } else {
                this.setState({ certificadoETCargado: false, blocking: false, showModalInformativo: true, textModalInformativo: "Ocurrio un error al carga su documento, favor intentelo nuevamente" })
                return
            }
        })
            .catch((error) => {
                this.setState({ certificadoETCargado: false, blocking: false, showModalInformativo: true, textModalInformativo: "Ocurrio un error al carga su documento, favor intentelo nuevamente" })
                return
            });
    }


    UploadFileChangeDictamen = (e) => {
        this.setState({ displayCargaDictamen: 'block', colorDictamen: 'blue', selectedFileDictamen: e.target.files[0] });
    }

    UploadFileClickDictamen = (e) => {

        if (!this.state.selectedFileDictamen) {
            this.setState({ showModalInformativo: true, textModalInformativo: "Debe seleccionar el archivo que desea cargar al sistema, antes de continuar con la solicitud" })
            return
        }
        if (this.state.selectedFileDictamen.type !== "application/pdf") {
            console.log("el archivo que intenta subir no es un pdf")
            return
        }
        this.setState({ blocking: true, mensajeBloqueo: "Espere mientras se carga su documento." })
        var docData = new FormData();

        docData.append(
            "documento",
            this.state.selectedFileDictamen,
            'DICTAMEN DE INVALIDEZ BENEFICIARIO'
        );

        docData.append('rutBeneficiario', this.LimpiarRut(this.state.rutBeneficiario))
        docData.append('rutCausante', this.state.rutCausante)
        docData.append('codigoDocumento', 130)


        fetch(this.state.URL_CARGA_DOC_GCP, {
            method: 'POST',
            body: docData
        }).then(res => {
            if (res.status === 200) {

                this.setState({ DictamenCargado: true, blocking: false, showModalInformativo: true, textModalInformativo: "Documento cargado con exito." })

                // fetch(this.state.URL_CARGA_DOC_CM, {
                //     method: 'POST',
                //     body: docData
                // }).then(res => {
                //     console.log("RESPONSE CM  , res")
                //     if (res.status === 200) {
                //         this.setState({ DictamenCargado: true, blocking: false, showModalInformativo: true, textModalInformativo: "Documento cargado con exito." })
                //         return
                //     } else {
                //         this.setState({ DictamenCargado: false, blocking: false, showModalInformativo: true, textModalInformativo: "Ocurrio un error al carga su documento, favor intentelo nuevamente" })
                //         return
                //     }

                // })
                //     .catch(errorCM => {
                //         console.log("CATCH CM ", errorCM)
                //         this.setState({ DictamenCargado: false, blocking: false, showModalInformativo: true, textModalInformativo: "Ocurrio un error al carga su documento, favor intentelo nuevamente" })
                //         return
                //     })

            } else {
                this.setState({ DictamenCargado: false, blocking: false, showModalInformativo: true, textModalInformativo: "Ocurrio un error al carga su documento, favor intentelo nuevamente" })
                return
            }
        })
            .catch((error) => {
                this.setState({ DictamenCargado: false, blocking: false, showModalInformativo: true, textModalInformativo: "Ocurrio un error al carga su documento, favor intentelo nuevamente" })
                return
            });
    }


    UploadFileChangeMandato = (e) => {
        this.setState({ displayCargaMandato: 'block', colorMandato: 'blue', selectedFileMandato: e.target.files[0] });
    }

    UploadFileClickMandato = (e) => {
        if (!this.state.selectedFileMandato) {
            this.setState({ showModalInformativo: true, textModalInformativo: "Debe seleccionar el archivo que desea cargar al sistema, antes de continuar con la solicitud" })
            return
        }

        if (this.state.selectedFileMandato.type !== "application/pdf") {
            console.log("el archivo que intenta subir no es un pdf")
            return
        }
        this.setState({ blocking: true, mensajeBloqueo: "Espere mientras se carga su documento." })
        var docData = new FormData();

        docData.append(
            "documento",
            this.state.selectedFileMandato,
            'MANDATO SUSCRIBIR SOLICITUD ENFERMO TERMINAL'
        );

        docData.append('rutBeneficiario', this.LimpiarRut(this.state.rutBeneficiario))
        docData.append('rutCausante', this.state.rutCausante)
        docData.append('codigoDocumento', 76)


        fetch(this.state.URL_CARGA_DOC_GCP, {
            method: 'POST',
            body: docData
        }).then(res => {
            if (res.status === 200) {
                this.setState({ MandatoCargado: true, blocking: false, showModalInformativo: true, textModalInformativo: "Documento cargado con exito." })
                // fetch(this.state.URL_CARGA_DOC_CM, {
                //     method: 'POST',
                //     body: docData
                // }).then(res => {
                //     console.log("RESPONSE CM  , res")
                //     if (res.status === 200) {
                //         this.setState({ MandatoCargado: true, blocking: false, showModalInformativo: true, textModalInformativo: "Documento cargado con exito." })
                //         return
                //     } else {
                //         this.setState({ MandatoCargado: false, blocking: false, showModalInformativo: true, textModalInformativo: "Ocurrio un error al carga su documento, favor intentelo nuevamente" })
                //         return
                //     }

                // })
                //     .catch(errorCM => {
                //         console.log("CATCH CM ", errorCM)
                //         this.setState({ MandatoCargado: false, blocking: false, showModalInformativo: true, textModalInformativo: "Ocurrio un error al carga su documento, favor intentelo nuevamente" })
                //         return
                //     })

            } else {
                this.setState({ MandatoCargado: false, blocking: false, showModalInformativo: true, textModalInformativo: "Ocurrio un error al carga su documento, favor intentelo nuevamente" })
                return
            }
        })
            .catch((error) => {
                this.setState({ MandatoCargado: false, blocking: false, showModalInformativo: true, textModalInformativo: "Ocurrio un error al carga su documento, favor intentelo nuevamente" })
                return
            });
    }




    UploadFileChangeAutorizacionFirmada = (e) => {
        this.setState({ displayUploadAutorizacionFirmada: 'block', colorAutorizacionFirmada: 'blue', selectedFileAutorizacionFirmada: e.target.files[0] });
    }

    UploadFileClickAutorizacionFirmada = (e) => {


        if (!this.state.selectedFileAutorizacionFirmada) {
            this.setState({ showModalInformativo: true, textModalInformativo: "Debe seleccionar el archivo que desea cargar al sistema, antes de continuar con la solicitud" })
            return
        }

        if (this.state.selectedFileAutorizacionFirmada.type !== "application/pdf") {
            console.log("el archivo que intenta subir no es un pdf")
            return
        }
        this.setState({ blocking: true, mensajeBloqueo: "Espere mientras se carga su documento." })
        var docData = new FormData();

        docData.append(
            "documento",
            this.state.selectedFileAutorizacionFirmada,
            'Autorización a Consejo Medico – Anexo 3'
        );

        docData.append('rutBeneficiario', this.LimpiarRut(this.state.rutBeneficiario))
        docData.append('rutCausante', this.state.rutCausante)
        docData.append('codigoDocumento', 91)


        fetch(this.state.URL_CARGA_DOC_GCP, {
            method: 'POST',
            body: docData
        }).then(res => {
            if (res.status === 200) {
                this.setState({ AutorizacionFirmadaCargada: true, blocking: false, showModalInformativo: true, textModalInformativo: "Documento cargado con exito." })
                // fetch(this.state.URL_CARGA_DOC_CM, {
                //     method: 'POST',
                //     body: docData
                // }).then(res => {
                //     console.log("RESPONSE CM  , res")
                //     if (res.status === 200) {
                //         this.setState({ AutorizacionFirmadaCargada: true, blocking: false, showModalInformativo: true, textModalInformativo: "Documento cargado con exito." })
                //         return
                //     } else {
                //         this.setState({ AutorizacionFirmadaCargada: false, blocking: false, showModalInformativo: true, textModalInformativo: "Ocurrio un error al carga su documento, favor intentelo nuevamente" })
                //         return
                //     }

                // })
                //     .catch(errorCM => {
                //         console.log("CATCH CM ", errorCM)
                //         this.setState({ AutorizacionFirmadaCargada: false, blocking: false, showModalInformativo: true, textModalInformativo: "Ocurrio un error al carga su documento, favor intentelo nuevamente" })
                //         return
                //     })

            } else {
                this.setState({ AutorizacionFirmadaCargada: false, blocking: false, showModalInformativo: true, textModalInformativo: "Ocurrio un error al carga su documento, favor intentelo nuevamente" })
                return
            }
        })
            .catch((error) => {
                this.setState({ AutorizacionFirmadaCargada: false, blocking: false, showModalInformativo: true, textModalInformativo: "Ocurrio un error al carga su documento, favor intentelo nuevamente" })
                return
            });
    }





    UploadAditonalDocChange = (e) => {
        console.log("FILES SELECTED ", e.target.files)
        this.setState({ displayCargaDocumentoAdicional: 'block', selectedFilesDocumentoAdicionl: e.target.files, selectFilesDocumentoAdicionalInfo: Array.from(e.target.files) });

    }

    UploadAditonalDocClick = (e) => {
        console.log("JDJSAJDS ", this.state.selectedFilesDocumentoAdicionl)

        if (!this.state.selectedFilesDocumentoAdicionl) {
            this.setState({ DctoAdicionalCargado: false, blocking: false, showModalInformativo: true, textModalInformativo: "Debe seleccionar los archivos que desea cargar al sistema, antes de continuar con la solicitud" })
            return
        }
        let arregloDocumentos = Array.from(this.state.selectedFilesDocumentoAdicionl)
        this.setState({ blocking: true, mensajeBloqueo: "Espere mientras se carga su documento." })
        var promiseArray = []

        var cargaDocumentos = true
        arregloDocumentos.forEach(file => {
            if (file.type === "application/pdf" || file.type === "image/jpeg" || file.type === "image/jpg") {
                console.log("formato correcto")
            } else {
                cargaDocumentos = false
                this.setState({ blocking: false, mensajeBloqueo: "", DctoAdicionalCargado: false, showModalInformativo: true, textModalInformativo: "Uno o mas documentos no tienen el formato correspondiente (pdf.jpeg,jpg)" })
            }
        })

        if (!cargaDocumentos) {
            return
        }

        arregloDocumentos.forEach((file, index) => {
            console.log("FILE ", file)
            var docData = new FormData();
            docData.append(
                "documento",
                file,
                `Dctos Complementarios Tramite Enfermo Terminal-${index}`
            );
            docData.append('rutBeneficiario', this.LimpiarRut(this.state.rutBeneficiario))
            docData.append('rutCausante', this.state.rutCausante)
            docData.append('codigoDocumento', 83)


            var promise = new Promise((resolve, reject) => {
                fetch(this.state.URL_CARGA_DOC_GCP, {
                    method: 'POST',
                    body: docData
                }).then(res => {
                    if (res.status === 200)
                        this.setState({ DctoAdicionalCargado: true, blocking: false, showModalInformativo: true, textModalInformativo: "Documentos cargado con exito." })
                    // fetch(this.state.URL_CARGA_DOC_CM, {
                    //     method: 'POST',
                    //     body: docData
                    // }).then(res => {
                    //     console.log("RESPONSE CM  , res")
                    //     if (res.status === 200) {
                    //         this.setState({ DctoAdicionalCargado: true, blocking: false, showModalInformativo: true, textModalInformativo: "Documentos cargado con exito." })
                    //         return
                    //     } else {
                    //         this.setState({ DctoAdicionalCargado: false, blocking: false, showModalInformativo: true, textModalInformativo: "Ocurrio un error al carga sus documentos, favor intentelo nuevamente" })
                    //         return
                    //     }

                    // })
                    //     .catch(errorCM => {
                    //         console.log("CATCH CM ", errorCM)
                    //         this.setState({ DctoAdicionalCargado: false, blocking: false, showModalInformativo: true, textModalInformativo: "Ocurrio un error al carga sus documentos, favor intentelo nuevamente" })
                    //         return
                    //     })
                    else
                        reject()
                })
                    .catch(res => reject())

            })
            promiseArray.push(promise)
        });

        Promise.all(promiseArray).then(values => {
            console.log("values ", values)
            this.setState({ blocking: false, mensajeBloqueo: "", DctoAdicionalCargado: true })
        }, reason => {
            this.setState({ blocking: false, mensajeBloqueo: "", DctoAdicionalCargado: false, showModalInformativo: true, textModalInformativo: "Ha ocurrido un problema al intentar cargar sus documentos, por favor vuelva a intentarlo" })
        });
    }

    NewHOC = (PassedComponent) => {
        return class extends React.Component {
            render() {
                return (
                    <div>
                        <PassedComponent {...this.props} />
                    </div>
                )
            }
        }
    }


    HandlerComboDctoAdicional = (e) => {
        var option = e.target.value
        console.log("OPCION ", option)
        if (option === "S") {
            this.setState({ displayDctoAdicional: 'block', documentacionAdicionalSeleccionado: true })
        } else {
            this.setState({ displayDctoAdicional: 'none', documentacionAdicionalSeleccionado: false })
        }
    }

    render() {
        let comboComuna = []
        console.log("comuna no es cero")
        let codregionCombo = region_comuna.region_comuna.regions.filter(region => region.number.toString() === this.state.regionBeneficiario)[0]
        if (codregionCombo) {
            comboComuna = codregionCombo.communes.map(comuna => {
                return <option value={comuna.codigo} selected> {comuna.name} </option>
            })
        } else if (Array.isArray(this.state.comunaBeneficiario) && this.state.comunaBeneficiario.length >= 1) {
            comboComuna = this.state.comunaBeneficiario.map(comuna => {
                return <option value={comuna.codigo} selected> {comuna.name} </option>
            })
        } else {
            comboComuna = region_comuna.region_comuna.regions
                .filter(region => region.number.toString() === "0")[0].communes.map(comuna => {
                    return <option value={comuna.codigo} selected> {comuna.name} </option>
                })
        }


        let comboComunaLugarReposo = this.state.comunasLugarReposo.map(comuna => {
            return <option value={comuna.codigo} selected> {comuna.name} </option>
        })
        return (<div>
            <BlockUi tag="div" blocking={this.state.blocking} message={this.state.mensajeBloqueo} keepInView>
                {this.state.indexRedirect ?
                    window.location = this.state.URL_REDIRECCION :
                    <div>
                        <ModalInformativo mostrarModal={this.state.showModalInformativo} texto={this.state.textModalInformativo} parentCallBackInformativo={this.ModalInformativoparentCallBackClose}></ModalInformativo>

                        <Header></Header>
                        <div className="container " >
                            <div class="row">

                                <div class="col-md-12">



                                    <div class="row">
                                        <div class="col-md-12 text-center">
                                            <br></br>
                                            <h1 style={{ fontFamily: "Trebuchet MS,Arial,Helvetica,sans-serif", fontSize: "30px", lineHeight: "100%", color: "#0033A0" }}>Solicitud de Certificación de Enfermo Terminal</h1>
                                            Te invitamos a ingresar los datos que aparecen a continuación para poder gestionar tu solicitud
                                            <br></br><br></br>
                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-2"></div>
                                        <div class="col-md-8">
                                            {this.state.ejecutivoLogueado &&
                                                <p>Ingreso Solicitud Ejecutiva/o : {this.state.nombreEjecutivo}</p>
                                            }
                                            <h3 style={{ fontFamily: "Trebuchet MS,Arial,Helvetica,sans-serif", fontSize: "20px", lineHeight: "100%", color: "#00ACCB" }}>
                                                Tus datos personales y de contacto
                                            </h3>

                                        </div>
                                        <div class="col-md-2"></div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-2"></div>
                                        <div class="col-md-4">
                                            <label className="" >Región</label>
                                            <select className="custom-select" id="comboRegionBeneficiario" onChange={(e) => this.HandlerRegionBeneficiario(e)}>
                                                <option value="0" selected>Seleccionar</option>
                                                {region_comuna.region_comuna.regions.map(regiones => {
                                                    return <option value={regiones.number} selected> {regiones.name} </option>
                                                })}

                                            </select>
                                        </div>
                                        <div class="col-md-4">
                                            <label className="" >Comuna</label>
                                            <select className="custom-select" id="comboComunaBeneficiario" onChange={(e) => this.HandlerComunaBeneficiario(e)}>
                                                <option value="0" selected>Seleccionar</option>
                                                {
                                                    comboComuna
                                                }
                                            </select>
                                        </div>
                                        <div class="col-md-2"></div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-2"></div>
                                        <div class="col-md-4">
                                            <label >Ciudad</label>
                                            <input type="email" className="form-control" maxLength="20" value={this.state.ciudadBeneficiario} onChange={(e) => this.HandlerCiudadBeneficiario(e)}></input>
                                        </div>
                                        <div class="col-md-4">
                                            <label >Dirección</label>
                                            <input type="email" className="form-control" maxLength="45" value={this.state.direccionBeneficiario} onChange={(e) => this.HandlerDireccionBeneficiario(e)}></input>
                                            <p className="inputdisclaimertext">{this.state.infoNombre}</p>
                                        </div>
                                        <div class="col-md-2"></div>

                                    </div>


                                    <div class="row">
                                        <div class="col-md-2"></div>
                                        <div class="col-md-4">
                                            <label >Teléfono (opcional)</label>
                                            <input type="email" maxLength="15" className="form-control" value={this.state.telefonoBeneficiario} onChange={(e) => this.HandlerTelefonoBeneficiario(e)}></input>

                                        </div>
                                        <div class="col-md-4">
                                            <label className="">Celular</label>
                                            <input type="email" maxLength="9" className="form-control" value={this.state.celularBeneficiario} onChange={(e) => this.HandlerCelularBeneficiario(e)} onBlur={(e) => this.HandlerCelularBeneficiarioBlur(e)}></input>

                                        </div>
                                        <div class="col-md-2"></div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-2"></div>
                                        <div class="col-md-4">
                                            <label >Repite tu Nro Celular</label>
                                            <input type="email" maxLength="9" className="form-control" value={this.state.celularBeneficiarioConfirmacion} onChange={(e) => this.HandlerCelularBeneficiarioConfirmacion(e)} onBlur={(e) => this.HandlerCelularBeneficiarioConfirmacionBlur(e)}></input>

                                        </div>
                                        <div class="col-md-4">
                                            <label className="">Celular secundario (opcional)</label>
                                            <input type="email" maxLength="9" className="form-control" value={this.state.celularBeneficiarioOpcional} onChange={(e) => this.HandlerCelularBeneficiarioOpcional(e)} onBlur={(e) => this.HandlerCelularBeneficiarioOpcionalBlur(e)}></input>


                                        </div>
                                        <div class="col-md-2"></div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-2"></div>
                                        <div class="col-md-4">
                                            <label className="" >Correo Electrónico</label>
                                            <input type="email" className="form-control" onCopy={(e) => { e.preventDefault(); return false }} value={this.state.correoBeneficiario} onChange={(e) => this.HandlerEmail(e)} onBlur={(e) => this.HandlerEmailBlur(e)}></input>
                                        </div>
                                        <div class="col-md-4">
                                            <label className="" >Repite tu Correo Electrónico</label>
                                            <input type="email" className="form-control" onCopy={(e) => { e.preventDefault(); return false }} onPaste={(e) => { e.preventDefault(); return false }} autoComplete="off" value={this.state.correoBeneficiarioConfirmacion} onChange={(e) => this.HandlerEmailValidacion(e)} onBlur={(e) => this.HandlerEmailValidationBlur(e)}></input>

                                        </div>
                                        <div class="col-md-2"></div>
                                    </div>
                                    <div class="row" >
                                        <div class="col-md-2"></div>
                                        <div class="col-md-4">
                                            <label className="" >Condición de Salud</label>
                                            <select className="custom-select" id="comboTipodeVigencia" onChange={(e) => this.HandlerTipoVigencia(e)}>
                                                <option value="0" selected>Seleccionar</option>
                                                <option value="N">No invalido</option>
                                                <option value="IP">Invalido Parcial</option>
                                                <option value="IT">Invalido Total</option>
                                            </select>
                                        </div>
                                        <div class="col-md-4">
                                            <label className="" >Tipo de Afiliado</label>
                                            <input type="email" className="form-control" readOnly={true} onCopy={(e) => { e.preventDefault(); return false }} onPaste={(e) => { e.preventDefault(); return false }} autoComplete="off" value={"Pensionado de sobrevivencia"} ></input>
                                        </div>
                                        <div class="col-md-2"></div>
                                    </div>


                                    {/* CAMPOS NUEVOS LUGAR REPOSO */}
                                    <div class="row" >
                                        <div class="col-md-2"></div>
                                        <div class="col-md-4">
                                            <label className="" >Lugar de reposo del solicitante</label>
                                            <select className="custom-select" id="comboTipodeVigencia" onChange={(e) => this.HandlerComboLugarReposo(e)}>
                                                <option value="0" selected>Seleccionar</option>
                                                <option value="DOM">Domicilio</option>
                                                <option value="HOSP">Hospital</option>
                                                <option value="OTR">Otro</option>
                                            </select>
                                        </div>
                                        <div class="col-md-4">
                                            {/* <label className="" >Tipo de Afiliado</label>
                                            <input type="email" className="form-control" readOnly={true} onCopy={(e) => { e.preventDefault(); return false }} onPaste={(e) => { e.preventDefault(); return false }} autoComplete="off" value={"Pensionado de sobrevivencia"} ></input> */}
                                        </div>
                                        <div class="col-md-2"></div>
                                    </div>

                                    <div style={{ display: this.state.displayLugarReposoOtro }}>
                                        <div class="row" >
                                            <div class="col-md-2"></div>
                                            <div class="col-md-4">
                                                <label className="" >Región</label>
                                                <select className="custom-select" id="comboRegionReposo" onChange={(e) => this.HandlerRegionLugarReposo(e)}>
                                                    <option value="0" selected>Seleccionar</option>
                                                    {region_comuna.region_comuna.regions.map(regiones => {
                                                        return <option value={regiones.number} selected> {regiones.name} </option>
                                                    })}
                                                </select>
                                            </div>
                                            <div class="col-md-4">
                                                <label className="" >Comuna</label>
                                                <select className="custom-select" id="comboComunaLugarReposo" onChange={(e) => this.HandlerComunaLugarReposo(e)}>
                                                    <option value="0" selected>Seleccionar</option>
                                                    {
                                                        comboComunaLugarReposo
                                                    }
                                                </select>
                                            </div>
                                            <div class="col-md-2"></div>
                                        </div>
                                        <div class="row" >
                                            <div class="col-md-2"></div>
                                            <div class="col-md-4">
                                                <label className="" >Ciudad</label>
                                                <input type="email" className="form-control" maxLength="20" value={this.state.ciudadLugarReposo} onChange={(e) => this.HandlerCiudadLugarReposo(e)}></input>
                                            </div>
                                            <div class="col-md-4">
                                                <label className="" >Calle</label>
                                                <input type="email" className="form-control" maxLength="45" value={this.state.calleLugarReposo} onChange={(e) => this.HandlerCalleLugarReposo(e)}></input>
                                            </div>
                                            <div class="col-md-2"></div>
                                        </div>
                                        <div class="row" >
                                            <div class="col-md-2"></div>
                                            <div class="col-md-4">
                                                <label className="" >Número</label>
                                                <input type="email" className="form-control" maxLength="45" value={this.state.numeroLugarReposo} onChange={(e) => this.HandlerNumeroLugarReposo(e)}></input>
                                            </div>
                                            <div class="col-md-4">
                                                {/* <label className="" >Número</label>
                                                <input type="email" className="form-control" readOnly={true} onCopy={(e) => { e.preventDefault(); return false }} onPaste={(e) => { e.preventDefault(); return false }} autoComplete="off" value={"Pensionado de sobrevivencia"} ></input> */}
                                            </div>
                                            <div class="col-md-2"></div>
                                        </div>
                                    </div>

                                    {/* CAMPOS NUEVOS LUGAR REPOSO */}

                                    <div id="receptor" style={{ display: this.state.tipoIngresoSolicitud !== "BE" ? "block" : "none" }}>
                                        <div class="row">
                                            <div class="col-md-2"></div>
                                            <div class="col-md-8">
                                                <br></br>
                                                <br></br>
                                                <h3 style={{ fontFamily: "Trebuchet MS,Arial,Helvetica,sans-serif", fontSize: "20px", lineHeight: "100%", color: "#00ACCB" }}>
                                                    Datos del receptor (mandatario o tutor)
                                                </h3>
                                            </div>
                                            <div class="col-md-2"></div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-2"></div>
                                            <div class="col-md-4">
                                                <label className="" >RUT del receptor</label>
                                                <input type="email" maxLength="12" className="form-control" onCopy={(e) => { e.preventDefault(); return false }} value={this.state.rutReceptor} onChange={(e) => this.HandleRutReceptor(e)} onBlur={(e) => this.HandleRutReceptorBlur(e)}></input>

                                            </div>
                                            <div class="col-md-4">

                                                <label className="" >Tipo de receptor</label>
                                                <select className="custom-select" id="comboTipoReceptor" onChange={(e) => this.HandlerTipoReceptor(e)}>
                                                    <option value="0" selected>Seleccionar</option>
                                                    <option value="TU">Tutor</option>
                                                    <option value="MA">Mandatario</option>
                                                </select>

                                            </div>
                                            <div class="col-md-2"></div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-2"></div>
                                            <div class="col-md-4">
                                                <label className="" >N° de serie del carnet</label>
                                                <input type="email" maxLength="10" className="form-control" onCopy={(e) => { e.preventDefault(); return false }} value={this.state.nroSerieReceptor} onChange={(e) => this.HandlerNroSerieReceptor(e)} onBlur={(e) => this.HandlerNroSerieReceptorBlur(e)}></input>

                                            </div>
                                            <div class="col-md-4">
                                                <label className="" >Repite N° de serie del carnet</label>
                                                <input type="email" maxLength="10" className="form-control" onCopy={(e) => { e.preventDefault(); return false }} onPaste={(e) => { e.preventDefault(); return false }} autoComplete="off" value={this.state.nroSerieReceptorConfirmacion} onChange={(e) => this.HandlerNroSerieReceptorConfirmacion(e)} onBlur={(e) => this.HandlerNroSerieReceptorConfirmacionBlur(e)}></input>

                                            </div>
                                            <div class="col-md-2"></div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-2"></div>
                                            <div class="col-md-4">
                                                <label className="" >Nombre Receptor</label>
                                                <input type="email" maxLength="50" className="form-control" onCopy={(e) => { e.preventDefault(); return false }} value={this.state.nombeReceptor} onChange={(e) => this.HandlerNombreReceptor(e)}></input>

                                            </div>
                                            <div class="col-md-4">
                                                <label className="" >Apellido Paterno</label>
                                                <input type="email" maxLength="30" className="form-control" onCopy={(e) => { e.preventDefault(); return false }} onPaste={(e) => { e.preventDefault(); return false }} autoComplete="off" value={this.state.apellidoPaternoReceptor} onChange={(e) => this.HandlerApellidoPaternoReceptor(e)}></input>

                                            </div>
                                            <div class="col-md-2"></div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-2"></div>
                                            <div class="col-md-4">
                                                <label className="" >Apellido Materno</label>
                                                <input type="email" maxLength="30" className="form-control" onCopy={(e) => { e.preventDefault(); return false }} value={this.state.apellidoMaternoReceptor} onChange={(e) => this.HandlerApellidoMaternoReceptor(e)}></input>

                                            </div>
                                            <div class="col-md-4">
                                                <label className="" >Región</label>
                                                <select className="custom-select" id="comboRegionReceptor" onChange={(e) => this.HandlerRegionReceptor(e)}>
                                                    <option value="0" selected>Seleccionar</option>
                                                    {region_comuna.region_comuna.regions.map(regiones => {
                                                        return <option value={regiones.number} selected> {regiones.name} </option>
                                                    })}

                                                </select>
                                            </div>
                                            <div class="col-md-2"></div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-2"></div>
                                            <div class="col-md-4">
                                                <label className="" >Comuna</label>
                                                <select className="custom-select" id="comboComunaReceptor" onChange={(e) => this.HandlerComunaReceptor(e)}>
                                                    <option value="0" selected>Seleccionar</option>
                                                    {this.state.comunaRegionReceptor.length >= 1 ?
                                                        this.state.comunaRegionReceptor.map(comuna => {
                                                            return <option value={comuna.name} selected>{comuna.name}</option>
                                                        })
                                                        :
                                                        null
                                                    }
                                                </select>
                                            </div>
                                            <div class="col-md-4">
                                                <label >Ciudad</label>
                                                <input type="email" maxLength="20" className="form-control" value={this.state.ciudadReceptor} onChange={(e) => this.HandlerCiudadReceptor(e)}></input>
                                            </div>
                                            <div class="col-md-2"></div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-2"></div>
                                            <div class="col-md-4">
                                                <label >Dirección</label>
                                                <input type="email" className="form-control" maxLength="45" value={this.state.direccionReceptor} onChange={(e) => this.HandlerDireccionReceptor(e)}></input>
                                                <p className="inputdisclaimertext">{this.state.infoNombre}</p>
                                            </div>
                                            <div class="col-md-4">
                                                <label className="" >Celular</label>
                                                <input type="email" className="form-control" maxLength="9" onCopy={(e) => { e.preventDefault(); return false }} onPaste={(e) => { e.preventDefault(); return false }} autoComplete="off" value={this.state.celularReceptor} onChange={(e) => this.HandlercelularReceptor(e)} onBlur={(e) => this.HandlercelularReceptorBlur(e)}></input>

                                            </div>
                                            <div class="col-md-2"></div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-2"></div>
                                            <div class="col-md-4">
                                                <label className="" >Repite N° Celular</label>
                                                <input type="email" maxLength="9" className="form-control" onCopy={(e) => { e.preventDefault(); return false }} value={this.state.celularReceptorConfirmacion} onChange={(e) => this.HandlercelularReceptorConfirmacion(e)} onBlur={(e) => this.HandlercelularReceptorConfirmacionBlur(e)}></input>

                                            </div>
                                            <div class="col-md-4">
                                                <label className="" >Celular secundario (opcional)</label>
                                                <input type="email" maxLength="9" className="form-control" onCopy={(e) => { e.preventDefault(); return false }} onPaste={(e) => { e.preventDefault(); return false }} autoComplete="off" value={this.state.celularReceptorConfirmacionOpcional} onChange={(e) => this.HandlercelularReceptorConfirmacionOpcional(e)} onBlur={(e) => this.HandlercelularReceptorConfirmacionOpcionalBlur(e)}></input>

                                            </div>
                                            <div class="col-md-2"></div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-2"></div>
                                            <div class="col-md-4">
                                                <label className="" >Correo Electrónico</label>
                                                <input type="email" className="form-control" onCopy={(e) => { e.preventDefault(); return false }} value={this.state.correoReceptor} onChange={(e) => this.HandlerEmailReceptor(e)} onBlur={(e) => this.HandlerEmailReceptorBlur(e)}></input>
                                                <p className="inputdisclaimertext">{this.state.infoMail}</p>
                                            </div>
                                            <div class="col-md-4">
                                                <label className="" >Repite Correo Electrónico</label>
                                                <input type="email" className="form-control" onCopy={(e) => { e.preventDefault(); return false }} onPaste={(e) => { e.preventDefault(); return false }} autoComplete="off" value={this.state.correoReceptorConfirmacion} onChange={(e) => this.HandlerEmailReceptorConfirmacion(e)} onBlur={(e) => this.HandlerEmailReceptorConfirmacionBlur(e)}></input>
                                                <p className="inputdisclaimertext">{this.state.infoMailValidacion}</p>
                                            </div>
                                        </div>
                                    </div>
                                    <br></br>
                                    <div class="row">
                                        <div class="col-md-2"></div>
                                        <div class="col-md-8">

                                            <h3 style={{ fontFamily: "Trebuchet MS,Arial,Helvetica,sans-serif", fontSize: "20px", lineHeight: "100%", color: "#00ACCB" }}>
                                                Datos para identificación de pago
                                            </h3>
                                        </div>
                                        <div class="col-md-2"></div>
                                    </div>
                                    {/* <div class="row">
                                        <div class="col-md-6">
                                            <label className="" >¿Desea efectuar el monto máximo del retiro (100%) ?</label>
                                            <p className="small-text" style={{ display: this.state.display, color: "#0038a5", }} >Retiro único parcial: Tenga en consideración que si realiza un retiro parcial, no podrá solicitar otro retiro para obtener la diferencia.</p>

                                        </div>
                                        <div class="col-md-6">
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" name="gridRadios" id="gridRadios1" value="1" onClick={(option) => this.HandlerCombo(option)}></input>
                                                <label className="" >
                                                    SI
                              </label>
                                            </div>
                                            <div className="form-check">
                                                <input className="form-check-input" type="radio" name="gridRadios" id="gridRadios2" value="2" onClick={(option) => this.HandlerCombo(option)}></input>
                                                <label className="" >
                                                    NO
                              </label>
                                                <input id="otroMonto" className="form-control" name="otroMonto" style={{ display: this.state.display }} value={this.state.ptjeRetiro} onChange={(e) => this.HandlerPorcentajeOtro(e)}></input>
                                                <p className="inputdisclaimertext" style={{ color: 'rgb(0, 51, 160)', fontSize: '12px' }} >(*)Si su renta mensual es menor a $1.500.000 millones, no pagará impuestos.</p>
                                                <p className="inputdisclaimertext">{this.state.infoPtjeRetiro}</p>

                                            </div>
                                        </div>
                                    </div> */}
                                    <div class="row" >
                                        <div class="col-md-2"></div>
                                        <div class="col-md-4">
                                            <label className="" >Medio de Pago</label>
                                            <input type="email" className="form-control" readOnly={true} onCopy={(e) => { e.preventDefault(); return false }} onPaste={(e) => { e.preventDefault(); return false }} autoComplete="off" value={"Depósito cuenta bancaria"} ></input>
                                            {/* <select className="custom-select" id="comboMedioPago" onChange={(e) => this.HandlerMedioPago(e)}>
                                                 <option value="0" selected>Seleccionar</option> 
                                                <option value="D" selected>Deposito cuenta bancaria</option>
                                                <option value="2">Cuenta 2 de la AFP</option>
                                                <option value="3">Depósito caja de compensación Los Héroes Tarjeta de prepago</option> 
                                            </select> */}
                                        </div>
                                        <div class="col-md-6">

                                        </div>
                                    </div>
                                    <div class="row">
                                        <div class="col-md-2"></div>
                                        <div class="col-md-4" style={{ display: this.state.bancoDisplay }}>
                                            <label className=""  >Banco</label>
                                            <select className="custom-select" id="comboBanco" onChange={(e) => this.HandlerBanco(e)}>
                                                <option value="0" selected>Seleccionar</option>
                                                {this.state.bancos.map((element) => {
                                                    return <option id={element} value={element}>{element}</option>
                                                })}
                                            </select>
                                        </div>
                                        <div class="col-md-4" style={{ display: this.state.bancoDisplay }}>
                                            <label className="">Tipo de cuenta</label>
                                            <select className="custom-select" id="comboTipoCuenta" onChange={(e) => this.HandlerTipoCuenta(e)}>
                                                <option value="0" selected>Seleccionar</option>
                                                {this.state.cuentas.map((element) => {
                                                    return <option id={element} value={element}>{element}</option>
                                                })}
                                            </select>
                                        </div>
                                        <div class="col-md-2"></div>
                                    </div>


                                    <div class="row">
                                        <div class="col-md-2"></div>
                                        <div class="col-md-4">
                                            <div style={{ display: this.state.displayNroCuenta }}>
                                                <label className="" >{this.state.displayTutorData === "block" ? "N° de cuenta del Tutor" : "N° de cuenta"} </label>
                                                <input type="email" className="form-control" onCopy={(e) => { e.preventDefault(); return false }} onPaste={(e) => { e.preventDefault(); return false }} maxLength={this.state.maxlengthCuenta} value={this.state.nroCuenta} onChange={(e) => this.HandlerNroCuenta(e)}></input>
                                                <p className="inputdisclaimertext" style={{ color: 'rgb(0, 51, 160)', display: this.state.bancoDisplay, fontSize: '12px' }}>
                                                    <ul>
                                                        <li>Ingrese los datos de su cuenta bancaria, sin puntos ni guión</li>
                                                        <li>No está autorizado el depósito en cuentas bipersonales (con más de un titular), ni en cuentas digitales con procesos de apertura no presenciales, evite el rechazo de su solicitud.</li>
                                                        <li>En el caso de que la cuenta ingresada presente algun problema a la hora de realizar el pago, se disponibilizara un Vale Vista en el Banco de Chile.</li>
                                                    </ul>
                                                </p>
                                                <p className="inputdisclaimertext">{this.state.infoNroCuenta}</p>
                                            </div>
                                            {/* <div style={{ display: this.state.displayNroCuentaHeroes }}>
                                                <label  >N° de cuenta caja los Héroes</label>
                                                <input type="email" className="form-control" maxLength="9" onCopy={(e) => { e.preventDefault(); return false }} onPaste={(e) => { e.preventDefault(); return false }} value={this.state.nroCuentaHeroes} onChange={(e) => this.HandlerNroCuentaHeroes(e)}></input>
                                                <p className="inputdisclaimertext">{this.state.infoNroCuentaHeroe}</p>
                                            </div> */}
                                        </div>
                                        <div class="col-md-4">
                                            <div style={{ display: this.state.displayNroCuenta }}>
                                                <label className="" > Confirme el N° de cuenta </label>
                                                <input type="email" className="form-control" onCopy={(e) => { e.preventDefault(); return false }} onPaste={(e) => { e.preventDefault(); return false }} maxLength={this.state.maxlengthCuenta} value={this.state.nroCuentaConfirm} onChange={(e) => this.HandlerNroCuentaConfirm(e)} onBlur={(e) => this.HandlerNroCuentaConfirmBlur(e)}></input>
                                                <p className="inputdisclaimertext">{this.state.infoNroCuentaConfirm}</p>
                                            </div>
                                            {/* <div style={{ display: this.state.displayNroCuentaHeroes }}>
                                                <label >Confirme el N° de cuenta caja los Héroes</label>
                                                <input type="email" className="form-control" maxLength="9" onCopy={(e) => { e.preventDefault(); return false }} onPaste={(e) => { e.preventDefault(); return false }} value={this.state.nroCuentaHeroesConfirm} onChange={(e) => this.HandlerNroCuentaHeroesConfirm(e)} onBlur={(e) => this.HandlerNroCuentaHeroesConfirmBlur(e)}></input>
                                                <p className="inputdisclaimertext">{this.state.infoNroCuentaHeroeconfirm}</p>

                                            </div> */}
                                        </div>
                                        <div class="col-md-2"></div>
                                    </div>

                                    <br></br>

                                    <div class="row">

                                        <div class="col-md-2"></div>
                                        <div class="col-md-4">
                                            <label className=""  >Certificado Médico otorgado por la Unidad de Cuidados Paliativos</label>
                                            {/* <select className="custom-select" id="comboCertificadoET" onChange={(e) => this.HandlerCertificadoET(e)}>
                                                <option value="0" selected>Seleccionar</option>
                                                <option id="1" value="S">SI</option>
                                                <option id="2" value="N">NO</option>

                                            </select> */}
                                            <p className="inputdisclaimertext" style={{ color: 'rgb(0, 51, 160)', display: this.state.bancoDisplay, fontSize: '12px' }}>
                                                <br></br>
                                                Tenga en cuenta que la Fecha de emisión del certificado no puede ser anterior a 3 meses contados desde la fecha de la solicitud</p>
                                        </div>
                                        {this.state.certificadoETCargado ? <div>
                                            <p className="inputdisclaimertext" style={{ color: 'rgb(0, 51, 160)', fontSize: '15px' }}>  Certificado ET cargado correctamente.</p>
                                            <Button variant="info" onClick={() => this.setState({ certificadoETCargado: false, selectedFileCertificadoET: null })}>Sustituir documentos cargados</Button>
                                        </div>
                                            :
                                            <div class="col-md-4" style={{ display: this.state.displayUploadCertificadoET }}>
                                                <label className=""  >Recuerda que para cargar los archivos estos deben estar en formato PDF, de lo contrario , no será posible adjuntarlos a tu solicitud.
                                                    Una vez que selecciones el documento que deseas subir espera hasta que el botón de “Cargar archivo” aparezca en la pantalla.</label>
                                                <UploadFile id="cargacertificado" color={this.state.colorCertificadoET} display={this.state.displayCertificadoET} parentCallBackFileOnChange={(e) => this.UploadFileChangeCertificadoET(e)} parentCallBackFileOnClick={(e) => this.UploadFileClickCertificadoET(e)}></UploadFile>
                                            </div>



                                        }

                                        <div class="col-md-2"></div>

                                    </div>


                                    <br></br>

                                    <div class="row">

                                        <div class="col-md-2"></div>
                                        <div class="col-md-4" style={{ display: this.state.displayUploadCertificadoET }}>
                                            {/* <label className=""  >¿Dispone del Certificado Médico otorgado por la Unidad de Cuidados Paliativos?</label>
                                            <select className="custom-select" id="comboCertificadoET" onChange={(e) => this.HandlerCertificadoET(e)}>
                                                <option value="0" selected>Seleccionar</option>
                                                <option id="1" value="S">SI</option>
                                                <option id="2" value="N">NO</option>

                                            </select> */}
                                            <p className="inputdisclaimertext" style={{ color: 'rgb(0, 51, 160)', display: this.state.bancoDisplay, fontSize: '12px' }}>

                                                Debe adjuntar una autorización firmada que permita al Consejo Médico acceso a sus antecedentes.</p>
                                        </div>
                                        {this.state.AutorizacionFirmadaCargada ? <div>
                                            <p className="inputdisclaimertext" style={{ color: 'rgb(0, 51, 160)', fontSize: '15px' }}>  Autorización cargada correctamente.</p>
                                            <Button variant="info" onClick={() => this.setState({ AutorizacionFirmadaCargada: false, selectedFileAutorizacionFirmada: null })}>Sustituir documentos cargados</Button>
                                        </div>
                                            :
                                            <div class="col-md-4" style={{ display: this.state.displayUploadCertificadoET }}>
                                                <UploadFile id="cargacertificado" color={this.state.colorAutorizacionFirmada} display={this.state.displayUploadAutorizacionFirmada} parentCallBackFileOnChange={(e) => this.UploadFileChangeAutorizacionFirmada(e)} parentCallBackFileOnClick={(e) => this.UploadFileClickAutorizacionFirmada(e)}></UploadFile>
                                            </div>
                                        }

                                        <div class="col-md-2"></div>

                                    </div>
                                    <br></br>

                                    <div class="row">
                                        <div class="col-md-2"></div>
                                        <div class="col-md-4" style={{ display: this.state.tipoVigenciaBeneficiario !== "N" && this.state.tipoVigenciaBeneficiario !== "" ? "block" : "none" }}>
                                            <br></br>
                                            <br></br>
                                            <label className="">¿Dispone del Dictamen de la Comisión Médica para certificar su grado de invalidez?</label>
                                            <select className="custom-select" id="cbDictamen" onChange={(e) => this.HandlerDictamen(e)}>
                                                <option value="0" selected>Seleccionar</option>
                                                <option id="1" value="S">SI</option>
                                                <option id="2" value="N">NO</option>
                                            </select>
                                        </div>
                                        {this.state.DictamenCargado ? <div>
                                            <p className="inputdisclaimertext" style={{ color: 'rgb(0, 51, 160)', fontSize: '15px' }}>Dictamen comisión médica cargado correctamente.</p>
                                            <Button variant="info" onClick={() => this.setState({ DictamenCargado: false, selectedFileDictamen: null })}>Sustituir documentos cargados</Button>
                                        </div>
                                            :
                                            <div class="col-md-4" style={{ display: this.state.displayUploadDictamen }}>
                                                <label className=""  >Recuerda que para cargar los archivos estos deben estar en formato PDF, de lo contrario , no será posible adjuntarlos a tu solicitud.
                                                    Una vez que selecciones el documento que deseas subir espera hasta que el botón de “Cargar archivo” aparezca en la pantalla.</label>
                                                <UploadFile color={this.state.colorDictamen} display={this.state.displayCargaDictamen} parentCallBackFileOnChange={(e) => this.UploadFileChangeDictamen(e)} parentCallBackFileOnClick={(e) => this.UploadFileClickDictamen(e)}></UploadFile>
                                            </div>
                                        }
                                        <div class="col-md-2"></div>
                                    </div>

                                    <br></br>
                                    <br></br>
                                    <div class="row" >
                                        <div class="col-md-2"></div>
                                        <div class="col-md-4" style={{ display: this.state.tipoIngresoSolicitud !== "BE" && this.state.tipoIngresoSolicitud !== "" ? "block" : "none" }}>
                                            <label className=""  >Adjunta los documentos que acreditan al mandatario de pago</label>
                                            <select className="custom-select" id="comboMandato" onChange={(e) => this.HandlerMandato(e)}>
                                                <option value="0" selected>Seleccionar</option>
                                                <option id="1" value="S">SI</option>
                                                <option id="2" value="N">NO</option>
                                            </select>
                                        </div>
                                        {this.state.MandatoCargado ? <div>
                                            <p className="inputdisclaimertext" style={{ color: 'rgb(0, 51, 160)', fontSize: '15px' }}>  Mandato cargado correctamente.</p>
                                            <Button variant="info" onClick={() => this.setState({ MandatoCargado: false, selectedFileMandato: null })}>Sustituir documentos cargados</Button>
                                        </div>
                                            :
                                            <div class="col-md-4" style={{ display: this.state.displayUploadMandato }}>
                                                <label className=""  >Recuerda que para cargar los archivos estos deben estar en formato PDF, de lo contrario , no será posible adjuntarlos a tu solicitud.
                                                    Una vez que selecciones el documento que deseas subir espera hasta que el botón de “Cargar archivo” aparezca en la pantalla.</label>
                                                <UploadFile color={this.state.colorMandato} display={this.state.displayCargaMandato} parentCallBackFileOnChange={(e) => this.UploadFileChangeMandato(e)} parentCallBackFileOnClick={(e) => this.UploadFileClickMandato(e)}></UploadFile>
                                            </div>
                                        }
                                        <div class="col-md-2"></div>
                                    </div>





                                    {/* //NUEVOS DOCUMENTOS adjuntarlos */}
                                    <br></br>
                                    <br></br>
                                    <div class="row" >
                                        <div class="col-md-2"></div>
                                        <div class="col-md-4" style={{ display: "block" }}>
                                            <label className=""  >¿Desea adjuntar documentación adicional?</label>
                                            <select className="custom-select" id="comboMandato" onChange={(e) => this.HandlerComboDctoAdicional(e)}>
                                                <option value="0" selected>Seleccionar</option>
                                                <option id="1" value="S">SI</option>
                                                <option id="2" value="N">NO</option>
                                            </select>
                                            {this.state.DctoAdicionalCargado ?
                                                <div class="row" ><div class="col-md-12" style={{ display: this.state.displayDctoAdicional }}>
                                                    <label className=""  >Recuerda que para cargar los archivos estos deben estar en formato PDF, de lo contrario , no será posible adjuntarlos a tu solicitud.
                                                        Una vez que selecciones los documentos que deseas subir espera hasta que el botón de “Cargar archivo” aparezca en la pantalla.</label>

                                                </div></div>
                                                :
                                                <div class="row" ><div class="col-md-12" style={{ display: this.state.displayDctoAdicional }}>
                                                    <label className=""  >Recuerda que para cargar los archivos estos deben estar en formato PDF, de lo contrario , no será posible adjuntarlos a tu solicitud.
                                                        Una vez que selecciones los documentos que deseas subir espera hasta que el botón de “Cargar archivo” aparezca en la pantalla.</label>

                                                </div></div>
                                            }
                                        </div>
                                        {this.state.DctoAdicionalCargado ?
                                            <div class="col-md-4">
                                                <p className="inputdisclaimertext" style={{ color: 'rgb(0, 51, 160)', fontSize: '15px' }}>  Documentos Adjuntados Correctamente.
                                                </p>
                                                <Button variant="info" onClick={() => this.setState({ DctoAdicionalCargado: false, selectedFilesDocumentoAdicionl: null, selectFilesDocumentoAdicionalInfo: null })}>Sustituir documentos cargados</Button>
                                            </div>
                                            :
                                            <div class="col-md-4" style={{ display: this.state.displayDctoAdicional }}>

                                                <UploadMultipleFiles color={'green'} display={'block'} parentCallBackFileOnChange={(e) => this.UploadAditonalDocChange(e)} parentCallBackFileOnClick={(e) => this.UploadAditonalDocClick(e)}></UploadMultipleFiles>

                                                {
                                                    this.state.DctoAdicionalCargado ?
                                                        <div></div>
                                                        :
                                                        <div>
                                                            <br></br>
                                                            <div className="card" style={{ display: this.state.displayDctoAdicional }}>
                                                                <div className="card-header">Listado de Archivos</div>
                                                                <ul className="list-group list-group-flush">
                                                                    {this.state.selectFilesDocumentoAdicionalInfo &&
                                                                        this.state.selectFilesDocumentoAdicionalInfo.map((file, index) => (

                                                                            <li className="list-group-item" key={index} >
                                                                                <a href={file.url}>{file.name}</a>
                                                                            </li>
                                                                        ))}
                                                                </ul>
                                                            </div>
                                                        </div>
                                                }
                                            </div>

                                        }

                                        <div class="col-md-2"></div>
                                    </div>
                                    {/* //NUEVOS DOCUMENTOS adjuntarlos */}
                                    <br></br>
                                    <br></br>
                                    <div>
                                        <div class="row" >
                                            <div class="col-md-2"></div>
                                            <div class="col-md-8">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" name="x" id="xt" onClick={() => { this.setState({ autorizaSubscripcionCertificados: true, disableSubmit: false }) }} ></input>
                                                    <label className="" >
                                                        Autorizo a AFP Capital, en caso que corresponda, a suscribir la solicitud de Calificación o Reevaluación de Invalidez y/o Aporte Previsional Solidario o Garantía Estatal a mi nombre.
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="col-md-6">

                                            </div>
                                        </div>

                                    </div>
                                    <br></br>
                                    {/* <div style={{ display: 'block' }}>
                                        <div class="row" >
                                            <div class="col-md-6">
                                                <div className="form-check">
                                                    <input className="form-check-input" type="checkbox" name="ui" id="er" value="2" onClick={() => this.setState({ terminosConfirm: true })}></input>
                                                    <label className="" >
                                                        He leido y acepto los <a href="#" onClick={(e) => {
                                                            e.preventDefault()
                                                            this.setState({
                                                                showModalInformativo: true, textModalInformativo: ReactHtmlParser(`
                                                
                                                <strong>Impacto pensión</strong> <br>
-	Conoce y acepta que el monto del retiro tendrá un impacto negativo en el monto de su pensión. <br>
<br>
<strong>Inembargabilidad de los fondos</strong>  <br>
-	Los fondos retirados se considerarán extraordinariamente intangibles para todo efecto legal, y no serán objeto de retención, descuento, compensación legal o contractual, embargo o cualquier forma de afectación judicial o administrativa, ni podrá rebajarse del monto ya decretado de la compensación económica en el juicio de divorcio, sin perjuicio de las deudas originadas por obligaciones alimentarias.  <br>
<br>
<strong>Pensionados</strong><br>
-	Si Ud. es un pensionado bajo la modalidad de retiro programado, su pensión se recalculará considerando el menor saldo de la cuenta individual en el mes en que corresponde su anualidad. Si efectúa el retiro por la totalidad del saldo de su cuenta individual de cotizaciones obligatorias podría quedarse sin pensión, de no tener acceso a beneficios del pilar solidario. En el caso de pensiones de vejez, se puede acceder a los beneficios del pilar solidario a partir de los 65 años de edad, cumpliendo los requisitos respectivos.<br>
-	Si Ud. es un pensionado y se encuentra en el periodo de renta temporal, el retiro se considerará en el próximo cálculo de anualidad y si no corresponde un nuevo recálculo, dependiendo del monto del retiro, podría implicar que se quede algunos meses sin pensión, previo al inicio del pago de su renta vitalicia.<br>
<br>
<strong>Cambio de fondos</strong><br>
-	Se puede ingresar una solicitud de retiro desde la cuenta obligatoria, teniendo un cambio o distribución de fondos en curso, siempre y cuando la fecha de materialización del último, sea a más tardar la fecha en que se haya aprobado la solicitud de retiro.<br>
-	En el caso que el cambio o distribución de fondos se materialice en plazos que exceden la fecha de aprobación del retiro de fondos, el cambio o distribución se hará efectivo luego de la fecha del pago del retiro. Es decir, una vez que haya quedado disponible el pago total del retiro de fondos, se podrá aceptar nuevamente la tramitación de cambio o distribución de fondos.<br>
<br>
<strong>Cambio de AFP</strong><br>
-	Si a la fecha en que se valide la solicitud de retiro, la cuenta individual no se encuentra bloqueada producto del proceso de traspaso de AFP, la solicitud de retiro se procesará en la AFP de origen. En este caso, el proceso de traspaso se pospone hasta que concluya el pago del retiro. Lo anterior, deberá ser comunicado oportunamente al afiliado.<br>
-	Si a la fecha en que se valide la solicitud de retiro, la cuenta individual se encuentra cerrada o cuando ya se efectuó el cargo con motivo de un traspaso de AFP, la solicitud de retiro será rechazada, y se le indicará al afiliado que producto de su solicitud de traspaso, debe presentar nuevamente su solicitud en la AFP de destino, una vez que esté abierta la cuenta individual en esta AFP.<br>
<br>
<strong>Pensión de alimentos</strong><br>
-	Si selecciona tener una deuda de pensión de alimentos, el monto solicitado será retenido hasta confirmar la existencia de la medida cautelar. <br>
-	Si el afiliado o beneficiario declara por error la existencia de una deuda alimentaria, sin haberse iniciado una demanda de alimentos en su contra ante los Tribunales de Justicia, no es posible probar el hecho negativo de la inexistencia de la deuda, por falta de Tribunal competente para emitir tal declaración. En este caso, deberá ingresar nuevamente una solicitud, declarando que no es deudor de pensión de alimentos.<br> `)
                                                            });
                                                        }}>terminos y condiciones </a>
                                                    </label>
                                                </div>
                                            </div>
                                            <div class="col-md-6">

                                            </div>
                                        </div>

                                    </div> */}
                                    <br></br>

                                    <br></br>

                                    <div class="text-center">

                                        <div class="row">
                                            <div class="col-md-4">
                                            </div>
                                            <div class="col-md-4">
                                                <button className="btnRegister montoRetiroNumero" id="submitButton" disabled={this.state.disableSubmit} onClick={() => this.HandlerSubmit()}>Enviar</button>
                                                <Recaptcha
                                                    ref={ref => this.recaptcha = ref}
                                                    sitekey={this.state.sitekey}
                                                    onResolved={() => this.HandlerRecaptcha()} />
                                            </div>
                                            <div class="col-md-4">
                                            </div>
                                        </div>
                                        <div class="row">
                                            <div class="col-md-12">
                                                <p className="small-text" id="disclaimerInfo" style={{ 'fontSize': '1rem', color: 'rgb(0, 51, 160)', fontWeight: "bold" }}>{this.state.diclaimerValidacion}</p>
                                                <p className="small-text" id="disclaimerInfo" style={{ 'fontSize': '1rem', color: 'green', fontWeight: "bold" }}>{this.state.diclaimerEmpty}</p>
                                            </div>
                                        </div>
                                        <Comentarios></Comentarios>
                                    </div>
                                </div>


                            </div>
                        </div>

                        <Footer></Footer>
                    </div>
                }
            </BlockUi>
        </div>
        )

    }
}

export default Solicitud;