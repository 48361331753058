import React from "react";
import { Modal, Button } from 'react-bootstrap';
import ReactDOM from "react-dom";

class ModalInformativo extends React.Component {


    render() {
        const { texto, mostrarModal } = this.props;
        const modalInformativo = document.querySelector("#modalInformativo");
        return ReactDOM.createPortal(<>
            <Modal show={mostrarModal} onHide={this.props.parentCallBackInformativo}>
                <Modal.Header style={{ fontFamily: "Trebuchet MS,Arial,Helvetica,sans-serif", fontSize: "20px", lineHeight: "100%", color: "#00ACCB" }} closeButton>
                    <Modal.Title >Información</Modal.Title>
                </Modal.Header>
                <Modal.Body>{texto}</Modal.Body>
                <Modal.Footer>
                    <Button className="btnRegister montoRetiroNumero" onClick={this.props.parentCallBackInformativo}>
                        Cerrar
            </Button>
                </Modal.Footer>
            </Modal>
        </>, modalInformativo);
    }



}

export default ModalInformativo;