
import React from "react";
// import { BrowserRouter as Router, Route, Redirect } from "react-router-dom";
import InputMask from 'react-input-mask';
import Recaptcha from 'react-google-invisible-recaptcha';
import ModalInformativo from '../../componentes/modal'
// import moment from "moment-timezone";
import Footer from "../../componentes/footer";
import Header from "../../componentes/header";
import Solicitud from './landing_solicitud'
// const { format } = require('rut.js')

class PreSolicitud extends React.Component {

    constructor(props) {
        super(props);

        this.state = {
            mostrarIngresoSolicitud: false,
            dataBeneficiario: "",
            rutBeneficiario: "",
            rutCausante: "",
            tipoIngresoSolicitud: "",
            fechaNacimientoBeneficiario: "",
            apellidoPaternoBeneficiario: "",
            apellidoMaternoBeneficiario: "",
            parentescoBeneficiario: "",
            generoBeneficiario: "",
            nroSerieBeneficiario: "",
            nroSerieBeneficiarioConfirmacion: "",
            nombreBeneficiario: "",
            infoNombreBeneficiario: "",
            infoApellidoPaternoBeneficiario: "",
            infoApellidoMaternoBeneficiario: "",
            infoParentescoBeneficiario: "",
            idEjecutivo: "",
            sitekey: process.env.REACT_APP_SITEKEY,
            URL_REDIRECCION: process.env.REACT_APP_URL_URL_REDIRECT,
            URL_NRO_DOCUMENTO_VALIDAR: process.env.REACT_APP_URL_NRO_DOCUMENTO_VALIDAR

        }
    }

    async componentDidMount() {

        console.log("mounted ", this.props.propiedades)
        if (this.props.propiedades) {
            let { idEjecutivo, rutBeneficiario, rutCausante, tipoIngresoSolicitud, dataBeneficiario } = this.props.propiedades
            console.log("dataBeneficiario ", dataBeneficiario)
            this.setState(
                {
                    rutBeneficiario: rutBeneficiario,
                    rutCausante: rutCausante,
                    tipoIngresoSolicitud: tipoIngresoSolicitud,
                    idEjecutivo: idEjecutivo,
                    nombreBeneficiario: dataBeneficiario.NOMBRE,
                    fechaNacimientoBeneficiario: dataBeneficiario.FEC_NACIMIENTO,
                    apellidoMaternoBeneficiario: dataBeneficiario.AP_MATERNO,
                    apellidoPaternoBeneficiario: dataBeneficiario.AP_PATERNO,
                    generoBeneficiario: dataBeneficiario.SEXO,
                    parentescoBeneficiario: dataBeneficiario.DESCRIPCION_PARENTESCO
                })
        }
    }

    HandlerNroSerie = (e) => {
        var serie = e.target.value
        if (/[^A-Za-z0-9]+/.test(serie)) {
            return false
        }
        this.setState({ nroSerieBeneficiario: serie, infoNroSerie: "", disableSubmit: true })

    }

    LimpiarFormatoRutValidSerie = (rutvalor) => {
        var rutv = rutvalor.replace(/\./g, "");
        return rutv;
    }

    HandlerLetterNroSerieBlur = async () => {
        var { nroSerieBeneficiario, rutBeneficiario } = this.state
        document.getElementById('submitButton').className += " disabled"
        // this.setState({ colorInfoNumeroSerie: 'green', infoNroSerie: "validando informacion número de serie..." })

        if (!rutBeneficiario || rutBeneficiario === "") {
            this.setState({ nroSerieBeneficiario: "", showModalInformativo: true, textModalInformativo: "Estimado afiliado , favor ingrese rut de beneficiario para continuar" });
            return;
        }

        const letras = Array.from('ABCDEFGHIJKLMNOPQRSTUVWXYZ')
        var nroSeriearray = Array.from(nroSerieBeneficiario)
        var contador = 0
        letras.forEach((letra) => {
            let count = nroSeriearray.filter(x => x.toUpperCase() === letra.toUpperCase()).length
            if (count >= 1) {
                contador += 1
                console.log("contador + 1")
            }
        })

        if (contador > 1) { //mas de una letra
            this.setState({ nroSerieBeneficiario: "", colorInfoNumeroSerie: 'red', infoNroSerie: "El número de serie no puede contener mas de una letra" })
        } else if (contador === 1) { //una letra
            if (!/^[a-zA-Z()]+$/.test(nroSerieBeneficiario.charAt(0))) { //validacion de letra al principio de la cadena
                this.setState({ nroSerieBeneficiario: "", colorInfoNumeroSerie: 'red', infoNroSerie: "La letra debe ir al principio de su número de serie" })
                return
            } else if (nroSerieBeneficiario.length < 7 || nroSerieBeneficiario.length > 10) {
                this.setState({ nroSerieBeneficiario: "", colorInfoNumeroSerie: 'red', infoNroSerie: "El largo del número de serie no puede ser menor a 7 ni mayor a 10 digitos" })
                return
            }
        } else if (nroSerieBeneficiario.length < 7 || nroSerieBeneficiario.length > 10) {
            this.setState({ nroSerieBeneficiario: "", colorInfoNumeroSerie: 'red', infoNroSerie: "El largo del número de serie no puede ser menor a 7 ni mayor a 10 digitos" })
            return
        }

        await fetch(this.state.URL_NRO_DOCUMENTO_VALIDAR, {
            method: 'POST',
            body: JSON.stringify({ "rut": this.LimpiarFormatoRutValidSerie(rutBeneficiario), "serie": nroSerieBeneficiario }),
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            }
        }).then(res => res.json())
            .then((response) => {
                if (response.status !== "OK") {
                    this.setState({ nroSerieBeneficiario: "", nroSerieBeneficiarioConfirmacion: "", showModalInformativo: true, textModalInformativo: "En estos momentos tenemos problemas para validar la información ingresada, favor intentar nuevamente" })
                    return;
                } else if (response.verificacion !== "V") {
                    this.setState({ nroSerieBeneficiario: "", nroSerieBeneficiarioConfirmacion: "", showModalInformativo: true, textModalInformativo: "Estimado beneficiario, El número de serie ingresado no es válido o no corresponde al rut de beneficiario, por favor verifique la información e intentelo nuevamente" })
                    return
                } else {
                    document.getElementById('submitButton').classList.remove("disabled")
                    this.setState({ disableSubmit: false, nroSerieBeneficiarioConfirmacion: "" })
                }
            })
            .catch((error) => {
                this.setState({ nroSerieBeneficiario: "", showModalInformativo: true, textModalInformativo: "En estos momentos tenemos problemas para validar la información ingresada, favor intentar nuevamente" })
                return;
            });
    }



    HandlerConfirmNroSerie = (e) => {
        var numeroSerieConfirmacion = e.target.value
        if (/[^A-Za-z0-9]+/.test(numeroSerieConfirmacion)) {
            return false
        }
        this.setState({ nroSerieBeneficiarioConfirmacion: numeroSerieConfirmacion, infoNroSerieConfirm: "", disableSubmit: true })
    }

    ModalInformativoparentCallBackClose = () => {
        this.setState({ showModalInformativo: false })
    }

    HandlerLetterNroConfirmSerieBlur = async () => {
        var { nroSerieBeneficiarioConfirmacion, nroSerieBeneficiario } = this.state
        document.getElementById('submitButton').className += " disabled"
        if (nroSerieBeneficiario !== nroSerieBeneficiarioConfirmacion) {
            this.setState({ nroSerieBeneficiarioConfirmacion: "", showModalInformativo: true, textModalInformativo: "El número de serie ingresado no coincide con el original" })
            return;
        }
        document.getElementById('submitButton').classList.remove("disabled")
        this.setState({ diclaimerEmpty: "", infoNroSerieConfirm: "", disableSubmit: false })
    }

    HandlerFechaNacimiento = (e) => {
        var fechaNacimiento = e.target.value
        this.setState({ disableSubmit: true, fechaNacimientoBeneficiario: fechaNacimiento, infoFechaNacimiento: "", bancoSelected: "", tipoCuenta: "", nroCuenta: "" })
    }

    HandlerFechaNacimientoBlur = (e) => {
        console.log("blur")
    }

    HandlerNombreBeneficiario = (e) => {
        var nombre = e.target.value
        if (!/^[a-zA-Z()]+$/.test(nombre.replace(/\s/g, ''))) {
            this.setState({ nombreBeneficiario: "", showModalInformativo: true, textModalInformativo: "Tu nombre no debe contener numeros" })
            return;
        }
        this.setState({ nombreBeneficiario: nombre })
    }


    HandlerApellidoPaternoBeneficiario = (e) => {
        var apellido = e.target.value
        if (!/^[a-zA-Z()]+$/.test(apellido.replace(/\s/g, ''))) {
            this.setState({ apellidoPaternoBeneficiario: "", infoApellidoPaternoBeneficiario: "Tu apellido paterno no puede contener numeros" })
            return;
        }
        this.setState({ apellidoPaternoBeneficiario: apellido, infoApellidoPaternoBeneficiario: "" })
    }

    HandlerApellidoMaternoBeneficiario = (e) => {
        var apellido = e.target.value
        if (!/^[a-zA-Z()]+$/.test(apellido.replace(/\s/g, ''))) {
            this.setState({ apellidoMaternoBeneficiario: "", infoApellidoMaternoBeneficiario: "Tu apellido paterno no puede contener numeros" })
            return;
        }
        this.setState({ apellidoMaternoBeneficiario: apellido, infoApellidoMaternoBeneficiario: "" })
    }

    HandlerGenero = (e) => {
        let genero = e.target.value
        if (genero === "0") {
            return;
        }
        this.setState({ generoBeneficiario: genero })
    }

    HandlerParentescoBeneficiario = (e) => {
        var parentesco = e.target.value
        if (!/^[a-zA-Z()]+$/.test(parentesco.replace(/\s/g, ''))) {
            this.setState({ parentescoBeneficiario: "", infoParentescoBeneficiario: "El parentesco no puedo ser numérico" })
            return;
        }
        this.setState({ parentescoBeneficiario: parentesco, infoParentescoBeneficiario: "" })
    }

    HandlerRecaptcha = async () => { }



    HandlerSubmit = async () => {

        let {
            rutBeneficiario,
            rutCausante,
            tipoIngresoSolicitud,
            fechaNacimientoBeneficiario,
            apellidoPaternoBeneficiario,
            apellidoMaternoBeneficiario,
            parentescoBeneficiario,
            generoBeneficiario,
            nroSerieBeneficiario,
            nombreBeneficiario
        } = this.state

        if (rutBeneficiario === "" || rutCausante === "" || tipoIngresoSolicitud === "" || fechaNacimientoBeneficiario === "" || apellidoPaternoBeneficiario === "" ||
            apellidoMaternoBeneficiario === "" || parentescoBeneficiario === "" || generoBeneficiario === "" || nroSerieBeneficiario === "" ||
            nombreBeneficiario === "") {
            this.setState({ showModalInformativo: true, textModalInformativo: "Uno o mas datos del formulario se encuentran vacios, favor verificar e intentar nuevamente" })
            return
        }
        this.setState({ mostrarIngresoSolicitud: true })
    }

    NewHOC = (PassedComponent) => {
        return class extends React.Component {
            render() {
                return (
                    <div>
                        <PassedComponent {...this.props} />
                    </div>
                )
            }
        }
    }

    render() {
        if (this.state.indexRedirect) {
            window.location = this.state.URL_REDIRECCION
        }
        // const Solicitud = this.NewHOC(_Solicitud);
        // const LoginEjecutivo = this.NewHOC(_LoginEjecutivo)
        return <div>
            {this.state.mostrarIngresoSolicitud === true ?
                <Solicitud rutBeneficiario={this.state.rutBeneficiario}
                    fechaNacimientoBeneficiario={this.state.fechaNacimientoBeneficiario}
                    nroSerieBeneficiario={this.state.nroSerieBeneficiario}
                    rutCausante={this.state.rutCausante}
                    apellidoMaternoBeneficiario={this.state.apellidoMaternoBeneficiario}
                    apellidoPaternoBeneficiario={this.state.apellidoPaternoBeneficiario}
                    generoBeneficiario={this.state.generoBeneficiario}
                    parentescoBeneficiario={this.state.parentescoBeneficiario}
                    tipoIngresoSolicitud={this.state.tipoIngresoSolicitud}
                    nombreBeneficiario={this.state.nombreBeneficiario}
                    idEjecutivo={this.state.idEjecutivo}
                    dataBeneficiario={this.props.propiedades.dataBeneficiario}
                >
                </Solicitud>
                :
                <div>
                    <ModalInformativo mostrarModal={this.state.showModalInformativo} texto={this.state.textModalInformativo} parentCallBackInformativo={this.ModalInformativoparentCallBackClose}></ModalInformativo>

                    <Header></Header>
                    <div className="container " >
                        <div class="row">



                            <div class="col-md-12">


                                <div class="row">
                                    <div class="col-md-12 text-center">
                                        <br></br>
                                        <h1 style={{ fontFamily: "Trebuchet MS,Arial,Helvetica,sans-serif", fontSize: "30px", lineHeight: "100%", color: "#0033A0" }}>Solicitud de Certificación de Enfermo Terminal</h1>
                                        Te invitamos a ingresar los datos que aparecen a continuación para poder gestionar tu solicitud
                                        <br></br><br></br>
                                    </div></div>
                                <div class="row">
                                    {this.state.ejecutivoLogueado &&
                                        <p>Ingreso Solicitud Ejecutiva/o</p>

                                    }<div class="col-md-2"></div>
                                    <div class="col-md-8">
                                        <br></br>
                                        <h3 style={{ fontFamily: "Trebuchet MS,Arial,Helvetica,sans-serif", fontSize: "20px", lineHeight: "100%", color: "#00ACCB" }}>
                                            Datos del Beneficiario
                                        </h3>
                                    </div>
                                    <div class="col-md-2"></div>
                                </div>
                                <div class="row">
                                    <div class="col-md-2"></div>
                                    <div class="col-md-4">
                                        <label for="basic-url">RUT del beneficiario</label>
                                        <div class="input-group mb-3">
                                            <input type="email" className="form-control" maxLength="12" readOnly={true} value={this.state.rutBeneficiario}></input>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <label for="basic-url">Fecha Nacimiento Beneficiario</label>
                                        <div class="input-group mb-3">
                                            <InputMask readOnly={true} className="form-control" mask="99/99/9999" maskChar="_" placeholder="DD/MM/YYYY" value={this.state.fechaNacimientoBeneficiario} onChange={(e) => this.HandlerFechaNacimiento(e)} onBlur={(e) => this.HandlerFechaNacimientoBlur(e)} />
                                            <p className="inputdisclaimertext" style={{ color: "red" }}>{this.state.infoFechaNacimiento}</p>
                                        </div>
                                    </div>
                                    <div class="col-md-2"></div>
                                </div>
                                <div class="row">
                                    <div class="col-md-2"></div>
                                    <div class="col-md-4">
                                        <label for="basic-url">N° de serie del carnet</label>
                                        <div class="input-group mb-3">
                                            <input type="email" onCopy={(e) => { e.preventDefault(); return false }} onPaste={(e) => { e.preventDefault(); return false }} className="form-control" maxLength="10" placeholder="" value={this.state.nroSerieBeneficiario} onChange={(e) => this.HandlerNroSerie(e)} onBlur={(e) => this.HandlerLetterNroSerieBlur(e)}></input>
                                            <p className="inputdisclaimertext" style={{ color: this.state.colorInfoNumeroSerie }}>{this.state.infoNroSerie}</p>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <label for="basic-url">Confirme N° de serie del carnet</label>
                                        <div class="input-group mb-3">
                                            <input type="email" class="form-control" id="basic-url" aria-describedby="basic-addon3" onCopy={(e) => { e.preventDefault(); return false }} maxLength="10" onPaste={(e) => { e.preventDefault(); return false }} autoComplete="off" value={this.state.nroSerieBeneficiarioConfirmacion} onChange={(e) => this.HandlerConfirmNroSerie(e)} onBlur={(e) => this.HandlerLetterNroConfirmSerieBlur(e)}></input>

                                        </div>
                                    </div>
                                    <div class="col-md-2"></div>
                                </div>
                                <div class="row" >
                                    <div class="col-md-2"></div>
                                    <div class="col-md-4">
                                        <label for="basic-url">Nombre</label>
                                        <div class="input-group mb-3">
                                            <input type="email" readOnly={true} class="form-control" id="basic-url" aria-describedby="basic-addon3" onCopy={(e) => { e.preventDefault(); return false }} maxLength="50" onPaste={(e) => { e.preventDefault(); return false }} autoComplete="off" value={this.state.nombreBeneficiario} onChange={(e) => this.HandlerNombreBeneficiario(e)}></input>
                                            <p className="inputdisclaimertext" style={{ color: "red" }}>{this.state.infoNombreBeneficiario}</p>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <label for="basic-url">Apellido Paterno</label>
                                        <div class="input-group mb-3">
                                            <input type="email" readOnly={true} class="form-control" id="basic-url" aria-describedby="basic-addon3" onCopy={(e) => { e.preventDefault(); return false }} maxLength="30" onPaste={(e) => { e.preventDefault(); return false }} autoComplete="off" value={this.state.apellidoPaternoBeneficiario} onChange={(e) => this.HandlerApellidoPaternoBeneficiario(e)}></input>
                                            <p className="inputdisclaimertext" style={{ color: "red" }}>{this.state.infoApellidoPaternoBeneficiario}</p>
                                        </div>
                                    </div>
                                    <div class="col-md-2"></div>
                                </div>
                                <div class="row" >
                                    <div class="col-md-2"></div>
                                    <div class="col-md-4">
                                        <label for="basic-url">Apellido Materno</label>
                                        <div class="input-group mb-3">
                                            <input type="email" readOnly={true} class="form-control" id="basic-url" aria-describedby="basic-addon3" onCopy={(e) => { e.preventDefault(); return false }} maxLength="30" onPaste={(e) => { e.preventDefault(); return false }} autoComplete="off" value={this.state.apellidoMaternoBeneficiario} onChange={(e) => this.HandlerApellidoMaternoBeneficiario(e)} ></input>
                                            <p className="inputdisclaimertext" style={{ color: this.state.colorInfoNumeroSerieConfirm }}>{this.state.infoApellidoMaternoBeneficiario}</p>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <label for="basic-url">Género</label>
                                        <div class="input-group mb-3">
                                            <input type="email" readOnly={true} class="form-control" id="basic-url" aria-describedby="basic-addon3" onCopy={(e) => { e.preventDefault(); return false }} maxLength="30" onPaste={(e) => { e.preventDefault(); return false }} autoComplete="off" value={this.state.generoBeneficiario}></input>

                                        </div>
                                    </div>
                                    <div class="col-md-2"></div>
                                </div>
                                <div class="row" >
                                    <div class="col-md-2"></div>
                                    <div class="col-md-4">
                                        <label for="basic-url">Parentesco</label>
                                        <div class="input-group mb-3">
                                            <input type="email" readOnly={true} class="form-control" id="basic-url" aria-describedby="basic-addon3" onCopy={(e) => { e.preventDefault(); return false }} maxLength="40" onPaste={(e) => { e.preventDefault(); return false }} autoComplete="off" value={this.state.parentescoBeneficiario} onChange={(e) => this.HandlerParentescoBeneficiario(e)} ></input>
                                        </div>
                                    </div>
                                    <div class="col-md-4">
                                        <label for="basic-url">Institución de salud</label>
                                        <div class="input-group mb-3">
                                            <input type="email" readOnly={true} class="form-control" id="basic-url" aria-describedby="basic-addon3" onCopy={(e) => { e.preventDefault(); return false }} maxLength="40" onPaste={(e) => { e.preventDefault(); return false }} autoComplete="off" value={this.props.propiedades.dataBeneficiario.DESCRIP_INSTITUCION_SALUD ? this.props.propiedades.dataBeneficiario.DESCRIP_INSTITUCION_SALUD : 'FONASA'}  ></input>

                                        </div>
                                    </div>
                                    <div class="col-md-2"></div>
                                </div>
                                <div class="row">
                                    <div class="col-md-2"></div>
                                    <div class="col-md-8">
                                        <br></br>
                                        <h3 style={{ fontFamily: "Trebuchet MS,Arial,Helvetica,sans-serif", fontSize: "20px", lineHeight: "100%", color: "#00ACCB" }}>
                                            Datos del Causante
                                        </h3>
                                    </div>
                                    <div class="col-md-2"></div>
                                </div>
                                <div class="row">
                                    <div class="col-md-2"></div>
                                    <div class="col-md-4">
                                        <label for="basic-url">RUT del Causante</label>
                                        <div class="input-group mb-3">
                                            <input type="email" className="form-control" maxLength="12" readOnly={true} value={this.state.rutCausante} ></input>
                                            <p className="inputdisclaimertext" style={{ color: 'rgb(0, 51, 160)', fontSize: '12px' }}>rut de la persona que origino la pensión (marido,padre,madre,esposa…)</p>
                                        </div>
                                    </div>
                                    <div class="col-md-6"></div>
                                </div>
                                <div class="text-center">

                                    <div class="row">
                                        <div class="col-md-4">
                                        </div>
                                        <div class="col-md-4">

                                            <div class="row">
                                                <div class="col-md-12">
                                                    <button className="btnRegister montoRetiroNumero" id="submitButton" disabled={this.state.disableSubmit} onClick={() => this.HandlerSubmit()}>Siguiente</button>
                                                </div>
                                                <Recaptcha
                                                    ref={ref => this.recaptcha = ref}
                                                    sitekey={this.state.sitekey}
                                                    onResolved={() => this.HandlerRecaptcha()} />
                                            </div>
                                            <p className="small-text" id="disclaimerInfo" style={{ 'fontSize': '1rem', color: this.state.disclaimerColor, fontWeight: "bold" }}>{this.state.diclaimerEmpty}</p>
                                        </div>
                                        <div class="col-md-4">
                                        </div>
                                    </div>


                                </div>

                            </div>



                        </div>







                    </div>

                    <Footer></Footer>
                </div>



            }
        </div>
    }
}

export default PreSolicitud;